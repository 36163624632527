import React from 'react'
import { Button, Col, Row, Skeleton, Spin, Typography } from 'antd'
import { ItemContainerProps } from '../containers/ItemsContainer'
import { AddItemContainer } from '../containers/AddItemContainer'
import { LayoutWrapper } from '../../../Components/LayoutWrapper'
import { ItemsTableHeader } from './ItemsTableHeader'
import { itemsTableCells } from './ItemsTableStructure'
import { ItemsTableRow } from './ItemsTableRow'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { LoadingOutlined } from '@ant-design/icons'
import { VisibleItem } from '../../../Components/VisibleItem'
import { loadNextPage } from '../itemSlice'

interface Props extends ItemContainerProps {}

export const ItemsList: React.FC<Props> = ({ items }) => {
	const loading = useSelector<RootState, boolean>((state) => state.item.loading)
	const dispatch = useDispatch()
	const pagesCount = useSelector<RootState, number>((state) => state.item.pagesCount)
	const page = useSelector<RootState, number>((state) => state.item.filter?.page ?? 1)

	return (
		<LayoutWrapper>
			<Row gutter={14}>
				{/* ITEM HISTORY */}
				<Col span={23}>
					<ItemsTableHeader cells={itemsTableCells} />
					{items.length < 1 && !loading ? <div className='empty-items'>You don't have items yet</div> : null}

					<div style={{ overflow: 'scroll', position: 'relative', minHeight: '80vh' }}>
						{items.map((item, index) => {
							return <ItemsTableRow key={item.id} even={index % 2 === 0} item={item} cells={itemsTableCells} />
						})}
						<VisibleItem visible={loading && page === 1}>
							<div className='loading-container'>
								<Spin indicator={<LoadingOutlined spin />} size='large' spinning={true} />
								<Typography.Text strong>Loading...</Typography.Text>
							</div>
						</VisibleItem>
						<VisibleItem visible={pagesCount > page || loading}>
							{loading ? (
								<div>
									<Skeleton.Button className='item-skeleton' active />
									<Skeleton.Button className='item-skeleton' active />
								</div>
							) : (
								<Row justify='center' align='middle'>
									<Button disabled={loading} onClick={() => dispatch(loadNextPage())} type='primary'>
										Load more
									</Button>
								</Row>
							)}
						</VisibleItem>
					</div>
				</Col>
				<Col span={1}>
					<AddItemContainer />
				</Col>
			</Row>
		</LayoutWrapper>
	)
}
