import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../store'

interface Props extends RouteProps {
	component?: any
}

/**
 * A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 * @param Component
 * @param rest
 * @constructor
 */
export const PrivateRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
	const isAuthenticated = useSelector<RootState>(({ auth }) => auth.isAuthenticated)
	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthenticated ? (
					<Component {...props} />
				) : (
					<Redirect to={{ pathname: '/login', state: { from: props.location } }} />
				)
			}
		/>
	)
}
