import React from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { AdminOverview } from '../components/AdminOverview'
import { RootState } from '../../../store'
import { initiateLogout } from '../../auth/actions/initiateLogout'
import { authSlice } from '../../auth/authSlice'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

const mapState = (state: RootState) => ({
	user: state.auth.user,
})

/**
 * defining mapDispatchToProps as an object
 * see https://react-redux.js.org/using-react-redux/connect-mapdispatch#defining-mapdispatchtoprops-as-an-object
 */
const mapDispatch = {
	initiateLogout,
	receiveUser: authSlice.actions.receiveUser,
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>

/** extra type definitions */
export type AdminOverviewContainerProps = PropsFromRedux & {}

/** higher-order wrapper component for side effects */
const Wrapper: React.FC<AdminOverviewContainerProps> = (props) => {
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? '')

	return (
		<Elements stripe={stripePromise}>
			<AdminOverview {...props} />
		</Elements>
	)
}

export const AdminOverviewContainer = connector(Wrapper)
