import React from 'react'

export default (
	<svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 30 30'>
		<path
			id='Icon_material-help'
			data-name='Icon material-help'
			d='M18,3A15,15,0,1,0,33,18,15.005,15.005,0,0,0,18,3Zm1.5,25.5h-3v-3h3Zm3.1-11.625-1.35,1.38A5.106,5.106,0,0,0,19.5,22.5h-3v-.75a6.038,6.038,0,0,1,1.755-4.245l1.86-1.89A2.933,2.933,0,0,0,21,13.5a3,3,0,0,0-6,0H12a6,6,0,1,1,12,0A4.773,4.773,0,0,1,22.6,16.875Z'
			transform='translate(-3 -3)'
			fill='#fff'
		/>
	</svg>
)
