import { DeferFn } from 'react-async'

import { checkbeeAPIBase } from '../../../Common/api-client.config'

/**
 * createAuthUserDeferFn
 * [react-async's deferFn - manually invoked]
 *
 * @example usage
 * const createAuthUserAsync = useAsync({ deferFn: createAuthUserDeferFn })
 * const initiateCreateAuthUser = (arg: any, anotherArg: any) => {
 * 		createAuthUserAsync.run(arg, anotherArg)
 * }
 * useSideEffect({
 * 		asyncFn: createAuthUserAsync,
 * 		message: 'Successful',
 * 		onSuccess: () => {},
 * })
 *
 * @param values - data: { firstName, lastName, emailAddress, name, address, description, gender }
 * @param props
 * @param signal
 */
export const createAuthUserDeferFn: DeferFn<any> = async ([values], props, { signal }) => {
	const tenantKey = process.env.REACT_APP_TENANT_KEY
	const response = await fetch(`${checkbeeAPIBase}/auth/newuser`, {
		signal,
		method: 'POST',
		headers: {
			'Tenant-Key': `${tenantKey}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(values),
	})

	const result = await response.json()
	if (result.status === 'success') {
		return result.data
	} else {
		throw new Error(result?.data?.message || result?.message || result?.data?.name || 'Failed to create user!')
	}
}
