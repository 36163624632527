import { DeferFn } from 'react-async'

import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { handleError } from '../../../Utils/handle-error'

/**
 * verify login otp
 * [manual trigger - deferFn]
 *
 * @param values [email, otp]
 * @param props
 * @param signal
 */
export const verifyLoginOTPDeferFn: DeferFn<any> = async ([email, otp], props, { signal }) => {
	const tenantKey = process.env.REACT_APP_TENANT_KEY
	const response = await fetch(`${checkbeeAPIBase}/auth/verify_admin`, {
		signal,
		method: 'POST',
		headers: {
			'Tenant-Key': `${tenantKey}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ emailAddress: email, otp: parseInt(otp) }),
	})

	const result = await response.json()
	if (result.status === 'success') {
		return result.data
	} else {
		const err = handleError(result, 'Failed to verify OTP!')
		throw new Error(err)
	}
}
