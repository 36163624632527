import { DeferFn, PromiseFn } from 'react-async'

import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { handleError } from '../../../Utils/handle-error'
import { SubCategoryFormContainerProps } from '../containers/SubCategoryFormContainer'

interface CreateCategory extends SubCategoryFormContainerProps {
	companyId: string
}

/**
 * this file contains 3 implementations of createCategory function:
 * - action
 * - react-async promiseFn
 * - react-async deferFn
 */

/**
 * createCategory action
 * [generic] - can be reused in both defer and promise functions
 */
export const createCategory = async (category: CreateCategory, signal: AbortSignal) => {
	const tenantKey = process.env.REACT_APP_TENANT_KEY
	const token = localStorage.getItem('chibi-tkn')

	const response = await fetch(`${checkbeeAPIBase}/category`, {
		signal,
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Tenant-Key': `${tenantKey}`,
			'Authorization': `Bearer ${token}`,
		},
		body: JSON.stringify(category),
	})

	const result = await response.json()
	if (result.status === 'success') {
		return result.data
	} else {
		const err = handleError(result, 'Failed to create category!')
		throw new Error(err)
	}
}

/**
 * createCategoryPromiseFn
 * [react-async's promiseFn - automatically invoked]
 *
 * @example [usage]
 * const createCategoryAsync = useAsync({
 * 		promiseFn: createCategoryPromiseFn,
 * 		id: SOME_ID_HERE, // arguments
 * })
 * useSideEffect({
 * 		asyncFn: createCategoryAsync,
 * 		message: 'Successful',
 * 		onSuccess: () => {},
 * })
 *
 * @param companyId
 * @param rest
 * @param signal
 */
export const createCategoryPromiseFn: PromiseFn<any> = async ({ data: { companyId, ...rest } }, { signal }) => {
	return await createCategory({ company: companyId, ...rest }, signal)
}

/**
 * createCategoryDeferFn
 * [react-async's deferFn - manually invoked]
 *
 * @param companyId
 * @param rest
 * @param props
 * @param signal
 */
export const createCategoryDeferFn: DeferFn<any> = async ([{ companyId, ...rest }], props, { signal }) => {
	return await createCategory({ company: companyId, ...rest }, signal)
}
