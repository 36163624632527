import React from 'react'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { useAsync } from 'react-async'

import { RootState } from '../../../store'
import { EditTaskTemplates, TaskTemplateForm } from '../components/EditTaskTemplates'
import { useSideEffect } from '../../../Hooks/useSideEffect'
import { editTaskTemplateDeferFn } from '../actions/editTaskTemplate'
import { deleteTaskTemplateDeferFn } from '../actions/deleteTaskTemplate'
import { fetchCategoriesPromiseFn } from '../../category/actions/fetchCategories'
import { applyFilter, TaskTemplates, taskTemplatesSlice, updateTaskTemplate } from '../taskTemplatesSlice'
import { categorySlice } from '../../category/categorySlice'

const mapState = (state: RootState) => ({
	loggedInUser: state.auth.user,
	categories: state.category.list,
	tasks: state.taskTemplates.list,
	availableAssignees: state.taskTemplates.availableAssignees,
})

const mapDispatch = {
	receiveTasks: taskTemplatesSlice.actions.receiveTasks,
	receiveCategories: categorySlice.actions.receiveCategories,
	receiveAvailableAssignees: taskTemplatesSlice.actions.receiveAvailableAssignees,
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>

/** extra type definitions */
export type TaskContainerProps = PropsFromRedux & {
	taskTemplate: TaskTemplates
}

/** higher-order wrapper component for side effects */
const Wrapper: React.FC<TaskContainerProps> = (props) => {
	const dispatch = useDispatch()
	// fetch categories block
	const fetchCategoriesAsync = useAsync({
		promiseFn: fetchCategoriesPromiseFn,
		companyId: props.loggedInUser.company.id,
	})
	useSideEffect({
		asyncFn: fetchCategoriesAsync,
		onSuccess: () => {
			const { data: categories } = fetchCategoriesAsync
			props.receiveCategories({ categories })
		},
	})

	// edit task block
	const editTaskTemplateAsync = useAsync({ deferFn: editTaskTemplateDeferFn })
	const initiateEditTaskTemplate = (values: TaskTemplateForm) => {
		editTaskTemplateAsync.run({ ...values })
	}
	useSideEffect({
		asyncFn: editTaskTemplateAsync,
		message: 'Template edited successfully',
		onSuccess: () => {
			const { data: taskTemplate } = editTaskTemplateAsync
			dispatch(applyFilter({ perPage: props.tasks.length, page: 1 }))
			dispatch(updateTaskTemplate({ task: taskTemplate }))
		},
	})

	// delete task
	const deleteTaskTemplateAsync = useAsync({ deferFn: deleteTaskTemplateDeferFn })
	const initiateDeleteTaskTemplate = (id: string) => {
		deleteTaskTemplateAsync.run(id)
	}
	useSideEffect({
		asyncFn: deleteTaskTemplateAsync,
		message: 'Template deleted successfully!',
		onSuccess: () => {
			dispatch(applyFilter({ perPage: props.tasks.length, page: 1 }))
		},
	})

	return (
		<EditTaskTemplates
			{...props}
			isEditingTaskTemplate={editTaskTemplateAsync.isPending}
			isGettingCategories={fetchCategoriesAsync.isPending}
			isGettingUsers={false}
			isGettingTasks={false}
			isEditTaskTemplateFulfilled={editTaskTemplateAsync.isFulfilled}
			editTaskTemplate={initiateEditTaskTemplate}
			isDeletingTaskTemplate={deleteTaskTemplateAsync.isPending}
			deleteTaskTemplate={initiateDeleteTaskTemplate}
		/>
	)
}

export const EditTaskContainer = connector(Wrapper)
