import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useAsync } from 'react-async'

import { RootState } from '../../../store'
import { ManageUsers } from '../components/ManageUsers'
import { fetchUsersPromiseFn } from '../actions/fetchUsers'
import { fetchCategoriesPromiseFn } from '../../category/actions/fetchCategories'
import { useSideEffect } from '../../../Hooks/useSideEffect'
import { addUserDeferFn } from '../actions/addUser'
import { removeUserDeferFn } from '../actions/removeUser'
import { categorySlice } from '../../category/categorySlice'
import { userSlice } from '../userSlice'
import { updateUserDeferFn } from '../actions/updateUser'
import { getUserDeferFn } from '../actions/getUser'
import { User } from '../../auth/authSlice'

const mapState = (state: RootState) => ({
	loggedInUser: state.auth.user,
	users: state.user.list,
	categories: state.category.list,
})

const mapDispatch = {
	receiveUsers: userSlice.actions.receiveUsers,
	receiveCategories: categorySlice.actions.receiveCategories,
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export type UsersContainerProps = PropsFromRedux & {
	loggedInUser: User
	updatedUser: User | undefined
	isGettingUsers: boolean
	isAddingUser: boolean
	isRemovingUser: boolean
	isUpdatingUser: boolean
	isAddUserFulfilled: boolean
	isUpdateUserFulfilled: boolean
	isGettingUser: boolean
	isGettingUsersFulfilled: boolean
	addUser: (values: any) => void
	removeUser: (userId: string) => void
	updateUser: (userId: string, values: any) => void
	getUser: (userId: string) => void
	resetUpdatedUser: () => void
	count: string
}

/** higher-order wrapper component for side effects */
const Wrapper: React.FC<UsersContainerProps> = (props) => {
	const [updatedUser, setUpdatedUser] = React.useState<User | undefined>(undefined)

	const getUserAsync = useAsync({ deferFn: getUserDeferFn })
	const addUserAsync = useAsync({ deferFn: addUserDeferFn })
	const removeUserAsync = useAsync({ deferFn: removeUserDeferFn })
	const updateUserAsync = useAsync({ deferFn: updateUserDeferFn })
	const fetchUsersAsync = useAsync({
		promiseFn: fetchUsersPromiseFn,
		query: { company: props.loggedInUser.company.id },
	})
	const fetchCategoriesAsync = useAsync({
		promiseFn: fetchCategoriesPromiseFn,
		companyId: props.loggedInUser.company.id,
	})

	const initiateAddUser = (values: any) => {
		addUserAsync.run({ ...values, company: props.loggedInUser.company.id })
	}
	const initiateRemoveUser = (userId: string) => {
		removeUserAsync.run(userId)
	}
	const initiateUpdateUser = (userId: string, values: string) => {
		updateUserAsync.run(userId, values)
	}
	const initiateGetUser = (userId: string) => {
		getUserAsync.run(userId)
	}

	useSideEffect({
		asyncFn: fetchUsersAsync,
		onSuccess: () => {
			const { data: users } = fetchUsersAsync
			props.receiveUsers({ users })
		},
	})
	useSideEffect({
		asyncFn: fetchCategoriesAsync,
		onSuccess: () => {
			const { data: categories } = fetchCategoriesAsync
			props.receiveCategories({ categories })
		},
	})
	useSideEffect({
		asyncFn: addUserAsync,
		message: 'Successfully added new user!',
		onSuccess: () => {
			fetchUsersAsync.reload()
		},
	})
	useSideEffect({
		asyncFn: removeUserAsync,
		message: 'Successfully removed user!',
		onSuccess: () => {
			fetchUsersAsync.reload()
		},
	})
	useSideEffect({
		asyncFn: updateUserAsync,
		message: 'Successfully updated user!',
		description: 'Updating screen data...',
		onSuccess: () => {
			fetchUsersAsync.reload()
			initiateGetUser(updateUserAsync.data.id)
		},
	})
	useSideEffect({
		asyncFn: getUserAsync,
		onSuccess: () => {
			setUpdatedUser(getUserAsync.data)
		},
	})

	return (
		<ManageUsers
			{...props}
			updatedUser={updatedUser}
			isGettingUsers={fetchUsersAsync.isPending}
			isGettingUsersFulfilled={fetchUsersAsync.isFulfilled}
			isAddingUser={addUserAsync.isPending}
			isRemovingUser={removeUserAsync.isPending}
			isUpdatingUser={updateUserAsync.isPending}
			isAddUserFulfilled={addUserAsync.isFulfilled}
			isUpdateUserFulfilled={updateUserAsync.isFulfilled}
			isGettingUser={getUserAsync.isPending}
			addUser={initiateAddUser}
			removeUser={initiateRemoveUser}
			updateUser={initiateUpdateUser}
			getUser={initiateGetUser}
			resetUpdatedUser={() => setUpdatedUser(undefined)}
		/>
	)
}

export const ManageUsersContainer = connector(Wrapper)
