import { CheckOutlined } from '@ant-design/icons'
import { Badge, Button, Popover, Typography } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListIcon from '../../../../Assets/SvgIcons/ListIcon'
import { RootState } from '../../../../store'
import { User } from '../../../auth/authSlice'
import { applyFilter, deleteFilter } from '../../taskSlice'

const SubCategoryRow = ({ title, selected, onClick }: { title: string; selected: boolean; onClick: Function }) => {
	return (
		<div onClick={() => onClick()} className='subcategory-filter-row'>
			<Typography className='subcategory-filter-row-title'>{title}</Typography>
			{selected ? <CheckOutlined style={{ color: '#F7C456' }} /> : null}
		</div>
	)
}

const AssigneesFilter = () => {
	const availableAssignees = useSelector<RootState, User[]>((state) => state.task.availableAssignees)
	const dispatch = useDispatch()
	const [selected, setSelected] = useState<string[]>([])
	const [visible, setVisible] = useState(false)

	return (
		<>
			<Popover
				placement='bottomRight'
				visible={visible}
				onVisibleChange={(newVisible) => {
					setVisible(newVisible)
				}}
				content={
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 200 }}>
						<Typography className='filter-title'>CHOOSE ASSIGNEE</Typography>
						<div className='select-container'>
							{availableAssignees.map((user) => (
								<SubCategoryRow
									onClick={() => {
										let newFilters = []
										if (selected.some((e) => e === user.id)) {
											newFilters = selected.filter((e) => e !== user.id)
										} else {
											newFilters = [...selected, user.id]
										}
										setSelected(newFilters)
										if (newFilters.length) {
											dispatch(applyFilter({ assignIds: newFilters }))
										} else {
											dispatch(deleteFilter('assignIds'))
										}
									}}
									selected={selected.some((e) => e === user.id)}
									title={user.firstName + ' ' + user.lastName}
								/>
							))}
						</div>
						<Button
							onClick={() => {
								setSelected([])
								setVisible(false)
								dispatch(deleteFilter('assignIds'))
							}}
							color='lightgray'
							className='task-action-button'
							style={{ marginTop: 10 }}
							type='link'>
							Clear filter
						</Button>
					</div>
				}
				trigger='click'>
				<Button type='link' className='filter-button'>
					{ListIcon()}
				</Button>
			</Popover>
			<Badge className='tasks-filter-badge' size='small' count={selected.length} />
		</>
	)
}

export default AssigneesFilter
