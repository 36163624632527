import React from 'react'
import { Avatar, Col, Empty, Row, Space, Spin, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons/lib'
import styled from 'styled-components'
import moment from 'moment'

import { colors } from '../../../Assets/colors'
import { DashboardContainerProps } from '../containers/DashboardContainer'
import { LayoutWrapper } from '../../../Components/LayoutWrapper'
import { Task } from '../../task/taskSlice'
import { VisibleItem } from '../../../Components/VisibleItem'
import InfoIcon from '../../../Assets/SvgIcons/InfoIcon'
import NotifyCircle from '../../../Assets/SvgIcons/NotifyCircle'
import CheckCircle from '../../../Assets/SvgIcons/CheckCircle'
import { DatetimeService } from '../../../Services/DatetimeService'
import SingleValueKpi from '../../../Components/SingleValueKpi/SingleValueKpi'
import ArrowUp from '../../../Assets/SvgIcons/ArrowUp'
import QuickDatePeriodSelector from '../../../Components/QuickDatePeriodSelector/QuickDatePeriodSelector'
import UsersColumnsChart from '../../../Components/UsersColumnsChart/UsersColumnsChart'
import SubCategoryChart from './SubCategoryChart/SubCategoryChart'

interface Props extends DashboardContainerProps {}

export const Dashboard: React.FC<Props> = (props) => {
	const lowPriorityTasks = props.tasks?.filter((task) => task.priority === 1) ?? []
	const mediumPriorityTasks = props.tasks?.filter((task) => task.priority === 2) ?? []
	const highPriorityTasks = props.tasks?.filter((task) => task.priority === 3) ?? []
	const completedTasks = props.tasks?.filter((task) => task.status === 'COMPLETED') ?? []

	// get % more completed tasks this year compared to last year
	const getTaskPercentIncrease = () => {
		const completedTasksByYear: { [year: string]: Task[] } = {}
		props.tasks.map((task) => {
			// only execute if task is completed
			if (task.status === 'COMPLETED') {
				const taskYear = task.createdAt.split('-')[0]
				// I can just increment this, but we might need the full task object in the future
				if (completedTasksByYear[taskYear]?.length) {
					// push to list if it exists
					completedTasksByYear[taskYear].push(task)
				} else {
					// initialize list if none exists
					completedTasksByYear[taskYear] = [task]
				}
			}
			return null
		})

		const currentYear = new Date().getFullYear()
		const completedThisYear = completedTasksByYear[currentYear]?.length ?? 0
		const completedTaskLastYear = completedTasksByYear[currentYear - 1]?.length ?? 0

		// % increase = Increase ÷ Original Number × 100.
		const increase = completedThisYear - completedTaskLastYear
		return Math.floor((increase / completedTaskLastYear) * 100)
	}

	const taskPercentIncrease = getTaskPercentIncrease()

	return (
		<LayoutWrapper>
			<RowHiddenScrollbar gutter={32} style={{ height: '100%', overflowX: 'hidden', overflowY: 'scroll' }}>
				{/* left content */}
				<Col span={14}>
					<div>
						<Typography.Title level={4}>Total tasks</Typography.Title>
						<StatisticsContainer>
							{/* low priority */}
							<TaskStatistic>
								<StatisticPriorityCircle type={1}>!</StatisticPriorityCircle>
								{props.isFetchingTasks ? (
									<Spin indicator={<LoadingOutlined spin />} />
								) : (
									<TaskLength>{lowPriorityTasks.length}</TaskLength>
								)}
							</TaskStatistic>
							{/* medium priority */}
							<TaskStatistic>
								<StatisticPriorityCircle type={2}>!!</StatisticPriorityCircle>
								{props.isFetchingTasks ? (
									<Spin indicator={<LoadingOutlined spin />} />
								) : (
									<TaskLength>{mediumPriorityTasks.length}</TaskLength>
								)}
							</TaskStatistic>
							{/* high priority */}
							<TaskStatistic>
								<StatisticPriorityCircle type={3}>!!!</StatisticPriorityCircle>
								{props.isFetchingTasks ? (
									<Spin indicator={<LoadingOutlined spin />} />
								) : (
									<TaskLength>{highPriorityTasks.length}</TaskLength>
								)}
							</TaskStatistic>
						</StatisticsContainer>
					</div>

					{/* Last 4 weeks */}
					<GreyedContainer>
						<Typography.Title level={4}>
							<span style={{ marginRight: 18 }}>
								Most Interacted Subcategories
								{props.isFetchingTasks && <Spin indicator={<LoadingOutlined spin />} style={{ marginLeft: 8 }} />}
							</span>
						</Typography.Title>

						<div className='stats-chart-container'>
							<VisibleItem visible={props.topSubcatsLoading}>
								<Spin indicator={<LoadingOutlined spin size={30} />} />
							</VisibleItem>
							<VisibleItem visible={!props.topSubcatsLoading}>
								<SubCategoryChart subCats={props.topSubcats ?? []} />
							</VisibleItem>
						</div>
					</GreyedContainer>

					<GreyedContainer>
						<Row align='middle'>
							<span className='section-title'>Tasks solved by user</span>
							<QuickDatePeriodSelector onChange={props.reloadTopWorkers} />
						</Row>
						<div className='stats-chart-container'>
							<VisibleItem visible={props.topWorkersLoading}>
								<Spin indicator={<LoadingOutlined spin size={30} />} />
							</VisibleItem>
							<VisibleItem visible={!props.topWorkersLoading}>
								<UsersColumnsChart topUsers={props.topWorkers ?? []} />
							</VisibleItem>
						</div>
					</GreyedContainer>
				</Col>

				{/* right content */}
				<Col span={10}>
					<Typography.Title level={4}>Nearly overdue</Typography.Title>
					<div className='grey-box-container'>
						<VisibleItem visible={props.overdueLoading}>
							<Spin indicator={<LoadingOutlined spin size={30} />} />
						</VisibleItem>
						<VisibleItem visible={!props.overdueLoading}>
							{props.overdueTasks?.nearlyOverdue?.map((task) => (
								<Row style={{ width: '100%' }} justify='space-between' align='middle'>
									<StylesPrioSpan type={'high'}>!!!</StylesPrioSpan>
									<div style={{ width: '65%' }}>
										<Typography.Title level={5} style={{ margin: 0 }}>
											{task.title}.
										</Typography.Title>
										<Typography.Title level={5} style={{ margin: 0, fontWeight: 'normal' }}>
											Due date: {moment(task.dueDate).format('DD.MM.YY')} - in{' '}
											<span style={{ color: colors.error }}>
												{DatetimeService.formatDuration(moment(), moment(task.dueDate))}
											</span>
										</Typography.Title>
									</div>
									<InfoIcon />
									<NotifyCircle />
									<CheckCircle />
								</Row>
							))}
						</VisibleItem>
						<VisibleItem visible={!props.overdueLoading && !props.overdueTasks?.nearlyOverdue?.length}>
							<EmptyContentPlaceholder>No enough data</EmptyContentPlaceholder>
						</VisibleItem>
					</div>

					{/* Recently past due */}
					<GreyedContainer>
						<VisibleItem visible={props.overdueLoading}>
							<Spin indicator={<LoadingOutlined spin size={30} />} />
						</VisibleItem>
						<VisibleItem visible={!props.overdueLoading}>
							<Typography.Title level={4}>
								Recently past due ({props.overdueTasks?.recentlyPastdue.length}){' '}
							</Typography.Title>

							<UserStatisticsContent>
								{props.overdueTasks?.recentlyPastdue.map((task) => (
									<Space align={'center'} size={24} style={{ marginBottom: 10 }}>
										<Typography.Text strong style={{ marginRight: 20 }}>
											{task.title}
										</Typography.Text>
										<Typography.Title level={5} style={{ margin: 0, fontWeight: 'normal', fontSize: '12' }}>
											Due date: {moment(task.dueDate).format('DD.MM.YY')} -{' '}
											<span style={{ color: colors.error }}>
												{DatetimeService.formatDuration(moment(task.dueDate), moment())}
											</span>{' '}
											ago
										</Typography.Title>
									</Space>
								))}
							</UserStatisticsContent>
						</VisibleItem>
						<VisibleItem visible={!props.overdueLoading && !props.overdueTasks?.recentlyPastdue.length}>
							<EmptyContentPlaceholder>No enough data</EmptyContentPlaceholder>
						</VisibleItem>
					</GreyedContainer>

					{/* Single value  KPIs */}
					<div className='single-kpi-container'>
						<SingleValueKpi
							loading={props.AVGSolvingTimeLoading}
							title={'Minutes'}
							subtitle={'Average solve time'}
							titleValue={DatetimeService.timeInMinutes(props.AVGSolvingTime?.averageSolvingTime)}
							renderContent={() => <ArrowUp />}
						/>
						<SingleValueKpi
							loading={props.totalTasksLoading}
							title={'Tasks'}
							subtitle={'Solved tasks'}
							titleValue={props.totalTasks?.tasksCount?.count}
							renderContent={() => {
								let count = parseInt(props.totalTasks?.tasksCreatedTodayCount?.count, 10)
								if (!count) return ''
								return (
									<KpiContent status={count > 0 ? 1 : 0}>
										{count > 0 ? '+' : ''}
										{count}
									</KpiContent>
								)
							}}
						/>
						<SingleValueKpi
							loading={props.totalTasksTodayLoading}
							title={'New'}
							subtitle={'New tasks today'}
							titleValue={props.totalTasksToday?.tasksCount?.count}
							renderContent={() => {
								let count =
									parseInt(props.totalTasksToday?.tasksCount?.count, 10) -
									parseInt(props.totalTasksToday?.tasksYesterdayCount?.count, 10)
								if (!count) return ''
								return (
									<KpiContent status={count > 0 ? 1 : 0}>
										{count > 0 ? '+' : ''}
										{count}
									</KpiContent>
								)
							}}
						/>
					</div>

					{/* user statistics */}
					<GreyedContainer padded>
						<Typography.Title level={4}>
							User statistics{' '}
							{props.isFetchingTasks && <Spin indicator={<LoadingOutlined spin />} style={{ marginLeft: 8 }} />}
						</Typography.Title>

						<UserStatisticsContent>
							{!completedTasks.length && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
							{completedTasks.map((task) => (
								<Space align={'center'} size={24} style={{ marginBottom: 18 }}>
									<Avatar />
									<div>
										{task.assign?.firstName} completed &lt;{task?.title}&gt; on{' '}
										{moment(task.updatedAt).format('MMM D, YYYY HH:mm')}
									</div>
								</Space>
							))}
						</UserStatisticsContent>
					</GreyedContainer>

					{/* at a glance */}
					<GreyedContainer padded>
						<Typography.Title level={4}>
							At a glance{' '}
							{props.isFetchingTasks && <Spin indicator={<LoadingOutlined spin />} style={{ marginLeft: 8 }} />}
						</Typography.Title>
						<div style={{ marginLeft: 24 }}>
							<div style={{ fontWeight: 'bold' }}>{completedTasks.length} tasks completed</div>
							<div style={{ fontWeight: 'bold' }}>
								{taskPercentIncrease === Infinity
									? "Unable calculate % increase, you don't have completed tasks from last year."
									: `${taskPercentIncrease}% more completed tasks than last year`}
							</div>
						</div>
					</GreyedContainer>
				</Col>
			</RowHiddenScrollbar>
		</LayoutWrapper>
	)
}

const RowHiddenScrollbar = styled(Row)`
	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
`

const StatisticsContainer = styled.div`
	display: flex;
	flex-direction: row;
	div:not(:last-child) {
		margin-right: 24px;
	}
`

const TaskStatistic = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	padding-left: 25px;
	height: 77px;
	border-radius: 36px;
	background: ${colors.greyShade};
`

const StatisticPriorityCircle = styled.div<{ type: number }>`
	width: 44px;
	height: 44px;
	border-radius: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 32px;
	font-weight: bold;
	color: #fff;
	background: ${(props) => {
		switch (props.type) {
			case 1:
				return colors.lowPriority
			case 2:
				return colors.mediumPriority
			case 3:
				return colors.highPriority
			default:
				return colors.lowPriority
		}
	}};
`

const TaskLength = styled.div`
	font-size: 40px;
	font-weight: bold;
	margin-left: 25px;
`

const GreyedContainer = styled.div<{ padded?: boolean }>`
	background: ${colors.greyShade};
	border-radius: 3px;
	margin-top: 40px;
	padding: ${(props) => (props.padded ? '18px 30px' : '18px')};
`

const UserStatisticsContent = styled.div`
	max-height: 300px;
	overflow-y: scroll;
	overflow-x: hidden;
`
const StylesPrioSpan = styled.span<{ type: string }>`
	font-size: 32px;
	font-weight: bold;
	color: ${({ type }) =>
		type === 'high' ? colors.highPriority : type === 'mid' ? colors.mediumPriority : colors.lowPriority};
`

const KpiContent = styled.span<{ status: number }>`
	font-size: 22px;
	font-weight: bold;
	color: ${({ status }) => (status === 1 ? colors.mediumPriority : colors.highPriority)};
`

export const EmptyContentPlaceholder = styled.span`
	display: flex;
	margin: auto;
	width: max-content;
`
