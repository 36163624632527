import React from 'react'
import { Button, Form, Input, Row, Switch, Typography } from 'antd'
import { UserOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import './styles.less'
import LocationIcon from '../../../Assets/SvgIcons/LocationIcon'
import { Company } from '../../company/companySlice'

interface Props {
	isUpdateing: boolean
	updateCompany: (values: any) => void
	company: Company
	receiveCompany: Function
}

const FormSchema = Yup.object().shape({
	name: Yup.string().min(3, 'Too short to be a phone number!').max(25, 'Too long!').required('Required'),
	phoneNumber: Yup.string().min(5, 'Too short to be a phone number!').max(15, 'Too long!').required('Required'),
	emailAddress: Yup.string().nullable().email('Invalid email').required('Required'),
	organizationNumber: Yup.string()
		.nullable()
		.min(5, 'Too short to be a phone number!')
		.max(10, 'Too long!')
		.required('Required'),
	address: Yup.string().min(5, 'Too short to be a phone number!').max(100, 'Too long!').required('Required'),
})

const list = ['name', 'phoneNumber', 'emailAddress', 'organizationNumber', 'address', 'itemsEnabled'] as const
type valueType = typeof list[number]
export const CompanyDetails: React.FC<Props> = ({ company, isUpdateing, updateCompany }) => {
	const checkEdited: any = (fieldName: valueType, value: string) =>
		company[fieldName] === value ? true : { [fieldName]: value }

	const formState = useFormik({
		validateOnChange: false,
		validationSchema: FormSchema,
		initialValues: company,
		onSubmit: (values) => {
			let patch = { ...checkEdited('name', values.name) }
			patch = { ...patch, ...checkEdited('phoneNumber', values.phoneNumber) }
			patch = { ...patch, ...checkEdited('emailAddress', values.emailAddress) }
			patch = { ...patch, ...checkEdited('organizationNumber', values.organizationNumber) }
			patch = { ...patch, ...checkEdited('address', values.address) }
			patch = { ...patch, ...checkEdited('itemsEnabled', values.itemsEnabled) }
			updateCompany({ ...patch, id: company.id })
		},
	})

	const fieldError = (field: valueType) => {
		return formState.touched[field] && formState.errors[field] ? 'error' : ''
	}

	const fieldHelp = (field: valueType) => {
		return formState.touched[field] ? formState.errors[field] : ''
	}

	return (
		<div style={styles.container}>
			<Form layout='vertical'>
				<Row className='company-section-details' justify='center' gutter={16}>
					<Form.Item className='sign-up-input' validateStatus={fieldError('name')} help={fieldHelp('name')}>
						<Input
							size={'large'}
							prefix={<UserOutlined />}
							placeholder='NAME'
							name='name'
							value={formState.values.name}
							onChange={formState.handleChange}
							onBlur={formState.handleBlur}
						/>
					</Form.Item>
					<Form.Item
						className='sign-up-input'
						validateStatus={fieldError('phoneNumber')}
						help={fieldHelp('phoneNumber')}>
						<Input
							size={'large'}
							prefix={<PhoneOutlined />}
							type='tel'
							placeholder='PHONE NUMBER'
							name='phoneNumber'
							value={formState.values.phoneNumber}
							onChange={formState.handleChange}
							onBlur={formState.handleBlur}
						/>
					</Form.Item>
					<Form.Item
						className='sign-up-input'
						validateStatus={fieldError('organizationNumber')}
						help={fieldHelp('organizationNumber')}>
						<Input
							size={'large'}
							prefix={<UserOutlined />}
							placeholder='ORGANIZATION NUMBER'
							name='organizationNumber'
							value={formState.values.organizationNumber}
							onChange={formState.handleChange}
							onBlur={formState.handleBlur}
						/>
					</Form.Item>
					<Form.Item
						className='sign-up-input'
						validateStatus={fieldError('emailAddress')}
						help={fieldHelp('emailAddress')}>
						<Input
							placeholder='EMAIL'
							name='emailAddress'
							size={'large'}
							prefix={<MailOutlined />}
							type='email'
							value={formState.values.emailAddress}
							onChange={formState.handleChange}
							onBlur={formState.handleBlur}
						/>
					</Form.Item>
					<Form.Item className='sign-up-input-full' validateStatus={fieldError('address')} help={fieldHelp('address')}>
						<Input
							placeholder='ADDRESS'
							name='address'
							size={'large'}
							prefix={<LocationIcon />}
							value={formState.values.address}
							onChange={formState.handleChange}
							onBlur={formState.handleBlur}
						/>
					</Form.Item>
					<Row align='middle' className='sign-up-input-full'>
						<Switch
							title='Items enabled?'
							onChange={(checked) => formState.setFieldValue('itemsEnabled', checked)}
							checked={formState.values.itemsEnabled}
						/>
						<Typography.Title level={5} style={{ marginLeft: 10, marginBottom: 0 }}>
							Items enabled?
						</Typography.Title>
					</Row>
				</Row>

				<Form.Item style={styles.buttonContainer}>
					<Button
						className='company-action-button'
						block
						size={'large'}
						type='primary'
						disabled={isUpdateing}
						loading={isUpdateing}
						onClick={() => formState.handleSubmit()}>
						Save
					</Button>
				</Form.Item>
			</Form>
		</div>
	)
}

/** @type {{search: React.CSSProperties}} */
const styles = {
	container: {
		width: '100%',
		flex: 1,
	},
	header: {
		maxWidth: 300,
		margin: '0 auto',
		paddingTop: 120,
		marginBottom: 72,
	},
	logoContainer: {
		overflow: 'hidden',
		textAlign: 'center' as const,
	},
	logo: {
		width: '60%',
	},
	content: {
		maxWidth: 300,
		margin: '0 auto',
	},
	buttonContainer: {
		marginTop: 32,
	},
	footer: {
		width: '100%',
		textAlign: 'center' as const,
		marginBottom: 20,
	},
	linkAsAText: {
		color: 'rgba(0, 0, 0, 0.65)',
		marginBottom: 14,
	},
}
