import React from 'react'

const PlusIcon = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='36' height='35.877' viewBox='0 0 36 35.877'>
			<path
				id='Icon_simple-addthis'
				data-name='Icon simple-addthis'
				d='M27,20.244H20.249V26.97h-4.5V20.244H9V15.759h6.75V9.031h4.5v6.727H27ZM31.5.062H4.5A4.506,4.506,0,0,0,0,4.547v26.91a4.506,4.506,0,0,0,4.5,4.482h27A4.508,4.508,0,0,0,36,31.456V4.547A4.508,4.508,0,0,0,31.5.062Z'
				transform='translate(0 -0.061)'
				fill='#f8cb60'
			/>
		</svg>
	)
}

export default PlusIcon
