/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Col, Form, Input, Row, Radio, Upload, Button, Select, Tooltip, Modal, DatePicker } from 'antd'
import type { DatePickerProps } from 'antd'

import {
	ExclamationOutlined,
	FileTextOutlined,
	InfoCircleOutlined,
	MessageOutlined,
	UserOutlined,
	ApartmentOutlined,
	PlusOutlined,
	ReloadOutlined,
	FieldTimeOutlined,
} from '@ant-design/icons/lib'
import styled from 'styled-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { colors } from '../../../Assets/colors'
import PlusIcon from '../../../Assets/SvgIcons/PlusIcon'
import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { uploadImage } from '../actions/uploadImage'
import { User } from '../../auth/authSlice'
import { Category } from '../../category/categorySlice'
import moment, { Moment } from 'moment'

interface Props {
	isCreatingTaskTemplate: boolean
	isGettingCategories: boolean
	isGettingUsers: boolean
	isCreateTaskTemplateFulfilled: boolean
	isGettingTasksTemplates: boolean
	createTaskTemplate: (values: TaskTemplateForm) => void
	availableAssignees: User[]
	categories: Category[]
}

const StyledForm = styled(Form)`
	.ant-form-item-label > label {
		height: 38px;
	}
`

export type TaskTemplateForm = {
	title: string
	description?: string
	priority: number
	category: string // id
	subCategory: string // id
	assign?: string // id
	count: string
}

const TaskFormSchema = Yup.object().shape({
	title: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
	description: Yup.string().nullable(),
	priority: Yup.number().required('Required'),
	category: Yup.string().required('Required'),
	subCategory: Yup.string().required('Required'),
	count: Yup.number().required('Required'),
})

const REPEATED_EVERY_OPTIONS = [
	{ key: 'DAILY', value: 'Daily' },
	{ key: 'WEEKLY', value: 'Weekly' },
	{ key: 'MONTHLY', value: 'Monthly' },
	{ key: 'YEARLY', value: 'Yearly' },
	{ key: 'AMOUNT_DAYS', value: 'Amount Of Days' },
	{ key: 'AMOUNT_WEEKS', value: 'Amount Of Weeks' },
	{ key: 'AMOUNT_MONTHS', value: 'Amount Of Months' },
]

export const AddTaskTemplates: React.FC<Props> = ({
	availableAssignees,
	isGettingUsers,
	isCreatingTaskTemplate,
	isGettingCategories,
	isCreateTaskTemplateFulfilled,
	categories,
	createTaskTemplate,
}) => {
	const [images, setImages] = React.useState<any[]>([])
	const [modalVisible, setModalVisible] = React.useState(false)
	const [selectedCatIndex, setSelectedCatIndex] = React.useState(0)
	const [repeatedValue, setSelectedRepeatedValue] = React.useState('')
	const [dateTime, setDateTime] = React.useState<Moment>(moment())
	const handleChange = (value: any) => {
		setSelectedRepeatedValue(value)
	}

	const onChange: DatePickerProps['onChange'] = (value) => {
		if (value) {
			setDateTime(value)
		}
	}

	const initialValues: TaskTemplateForm = {
		title: '',
		description: '',
		priority: 1,
		category: categories[0]?.id ?? '',
		subCategory: categories[0]?.subCategories[0]?.id ?? '',
		assign: '',
		count: '1',
	}

	const [form] = Form.useForm()
	const formState = useFormik({
		enableReinitialize: true,
		initialValues: initialValues,
		validationSchema: TaskFormSchema,
		onSubmit: (values) => {
			const requestData = {
				...values,
				assign: values.assign ? values.assign : undefined,
				imageUrls: images.map((image) => image.url),
				description: values.description || undefined,
				repeatedInfo: JSON.stringify({ every: repeatedValue, count: parseInt(values.count) }),
				nextPublishDate: dateTime?.toISOString(),
			}
			createTaskTemplate(requestData)
		},
	})

	// effect to reset form after request is fulfilled
	React.useEffect(() => {
		if (isCreateTaskTemplateFulfilled) {
			// reset formik and antd forms
			formState.resetForm()
			form.resetFields()
			setImages([])
			setModalVisible(false)
		}
	}, [isCreateTaskTemplateFulfilled])

	return (
		<div>
			<Button
				onClick={() => setModalVisible(true)}
				style={{ marginTop: -5 }}
				size='large'
				icon={<PlusIcon />}
				type='text'
			/>
			<Modal
				width={800}
				title='Add Task Template'
				onCancel={() => setModalVisible(false)}
				footer={null}
				visible={modalVisible}>
				<Row gutter={{ xs: 8, sm: 12, md: 18, lg: 24 }}>
					<Col span={16}>
						<StyledForm form={form} labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
							{/* title */}
							<Form.Item
								label={<FileTextOutlined style={styles.iconLabel} />}
								name='title'
								validateStatus={formState.touched.title && formState.errors.title ? 'error' : ''}>
								<Input
									size='large'
									placeholder='TITLE'
									name='title'
									value={formState.values.title}
									onChange={formState.handleChange}
									onBlur={formState.handleBlur}
								/>
							</Form.Item>
							<Form.Item
								label={<MessageOutlined style={styles.iconLabel} />}
								name='description'
								validateStatus={formState.touched.description && formState.errors.description ? 'error' : ''}>
								<Input.TextArea
									autoSize={{ minRows: 4, maxRows: 8 }}
									placeholder='DESCRIPTION'
									name='description'
									value={formState.values.description}
									onChange={formState.handleChange}
									onBlur={formState.handleBlur}
								/>
							</Form.Item>

							{/* category */}
							<Form.Item
								label={<ApartmentOutlined style={styles.iconLabel} />}
								name='category'
								hasFeedback
								validateStatus={formState.errors.category ? 'error' : ''}>
								<Input.Group compact style={{ display: 'flex' }}>
									<Input disabled size='large' defaultValue='CATEGORY' />
									<Select
										style={{ flex: '1 0 65%' }}
										size='large'
										loading={isGettingCategories}
										disabled={isGettingCategories}
										value={formState.values.category}
										onChange={(value) => {
											formState.setFieldValue('category', value)
											const index = categories.findIndex((cat) => cat.id === value)
											setSelectedCatIndex(index)
											formState.setFieldValue('subCategory', categories[index].subCategories[0].id)
											formState.setFieldValue('assign', '')
										}}>
										{categories.length &&
											categories
												.filter((e) => e.subCategories.length)
												.map((cat) => {
													return (
														<Select.Option key={cat.id} value={cat.id}>
															{cat.name}
														</Select.Option>
													)
												})}
									</Select>
								</Input.Group>
							</Form.Item>

							{/* subcategory */}
							<Form.Item
								label={<ApartmentOutlined style={styles.iconLabel} />}
								name='category'
								hasFeedback
								validateStatus={formState.errors.category ? 'error' : ''}>
								<Input.Group compact style={{ display: 'flex' }}>
									<Input disabled size='large' defaultValue='SUBCATEGORY' />
									<Select
										style={{ flex: '1 0 65%' }}
										size='large'
										loading={isGettingCategories}
										disabled={isGettingCategories}
										value={formState.values.subCategory}
										onChange={(value) => {
											formState.setFieldValue('subCategory', value)
											formState.setFieldValue('assign', '')
										}}>
										{categories.length &&
											categories[selectedCatIndex].subCategories.map((subcat) => {
												return (
													<Select.Option key={subcat.id} value={subcat.id}>
														{subcat.name}
													</Select.Option>
												)
											})}
									</Select>
								</Input.Group>
							</Form.Item>

							{/* assignee */}
							<Form.Item
								hasFeedback
								label={<UserOutlined style={styles.iconLabel} />}
								validateStatus={formState.errors.assign ? 'error' : ''}>
								<Input.Group compact style={{ display: 'flex' }}>
									<Input disabled size='large' defaultValue='ASSIGN TO' />
									<Select
										style={{ flex: '1 0 65%' }}
										size='large'
										loading={isGettingUsers}
										disabled={isGettingUsers}
										value={formState.values.assign}
										onChange={(value) => formState.setFieldValue('assign', value)}>
										<Select.Option value={''}>{'No one'}</Select.Option>
										{availableAssignees.length &&
											availableAssignees
												.filter((user) =>
													user.subCategories.find((subCat) => subCat.id === formState.values.subCategory)
												)
												.map((user) => (
													<Select.Option key={user.id} value={user.id}>
														{user.firstName} {user.lastName}
													</Select.Option>
												))}
									</Select>
								</Input.Group>
							</Form.Item>

							{/* priority */}
							<Form.Item
								hasFeedback
								label={<ExclamationOutlined style={styles.iconLabel} />}
								validateStatus={formState.errors.priority ? 'error' : ''}>
								<Input.Group compact style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
									<Input disabled size='large' defaultValue='PRIORITY' />
									<Radio.Group
										size='large'
										name='priority'
										buttonStyle='solid'
										defaultValue='medium'
										style={{ marginRight: 12, flex: '1 0 60%' }}
										value={formState.values.priority}
										onChange={(e) => formState.setFieldValue('priority', e.target.value)}>
										<Radio.Button style={styles.radioButton} value={1}>
											!
										</Radio.Button>
										<Radio.Button style={styles.radioButton} value={2}>
											!!
										</Radio.Button>
										<Radio.Button style={styles.radioButton} value={3}>
											!!!
										</Radio.Button>
									</Radio.Group>
									<Tooltip title='Set the priority of this task' placement='right'>
										<InfoCircleOutlined />
									</Tooltip>
								</Input.Group>
							</Form.Item>
							{/* Repeated Every */}
							<Form.Item label={<ReloadOutlined style={styles.iconLabel} />} name='repeated' hasFeedback>
								<Input.Group compact style={{ display: 'flex' }}>
									<Input disabled size='large' defaultValue='REPEATED EVERY' />
									<div style={{ display: 'flex', flex: '1 0 65%' }}>
										<Select
											size='large'
											style={{ width: '100%', borderRadius: 0 }}
											defaultValue='Daily'
											onChange={handleChange}>
											{REPEATED_EVERY_OPTIONS.map((item) => {
												return (
													<Select.Option key={item.key} value={item.key}>
														{item.value}
													</Select.Option>
												)
											})}
										</Select>
										{repeatedValue === 'AMOUNT_DAYS' ||
										repeatedValue === 'AMOUNT_WEEKS' ||
										repeatedValue === 'AMOUNT_MONTHS' ? (
											<Input
												size='large'
												style={{ width: '28%', borderLeft: 0 }}
												defaultValue='1'
												name='count'
												onChange={formState.handleChange}
												onBlur={formState.handleBlur}
												placeholder='count'
												value={formState.values.count}
											/>
										) : null}
									</div>
								</Input.Group>
							</Form.Item>

							{/* start from */}
							<Form.Item label={<FieldTimeOutlined style={styles.iconLabel} />} name='repeated' hasFeedback>
								<Input.Group compact style={{ display: 'flex' }}>
									<Input disabled size='large' defaultValue='START FROM' />
									<DatePicker
										style={{ flex: '1 0 65%' }}
										size='large'
										disabledDate={(current) => current.isBefore(moment())}
										showTime={{ format: 'hh:mm A', use12Hours: true }}
										use12Hours
										showSecond={false}
										minuteStep={30}
										onChange={onChange}
										allowClear={false}
										format={'DD.MM.YYYY h:mm A'}
										value={dateTime}
									/>
								</Input.Group>
							</Form.Item>
						</StyledForm>
					</Col>
					<Col span={8}>
						<Upload
							onRemove={(file) => {
								setImages(images.filter((image) => image.uid !== file.uid))
							}}
							customRequest={async ({ file }) => {
								const response = await uploadImage(file)
								setImages([...images, { uid: new Date().getTime().toString(), url: response.data, status: 'done' }])
							}}
							action={`${checkbeeAPIBase}/task/upload/image`}
							onChange={(files) => {
								console.log(files)
							}}
							listType='picture-card'
							fileList={images}>
							{images.length >= 4 ? null : (
								<div>
									<PlusOutlined />
									<div style={{ marginTop: 8 }}>Upload</div>
								</div>
							)}
						</Upload>
					</Col>
					<div className='task-edit-actions-container'>
						<Button
							className='task-action-button'
							type='primary'
							danger
							style={{ marginRight: 20 }}
							disabled={isCreatingTaskTemplate}
							loading={isCreatingTaskTemplate}
							onClick={() => {
								formState.resetForm()
								form.resetFields()
								setModalVisible(false)
								setImages([])
							}}>
							CANCEL
						</Button>
						<Button
							className='task-action-button'
							type='primary'
							disabled={isCreatingTaskTemplate}
							loading={isCreatingTaskTemplate}
							onClick={() => formState.handleSubmit()}>
							SAVE TEMPLATE
						</Button>
					</div>
				</Row>
			</Modal>
		</div>
	)
}

const styles = {
	iconLabel: { fontSize: 24, color: colors.primaryColor },
	radioButton: { textAlign: 'center' as const },
}
