import React, { useState } from 'react'
import { Button, notification } from 'antd'
import axios from 'axios'
import { checkbeeAPIBase } from '../../../Common/api-client.config'

export const ActivationLink: React.FC<{ email: string }> = ({ email }) => {
	const [loading, setLoading] = useState(false)

	const sentActivationLink = async () => {
		setLoading(true)
		const tenantKey = process.env.REACT_APP_TENANT_KEY

		try {
			await axios.post(
				`${checkbeeAPIBase}/auth/send-activation-link?email=${email}`,
				{},
				{
					headers: {
						'Content-Type': 'application/json',
						'Tenant-Key': `${tenantKey}`,
					},
				}
			)

			setLoading(false)
			notification.success({ message: 'Activation link sent successfully' })
		} catch (e) {
			setLoading(false)
			notification.warn({ message: 'Invalid email' })
		}
	}
	return (
		<Button disabled={loading || !email} loading={loading} onClick={sentActivationLink} type='link' block>
			Re-send Activation Link
		</Button>
	)
}
