import React from 'react'
import { colors } from '../Assets/colors'
import { useWindowSize } from '../Hooks/useWindowSize'

/**
 * CheckbeeMenuContent
 * - usually paired up with CheckbeeMenu
 * this can be a styled component, but for consistency
 * this was made to be a functional component {stateless}
 * @param props
 * @constructor
 */
export const CheckbeeMenuContent: React.FC<{ style?: React.CSSProperties }> = (props) => {
	const { height } = useWindowSize()
	const contentHeight = height ? height - 274 : 500

	return <div style={{ ...styles.menuContent, maxHeight: contentHeight, ...props.style }}>{props.children}</div>
}

const styles = {
	menuContent: {
		position: 'relative' as const,
		padding: 32,
		marginLeft: 38,
		width: '100%',
		border: `1px solid ${colors.borderColor}`,
		background: colors.greyShade,
		overflowY: 'scroll' as const,
		minWidth: 800,
	},
}
