import React from 'react'

const CloseIcon = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='14.16' height='14.16' viewBox='0 0 14.16 14.16'>
			<path
				id='Icon_metro-cross'
				data-name='Icon metro-cross'
				d='M16.6,13.3h0l-4.3-4.3,4.3-4.3h0a.444.444,0,0,0,0-.626L14.572,2.057a.444.444,0,0,0-.626,0h0l-4.3,4.3-4.3-4.3h0a.444.444,0,0,0-.626,0L2.7,4.087a.444.444,0,0,0,0,.626h0L7,9.008,2.7,13.3h0a.443.443,0,0,0,0,.626l2.029,2.029a.444.444,0,0,0,.626,0h0l4.3-4.3,4.3,4.3h0a.443.443,0,0,0,.626,0L16.6,13.93a.443.443,0,0,0,0-.626Z'
				transform='translate(-2.571 -1.928)'
			/>
		</svg>
	)
}

export default CloseIcon
