import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { colors } from '../../../../Assets/colors'
import OrderIcon from '../../../../Assets/SvgIcons/OrderIcon'
import { RootState } from '../../../../store'
import { applyFilter } from '../../taskTemplatesSlice'

const INITIAL = '-1'
const OrderByFilter = ({ field }: { field: string }) => {
	const orderBy = useSelector<RootState, string>((state) => state.taskTemplates.filter?.orderBy ?? '')
	const [selected, setSelected] = useState(INITIAL)
	const dispatch = useDispatch()

	const getIcon = () => {
		if (selected === INITIAL || field !== orderBy) {
			return <OrderIcon />
		}
		if (selected === 'DESC') {
			return <CaretDownOutlined style={{ color: colors.lowPriority }} />
		}

		return <CaretUpOutlined style={{ color: colors.lowPriority }} />
	}

	return (
		<Button
			type='link'
			className='filter-button'
			onClick={() => {
				const newSelected = selected === 'ASC' ? 'DESC' : 'ASC'
				setSelected(newSelected)
				dispatch(applyFilter({ orderBy: field, orderDirection: newSelected }))
			}}>
			{getIcon()}
		</Button>
	)
}

export default OrderByFilter
