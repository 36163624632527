import React from 'react'

export default (
	<svg xmlns='http://www.w3.org/2000/svg' width='25' height='23' viewBox='0 0 30.663 24.814'>
		<path
			id='Icon_awesome-user-lock'
			data-name='Icon awesome-user-lock'
			d='M10.732,12.407a6.168,6.168,0,0,0,6.133-6.2A6.133,6.133,0,1,0,4.6,6.2a6.168,6.168,0,0,0,6.133,6.2Zm4.6,3.1a3.085,3.085,0,0,1,.388-1.478,6.484,6.484,0,0,0-.695-.073h-.8a8.254,8.254,0,0,1-6.986,0h-.8A6.478,6.478,0,0,0,0,20.47v2.018a2.313,2.313,0,0,0,2.3,2.326H15.758a3.106,3.106,0,0,1-.426-1.551Zm13.8-1.551H27.6V10.081a3.833,3.833,0,1,0-7.666,0v3.877H18.4a1.542,1.542,0,0,0-1.533,1.551v7.754A1.542,1.542,0,0,0,18.4,24.814H29.13a1.542,1.542,0,0,0,1.533-1.551V15.509A1.542,1.542,0,0,0,29.13,13.958Zm-5.366,6.979A1.551,1.551,0,1,1,25.3,19.386,1.542,1.542,0,0,1,23.764,20.937ZM25.3,13.958H22.231V10.081a1.533,1.533,0,1,1,3.066,0Z'
			fill='#fff'
		/>
	</svg>
)
