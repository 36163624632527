import React from 'react'

const LocationIcon = () => (
	<svg width='16' height='23' viewBox='0 0 18 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M8.07506 23.9251C1.26422 14.0514 0 13.038 0 9.4093C0 4.43872 4.02942 0.409302 9 0.409302C13.9706 0.409302 18 4.43872 18 9.4093C18 13.038 16.7358 14.0514 9.92494 23.9251C9.47798 24.5707 8.52197 24.5707 8.07506 23.9251ZM9 13.1593C11.0711 13.1593 12.75 11.4804 12.75 9.4093C12.75 7.33822 11.0711 5.6593 9 5.6593C6.92892 5.6593 5.25 7.33822 5.25 9.4093C5.25 11.4804 6.92892 13.1593 9 13.1593Z'
			fill='black'
		/>
	</svg>
)

export default LocationIcon
