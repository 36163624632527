import React from 'react'

export default (
	<svg xmlns='http://www.w3.org/2000/svg' width='26' height='16' viewBox='0 0 30 21'>
		<g id='Icon_feather-menu' data-name='Icon feather-menu' transform='translate(-3 -7.5)'>
			<path
				id='Path_6'
				data-name='Path 6'
				d='M4.5,18h27'
				fill='none'
				stroke='#fff'
				stroke-linecap='round'
				stroke-linejoin='round'
				stroke-width='3'
			/>
			<path
				id='Path_7'
				data-name='Path 7'
				d='M4.5,9h27'
				fill='none'
				stroke='#fff'
				stroke-linecap='round'
				stroke-linejoin='round'
				stroke-width='3'
			/>
			<path
				id='Path_8'
				data-name='Path 8'
				d='M4.5,27h27'
				fill='none'
				stroke='#fff'
				stroke-linecap='round'
				stroke-linejoin='round'
				stroke-width='3'
			/>
		</g>
	</svg>
)
