import React, { useEffect, useState } from 'react'
import { Button, notification, Typography } from 'antd'
import { MinusOutlined } from '@ant-design/icons'

import './styles.less'
import styled from 'styled-components'
import { PlusOutlined } from '@ant-design/icons'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import moment from 'moment'
import { VisibleItem } from '../../../Components/VisibleItem'
import { Company } from '../../company/companySlice'

const PaymentComponent = ({ changeReady }: { changeReady: Function }) => {
	return (
		<div className='card-input-container'>
			<CardElement
				onChange={(event) => changeReady(event.complete)}
				options={{
					hidePostalCode: true,
					style: {
						base: {
							'padding': '25px',
							'fontSize': '18px',
							'::placeholder': {
								color: '#aab7c4',
							},
						},
						invalid: {
							color: '#9e2146',
						},
					},
				}}
			/>
		</div>
	)
}

interface Props {
	companySubscription: any
	isUpdateing: boolean
	company: Company
	receiveCompany: Function
	createSubscription: Function
	cancelSubscription: () => void
	updateSubscription: Function
	pricingDetails: any
}

const StyledButton = styled(Button)`
	border-radius: 12px !important;
`

export const SubscriptionDetails: React.FC<Props> = ({
	companySubscription,
	createSubscription,
	cancelSubscription,
	updateSubscription,
	company,
	pricingDetails,
}) => {
	const stripe = useStripe()
	const elements = useElements()
	const [seatsCount, setSeatsCount] = useState(1)
	const [submitReady, setSubmitReady] = useState(false)
	const [editMode, setEditMode] = useState(false)

	const currency = (pricingDetails?.priceDetails?.currency ?? '').toUpperCase()
	const price = pricingDetails?.priceDetails?.unit_amount / 100
	const priceWithVat = price + price * (pricingDetails?.taxDetails?.percentage / 100)

	useEffect(() => {
		if (companySubscription) {
			setSeatsCount(companySubscription.currentQuantity)
		}
	}, [companySubscription])

	const startSubscriping = async () => {
		if (!stripe || !elements) {
			return
		}
		const cardElement = elements.getElement(CardElement)

		if (!cardElement) return null
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: cardElement,
		})

		if (error) {
			notification.error({ message: error?.message ?? 'something went wrong' })
			return
		}
		createSubscription({
			paymentMethodId: paymentMethod?.id,
			quantity: seatsCount,
		})
	}

	const nextPaymentDue = companySubscription
		? moment(companySubscription.upcomingInvoice.period_end * 1000).format('MM-DD-YYYY')
		: ''
	const renderSubscriptionChange = () => {
		const newValue = Math.abs(seatsCount - companySubscription?.currentQuantity)
		const isAdding = seatsCount - companySubscription?.currentQuantity > 0

		if (!newValue) return <></>

		return (
			<div>
				You {isAdding ? 'added' : 'removed'} {newValue} {isAdding ? 'additional' : ''} seat(s), bringing you to a total
				of {seatsCount} seat(s). {isAdding ? <br /> : null}
				{isAdding ? `You will be charged ${newValue * priceWithVat}${currency} today.` : ''}
				<br /> Your next payment of {seatsCount * priceWithVat}
				{currency} (including VAT) will be due {nextPaymentDue}
			</div>
		)
	}

	const editModeReady = companySubscription?.currentQuantity !== seatsCount

	const usedSeats = company.users.filter((user) => user.licenced).length
	return (
		<div className='subscription-details-container'>
			{companySubscription ? (
				<div className='subscribe-card'>
					<Typography.Text strong>
						You have {companySubscription.currentQuantity} seats ({usedSeats} Used)
						<br />
						<Typography.Text style={{ fontSize: 12, fontWeight: 'normal' }}>
							Your next invoice is on {nextPaymentDue}
						</Typography.Text>
					</Typography.Text>
					<div>
						<StyledButton type='primary' style={{ marginRight: 5 }} onClick={() => setEditMode(true)}>
							Change
						</StyledButton>
						<StyledButton danger onClick={cancelSubscription}>
							Cancel
						</StyledButton>
					</div>
				</div>
			) : null}

			{editMode || !companySubscription ? (
				<>
					<div className='subscribe-card'>
						<div>
							<Typography.Text
								strong
								style={{ fontSize: 22, marginRight: 5, textTransform: 'uppercase' }}
								type='danger'>
								{pricingDetails?.priceDetails?.unit_amount / 100} {pricingDetails?.priceDetails?.currency}
							</Typography.Text>
							<Typography.Text type='secondary'>per seat: billed monthly</Typography.Text>
							<br />
							<Typography.Text type='secondary'> VAT ({pricingDetails?.taxDetails?.percentage}%)</Typography.Text>
						</div>
						<div className='plan-actions-container'>
							<StyledButton
								disabled={seatsCount < 2}
								size='large'
								type='primary'
								icon={<MinusOutlined />}
								onClick={() => setSeatsCount(seatsCount - 1)}
							/>
							<Typography.Text strong style={{ fontSize: 16 }}>
								{seatsCount}
							</Typography.Text>
							<StyledButton
								size='large'
								type='primary'
								disabled={seatsCount > 99}
								icon={<PlusOutlined />}
								onClick={() => setSeatsCount(seatsCount + 1)}
							/>
						</div>
					</div>
					<VisibleItem visible={!editMode}>
						<div style={{ width: '100%', marginBottom: 15 }}>
							<PaymentComponent changeReady={setSubmitReady} />
						</div>
					</VisibleItem>
					<VisibleItem visible={editMode}>{renderSubscriptionChange()}</VisibleItem>
					<VisibleItem visible={!editMode}>
						<Typography.Text type='secondary'>
							You will be charged {seatsCount * priceWithVat}
							{currency} (including VAT)
						</Typography.Text>
					</VisibleItem>
					<StyledButton
						className='subscribe-button'
						size='large'
						type='primary'
						disabled={editMode ? !editModeReady : !submitReady}
						onClick={() => (editMode ? updateSubscription({ quantity: seatsCount }) : startSubscriping())}>
						{editMode ? 'Update subscription' : 'Subscribe'}
					</StyledButton>
				</>
			) : null}
		</div>
	)
}
