import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useAsync } from 'react-async'
import decodeJWT from 'jwt-decode'

import { Login } from '../components/Login'
import { RootState } from '../../../store'
import { generateLoginOTPDeferFn } from '../actions/generateLoginOTP'
import { useSideEffect } from '../../../Hooks/useSideEffect'
import { verifyLoginOTPDeferFn } from '../actions/verifyLoginOTP'
import { authSlice } from '../authSlice'

const mapState = (state: RootState) => ({
	isAuthenticated: state.auth.isAuthenticated,
})

const mapDispatch = {
	authenticateUser: authSlice.actions.login,
	receiveUser: authSlice.actions.receiveUser,
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export type LoginContainerProps = PropsFromRedux & {
	isSendingOTP: boolean
	isGenerateOTPSuccess: boolean
	isVerifyingOTP: boolean
	initiateOTPGeneration: (email: string) => void
	initiateOTPVerification: (email: string, otp: string) => void
}

const Wrapper: React.FC<LoginContainerProps> = (props) => {
	const generateOTPAsync = useAsync({ deferFn: generateLoginOTPDeferFn })
	const verifyOTPAsync = useAsync({ deferFn: verifyLoginOTPDeferFn })

	const initiateOTPGeneration = (email: string) => {
		generateOTPAsync.run(email)
	}
	const initiateOTPVerification = (email: string, otp: string) => {
		verifyOTPAsync.run(email, otp)
	}

	useSideEffect({
		asyncFn: generateOTPAsync,
		message: 'We sent an OTP to your email. Check it out!',
		onSuccess: () => {},
	})
	useSideEffect({
		asyncFn: verifyOTPAsync,
		message: 'Login successful. Welcome to Checkbee!',
		onSuccess: () => {
			const { data: token } = verifyOTPAsync
			const { iat, ...user } = decodeJWT(token) as any

			localStorage.setItem('chibi-tkn', token)
			props.receiveUser({ user: { ...generateOTPAsync.data, ...user } })
			props.authenticateUser()
		},
	})

	if (props.isAuthenticated) return <Redirect to={{ pathname: '/' }} />
	return (
		<Login
			{...props}
			isSendingOTP={generateOTPAsync.isPending}
			isGenerateOTPSuccess={generateOTPAsync.isFulfilled}
			isVerifyingOTP={verifyOTPAsync.isPending}
			initiateOTPGeneration={initiateOTPGeneration}
			initiateOTPVerification={initiateOTPVerification}
		/>
	)
}

export const LoginContainer = connector(Wrapper)
