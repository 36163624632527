/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useAsync } from 'react-async'
import { Loader } from '../../../Components/Loader'
import { useSideEffect } from '../../../Hooks/useSideEffect'
import { getInvoicesHistoryDeferFn } from '../actions/getInvoicesHistory'
import InvoicesHistory from '../components/InvoicesHistory'

/** higher-order wrapper component for side effects */
const InvoicesHistoryContainer: React.FC<{}> = () => {
	const [invoices, setinvoices] = useState([])
	const getInvoicesHistoryAsync = useAsync({ deferFn: getInvoicesHistoryDeferFn })

	useEffect(() => {
		getInvoicesHistoryAsync.run()
	}, [])

	useSideEffect({
		asyncFn: getInvoicesHistoryAsync,
		onSuccess: () => {
			const { data } = getInvoicesHistoryAsync
			setinvoices(data.data)
		},
	})

	if (getInvoicesHistoryAsync.isLoading) {
		return <Loader />
	}

	return <InvoicesHistory invoices={invoices} />
}

export default InvoicesHistoryContainer
