/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import CommentsSection, { TaskComment } from '../components/CommentsSection/CommentsSection'
import { getTaskCommentsDeferFn } from '../actions/getTaskComments'
import { useAsync } from 'react-async'
import { useSideEffect } from '../../../Hooks/useSideEffect'
import { addTaskCommentDeferFn } from '../actions/addComment'
import { deleteTaskCommentDeferFn } from '../actions/deleteTaskComment'

const CommentsSectionContainer = ({ taskId }: { taskId: string }) => {
	const [comments, setComments] = useState<TaskComment[]>([])
	const getTaskComments = useAsync({
		deferFn: getTaskCommentsDeferFn,
	})
	useSideEffect({
		asyncFn: getTaskComments,
		onSuccess: () => {
			const { data: comments } = getTaskComments
			setComments(comments)
		},
	})

	const addTaskComment = useAsync({
		deferFn: addTaskCommentDeferFn,
	})
	useSideEffect({
		asyncFn: addTaskComment,
		onSuccess: () => {
			const { data: comment } = addTaskComment
			setComments([...comments, comment])
		},
	})

	const deleteTaskComment = useAsync({
		deferFn: deleteTaskCommentDeferFn,
	})
	useSideEffect({
		asyncFn: deleteTaskComment,
		onSuccess: () => {
			const { data: comment } = addTaskComment
			setComments([...comments, comment])
		},
	})

	useEffect(() => {
		getTaskComments.run({ id: taskId })
	}, [])

	return (
		<CommentsSection
			addLoading={addTaskComment.isPending}
			addComment={(text) => {
				addTaskComment.run({ task: taskId, text })
			}}
			tasksLoading={getTaskComments.isPending}
			comments={comments}
		/>
	)
}

export default CommentsSectionContainer
