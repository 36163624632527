/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux'
import { useAsync } from 'react-async'

import { RootState } from '../../../store'
import { ItemsList } from '../components/ItemsList'
import { useSideEffect } from '../../../Hooks/useSideEffect'
import { fetchCategoriesPromiseFn } from '../../category/actions/fetchCategories'
import { fetchUsersPromiseFn } from '../../user/actions/fetchUsers'
import { Filter, getItemsThunk, itemSlice, resetFilter } from '../itemSlice'
import { categorySlice } from '../../category/categorySlice'

const mapState = (state: RootState) => ({
	loggedInUser: state.auth.user,
	categories: state.category.list,
	items: state.item.list,
	availableAssignees: state.item.availableAssignees,
})

const mapDispatch = {
	receiveItems: itemSlice.actions.receiveItems,
	receiveCategories: categorySlice.actions.receiveCategories,
	receiveAvailableAssignees: itemSlice.actions.receiveAvailableAssignees,
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>

/** extra type definitions */
export type ItemContainerProps = PropsFromRedux & {
	isGettingCategories: boolean
	isGettingUsers: boolean
	isGettingItems: boolean
}

/** higher-order wrapper component for side effects */
const Wrapper: React.FC<ItemContainerProps> = (props) => {
	const filter = useSelector<RootState, Filter>((state) => state.item.filter)

	const dispatch = useDispatch()
	// fetch categories block
	const fetchCategoriesAsync = useAsync({
		promiseFn: fetchCategoriesPromiseFn,
		companyId: props.loggedInUser.company.id,
	})
	useSideEffect({
		asyncFn: fetchCategoriesAsync,
		onSuccess: () => {
			const { data: categories } = fetchCategoriesAsync
			props.receiveCategories({ categories })
		},
	})

	// fetch users block
	const fetchUsersAsync = useAsync({
		promiseFn: fetchUsersPromiseFn,
		query: { company: props.loggedInUser.company.id },
	})
	useSideEffect({
		asyncFn: fetchUsersAsync,
		onSuccess: () => {
			const { data: users } = fetchUsersAsync
			const unArchivedUsers = users.filter((user: any) => !user.archived)
			props.receiveAvailableAssignees({ users: unArchivedUsers })
		},
	})

	useEffect(() => {
		dispatch(resetFilter())
	}, [])

	useEffect(() => {
		dispatch(getItemsThunk())
	}, [filter])

	return (
		<ItemsList
			{...props}
			isGettingCategories={fetchCategoriesAsync.isPending}
			isGettingUsers={fetchUsersAsync.isPending}
		/>
	)
}

export const ItemsListContainer = connector(Wrapper)
