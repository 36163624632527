/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from 'react'
import { Button, Col, Input, Row, Skeleton, Space, Table, Typography, Form, Modal } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import ProgressiveImage from 'react-progressive-image'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { CheckbeeMenuContentHeader } from '../../../Components/containers.styled'
import {
	ConnectedSubCategoryFormProps,
	SubCategoryFormState,
	SubCategoryFormContainerProps,
} from '../containers/SubCategoryFormContainer'
import { colors } from '../../../Assets/colors'
import { uploadImage } from '../../task/actions/uploadImage'

/** types for this component */
type Props = ConnectedSubCategoryFormProps & SubCategoryFormContainerProps & {}

const SubCategoryFormSchema = Yup.object().shape({
	name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
	description: Yup.string().nullable(),
	imageUrl: Yup.string().required('Image is required'),
})

/**
 * Edit and View category combined
 * code a little messy :|
 * It is true
 */
export const SubCategoryForm: React.FC<Props> = ({
	initialFormState,
	isCreatingCategory,
	isFormFulfilled,
	createSubCategory,
	deleteSubCategory,
	updateSubCategory,
	category,
	subCategory,
}) => {
	const [isEditModalOpen, setIsEditModalOpen] = React.useState(false)
	const [isEditCatModalOpen, setIsEditCatModalOpen] = React.useState(false)
	const [imageUploading, setImageUploading] = React.useState(false)
	const imageUploader = useRef<HTMLInputElement>(null)

	const formState = useFormik({
		enableReinitialize: true,
		validationSchema: SubCategoryFormSchema,
		initialValues: {
			imageUrl: 'https://checkbee-storage.s3.eu-north-1.amazonaws.com/25514AEA-AB82-46BC-86BC-61FFAD8AEEB7.jpg',
			name: 'Draft Sub Category',
			...initialFormState,
		} as SubCategoryFormState,
		onSubmit: (values) => {
			if (initialFormState) {
				updateSubCategory(values)
			} else {
				createSubCategory({ values, category })
			}
		},
	})

	React.useEffect(() => {
		if (subCategory) {
			formState.setFieldValue('name', subCategory.name)
		}
	}, [subCategory])

	// effect to reset form after request is fulfilled
	React.useEffect(() => {
		if (isFormFulfilled) {
			formState.resetForm()
		}
	}, [formState, isFormFulfilled])

	const handleDelete = () => {
		const id = initialFormState?.id
		if (id) {
			deleteSubCategory(id)
		}
	}

	return (
		<>
			<Row gutter={24}>
				{/* category image */}
				<Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<div style={{ position: 'relative', width: 333, height: 187 }}>
						{formState.values.imageUrl ? (
							<ProgressiveImage src={formState.values.imageUrl} placeholder={formState.values.imageUrl}>
								{(src: string) => (
									<img
										src={src}
										alt={`category-${initialFormState?.id}`}
										style={{ width: '100%', height: '100%', objectFit: 'cover' }}
									/>
								)}
							</ProgressiveImage>
						) : (
							<div style={styles.skeletonContainer}>
								<Skeleton.Image />
							</div>
						)}
						<Button
							size='small'
							type='primary'
							onClick={() => (imageUploader?.current?.click ? imageUploader?.current?.click() : null)}
							color={colors.primaryColor}
							style={{ position: 'absolute', top: 14, left: 14 }}>
							Change Picture
						</Button>
					</div>
				</Col>
				{/* header title, description */}
				<Col span={16}>
					<CheckbeeMenuContentHeader>
						<React.Fragment>
							<Typography.Title level={3} style={{ margin: '0 8px 0 0', textTransform: 'uppercase' }}>
								{formState.values?.name ?? initialFormState?.name}
							</Typography.Title>
						</React.Fragment>
					</CheckbeeMenuContentHeader>

					{/* modal for editing a category */}
					<Modal
						title='Edit Subcategory'
						visible={isEditCatModalOpen}
						onOk={() => !formState.errors.name && setIsEditCatModalOpen(false)}
						onCancel={() => !formState.errors.name && setIsEditCatModalOpen(false)}>
						<Form.Item
							style={{ margin: 0 }}
							validateStatus={formState.errors.name ? 'error' : ''}
							help={formState.errors.name}>
							<Input
								placeholder='Subcategory name'
								size='large'
								name={'name'}
								value={formState.values.name}
								onChange={formState.handleChange}
							/>
						</Form.Item>
					</Modal>

					{/* description */}
					<div style={{ marginTop: 28 }}>
						<Form.Item
							style={{ maxWidth: 702 }}
							validateStatus={formState.errors.powerUrl ? 'error' : ''}
							onReset={() => formState.setFieldValue('powerUrl', '')}
							help={formState.errors.powerUrl}>
							<Input.TextArea
								onReset={() => formState.setFieldValue('powerUrl', '')}
								allowClear={true}
								placeholder='Power webhook url'
								name='powerUrl'
								onChange={formState.handleChange}
								value={formState.values.powerUrl ?? ''}
								autoSize={{ minRows: 5, maxRows: 8 }}
							/>
						</Form.Item>
					</div>
				</Col>
			</Row>

			<Row gutter={16} justify='space-between' style={{ marginTop: 26 }}>
				{/* modal for editting a subcategory */}
				<Modal
					title='Edit Subcategory'
					visible={isEditModalOpen}
					onOk={() => setIsEditModalOpen(false)}
					onCancel={() => setIsEditModalOpen(false)}>
					<Form.Item style={{ margin: 0 }}>
						<Input
							placeholder='Subcategory Name'
							size='large'
							name={'name'}
							value={formState.values.name}
							onChange={formState.handleChange}
						/>
					</Form.Item>
				</Modal>

				{/* users table */}
				<Col span={18}>
					<Row align='middle' style={{ marginBottom: 12 }}>
						<Typography.Title level={4} style={{ margin: 0, textTransform: 'uppercase' }}>
							USERS WITH ACCESS TO {initialFormState?.name ?? formState.values?.name ?? 'this subcategory'}{' '}
						</Typography.Title>
						<Button size='small' type='text'>
							<InfoCircleOutlined />
						</Button>
					</Row>

					<Table
						bordered
						pagination={false}
						dataSource={initialFormState?.users}
						columns={[
							{
								title: 'NAME',
								dataIndex: 'name',
								key: 'name',
								render: (text, record) => `${record.firstName} ${record.lastName}`,
							},
							{
								title: 'EMAIL',
								dataIndex: 'emailAddress',
								key: 'emailAddress',
								render: (email) => <a href={`mailto:${email}`}>{email}</a>,
							},
							{ title: 'USER TYPE', dataIndex: 'role', key: 'role' },
							{
								title: 'ACTIVE?',
								dataIndex: 'activate',
								key: 'activate',
								render: (activate) => <div>{activate ? 'YES' : 'NO'}</div>,
							},
							{
								title: 'ARCHIVED?',
								dataIndex: 'archived',
								key: 'archived',
								render: (archived) => <div>{archived ? 'YES' : 'NO'}</div>,
							},
						]}
					/>
				</Col>
			</Row>

			<Row justify='end' style={{ marginTop: 54, marginBottom: 32 }}>
				<Space size={8}>
					<Button
						type='primary'
						size='large'
						onClick={() => formState.handleSubmit()}
						disabled={isCreatingCategory || imageUploading}
						loading={isCreatingCategory}>
						SAVE SUBCATEGORY
					</Button>
					{initialFormState && (
						<Button
							size='large'
							disabled={isCreatingCategory || imageUploading}
							loading={isCreatingCategory}
							onClick={handleDelete}>
							DELETE
						</Button>
					)}
				</Space>
			</Row>
			<input
				onChange={async (e) => {
					if (e?.target?.files?.length) {
						const file = e.target.files[0]
						setImageUploading(true)
						const response = await uploadImage(file)
						formState.setFieldValue('imageUrl', response.data)
						setImageUploading(false)
					}
				}}
				accept='image/*'
				type='file'
				id='file'
				ref={imageUploader}
				style={{ display: 'none' }}
			/>
		</>
	)
}

const styles = {
	skeletonContainer: {
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: '#F2F2F2',
		border: `1px solid ${colors.borderColor}`,
	},
}
