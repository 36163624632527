import { Button, Typography } from 'antd'
import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import CloseIcon from '../../../Assets/SvgIcons/CloseIcon'
import EditIcon from '../../../Assets/SvgIcons/EditIcon'
import { RootState } from '../../../store'
import { TaskTemplates } from '../taskTemplatesSlice'
import AssigneesFilter from './FilterComponents/AssigneesFilter'
import CategoryFilter from './FilterComponents/CategoryFilter'
import OrderByFilter from './FilterComponents/OrderByFilter'
import SubCategoryFilter from './FilterComponents/SubCategoryFilter'
import moment from 'moment'

export interface Cell {
	id: React.Key
	name: string
	icon?: Function
	span?: number
	fieldKey?: undefined | 'title' | 'priority'
	getValue?: Function
}

const mapState = (state: RootState) => ({
	categories: state.category.list,
})
const connector = connect(mapState, {})
type PropsFromRedux = ConnectedProps<typeof connector>
type TaskContainerProps = PropsFromRedux & {
	subCategoryId: string
}

const CategoryItem: React.FC<TaskContainerProps> = ({ categories, subCategoryId }) => {
	const categoryName =
		categories.find((cat) => cat.subCategories.some((subCat) => subCat.id === subCategoryId))?.name || ''
	return <div> {categoryName} </div>
}

const CategoryItemWrapper = connector(CategoryItem)

export const tasksTableCells: Cell[] = [
	{
		id: 'TaskName',
		name: 'Task',
		span: 3,
		getValue: (taskTemplate: TaskTemplates) => <Typography.Text strong>{taskTemplate.title}</Typography.Text>,
	},
	{
		id: 'ASSIGNTO',
		name: 'ASSIGNED TO',
		icon: () => <AssigneesFilter />,
		span: 3,
		getValue: (taskTemplate: TaskTemplates) => {
			const { assign } = taskTemplate
			return `${assign?.firstName ?? ''} ${assign?.lastName ?? ''}`
		},
	},
	{
		id: 'CATEGORY',
		name: 'CATEGORY',
		icon: () => <CategoryFilter />,
		span: 3,
		getValue: (taskTemplate: TaskTemplates) => <CategoryItemWrapper subCategoryId={taskTemplate.subCategory?.id} />,
	},
	{
		id: 'SUB CATEGORY',
		name: 'SUB CATEGORY',
		icon: () => <SubCategoryFilter />,
		span: 3,
		getValue: (taskTemplate: TaskTemplates) => taskTemplate.subCategory?.name,
	},
	{
		id: 'PRIORITY',
		name: 'PRIORITY',
		icon: () => <OrderByFilter field={'priority'} />,
		span: 2,
		getValue: (taskTemplate: TaskTemplates) => {
			let res = ''

			switch (taskTemplate.priority) {
				case 3:
					res = '!!!'
					break
				case 2:
					res = '!!'
					break
				case 1:
					res = '!'
					break

				default:
					break
			}
			return (
				<Typography.Text className='task-row-priority' title={res} type={res === '!!!' ? 'danger' : 'secondary'}>
					{res}
				</Typography.Text>
			)
		},
	},
	{
		id: 'CREATEDBY',
		name: 'CREATED BY',
		span: 3,
		getValue: (taskTemplate: TaskTemplates) => {
			const { createdBy } = taskTemplate
			return `${createdBy?.firstName ?? ''} ${createdBy?.lastName ?? ''}`
		},
	},

	{
		id: 'CREATED',
		name: 'CREATED',
		icon: () => <OrderByFilter field={'createdAt'} />,
		span: 3,
		getValue: (taskTemplate: TaskTemplates) => moment(taskTemplate.createdAt).format('DD.MM.YYYY'),
	},
	{
		id: 'NEXTBUBLISHEDDATE',
		name: 'NEXT CREATION TIME',
		span: 3,
		getValue: (taskTemplate: TaskTemplates) => moment(taskTemplate.nextPublishDate).format('DD.MM.YYYY h:mm A'),
	},
	{
		id: 'action1',
		name: '',
		getValue: (
			informAssignee: any,
			informLoading: boolean,
			editTaskTemplate: any,
			editing: boolean,
			taskTemplate: TaskTemplates
		) => (
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Button
					onClick={editTaskTemplate}
					style={{ height: 22, padding: 0 }}
					size='large'
					icon={editing ? <CloseIcon /> : <EditIcon />}
					type='text'
				/>
			</div>
		),
		span: 1,
	},
]
