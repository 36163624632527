import { DeferFn, PromiseFn } from 'react-async'

import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { handleError } from '../../../Utils/handle-error'

/**
 * this file contains 3 implementations of deleteCheckList function:
 * - action
 * - react-async promiseFn
 * - react-async deferFn
 */

/**
 * deleteCheckList action
 * [generic] - can be reused in both defer and promise functions
 */
export const deleteCheckList = async (checkList: string, signal: AbortSignal) => {
	const tenantKey = process.env.REACT_APP_TENANT_KEY
	const token = localStorage.getItem('chibi-tkn')
	const response = await fetch(`${checkbeeAPIBase}/checklist/${checkList}`, {
		signal,
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			'Tenant-Key': `${tenantKey}`,
			'Authorization': `Bearer ${token}`,
		},
	})

	const result = await response.json()
	if (result.status === 'success') {
		return result.data
	} else {
		const err = handleError(result, 'Failed to delete checklist!')
		throw new Error(err)
	}
}

/**
 * deleteCheckListPromiseFn
 * [react-async's promiseFn - automatically invoked]
 *
 * @example [usage]
 * const deleteCheckListAsync = useAsync({
 * 		promiseFn: deleteCheckListPromiseFn,
 * 		id: SOME_ID_HERE, // arguments
 * })
 * useSideEffect({
 * 		asyncFn: deleteCheckListAsync,
 * 		message: 'Successful',
 * 		onSuccess: () => {},
 * })
 *
 * @param rest
 * @param signal
 */
export const deleteCheckListPromiseFn: PromiseFn<any> = async ({ data: rest }, { signal }) => {
	return await deleteCheckList(rest, signal)
}

/**
 * deleteCheckListDeferFn
 * [react-async's deferFn - manually invoked]
 *
 * @param rest
 * @param props
 * @param signal
 */
export const deleteCheckListDeferFn: DeferFn<any> = async ([rest], props, { signal }) => {
	return await deleteCheckList(rest, signal)
}
