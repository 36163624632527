import React from 'react'

const NotifyCircle = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='25.5' height='25.5' viewBox='0 0 25.5 25.5'>
			<g id='Component_10_1' data-name='Component 10 – 1' transform='translate(1.5 1.5)'>
				<g id='Icon_ionic-ios-megaphone' data-name='Icon ionic-ios-megaphone' transform='translate(1.743 2.625)'>
					<path
						id='Path_35'
						data-name='Path 35'
						d='M4.523,9.5a2.515,2.515,0,0,1,1.191-2.16.089.089,0,0,0-.047-.164h0A2.114,2.114,0,0,0,3.639,9.09a.389.389,0,0,0-.271.408.373.373,0,0,0,.3.405,2.416,2.416,0,0,0,1.676,1.766h0a.119.119,0,0,0,.1-.205A2.728,2.728,0,0,1,4.523,9.5Z'
						transform='translate(0 -2.323)'
						fill='#727272'
					/>
					<path
						id='Path_36'
						data-name='Path 36'
						d='M17.328,4.875c-.027-.074-.057-.142-.088-.211a2.809,2.809,0,0,0-.594-.882,1.351,1.351,0,0,0-.953-.408,1.252,1.252,0,0,0-.706.214,9.765,9.765,0,0,1-4.216,1.259c-1.142,0-1.079.005-1.079.005a2.567,2.567,0,0,0-.285,4.518c.331.277.646.241.578.608s-.616,2.669-.684,3.149-.079.753.11.986a4.04,4.04,0,0,0,1.862.652c.507,0,.378-.238.378-1.2S11.395,9.96,12.1,9.96a7.382,7.382,0,0,1,3.047.868,1.467,1.467,0,0,0,.55.129,1.351,1.351,0,0,0,.95-.5,2.837,2.837,0,0,0,.594-.882c.03-.071.06-.142.09-.219a6.393,6.393,0,0,0,.375-2.242A6.4,6.4,0,0,0,17.328,4.875Zm-.728,4.3c-.175.43-.482.772-.736.772s-.487-.342-.663-.772a5.646,5.646,0,0,1-.337-2.045A5.667,5.667,0,0,1,15.2,5.078c.175-.427.408-.684.66-.684s.556.26.734.684a5.516,5.516,0,0,1,.005,4.1Z'
						transform='translate(-2.945)'
						fill='#727272'
					/>
				</g>
				<g id='Icon_feather-info' data-name='Icon feather-info' transform='translate(-13 17)'>
					<path
						id='Path_26'
						data-name='Path 26'
						d='M25.5,14.25A11.25,11.25,0,1,1,14.25,3,11.25,11.25,0,0,1,25.5,14.25Z'
						transform='translate(10 -20)'
						fill='none'
						stroke='#727272'
						stroke-linecap='round'
						stroke-linejoin='round'
						stroke-width='3'
					/>
				</g>
			</g>
		</svg>
	)
}

export default NotifyCircle
