import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from '../auth/authSlice'

export interface Company {
	id: string
	name: string
	phoneNumber: string
	emailAddress: string
	organizationNumber: string
	address: string
	users: User[]
	subscriptionEndDate: string
	stripeSubscriptionId: string | null
	isTrial: boolean
	itemsEnabled: boolean
}

export const companySlice = createSlice({
	name: 'company',
	initialState: {
		list: [] as Company[],
	},
	reducers: {
		receiveCompanies: (state, action: PayloadAction<{ companies: Company[] }>) => {
			state.list = action.payload.companies
		},
	},
})
