import React from 'react'

const InfoIcon = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='25.5' height='25.5' viewBox='0 0 25.5 25.5'>
			<g id='Icon_feather-info' data-name='Icon feather-info' transform='translate(1.5 1.5)'>
				<path
					id='Path_26'
					data-name='Path 26'
					d='M25.5,14.25A11.25,11.25,0,1,1,14.25,3,11.25,11.25,0,0,1,25.5,14.25Z'
					transform='translate(-3 -3)'
					fill='none'
					stroke='#727272'
					stroke-linecap='round'
					stroke-linejoin='round'
					stroke-width='3'
				/>
				<path
					id='Path_27'
					data-name='Path 27'
					d='M18,22.5V18'
					transform='translate(-6.75 -6.75)'
					fill='none'
					stroke='#727272'
					stroke-linecap='round'
					stroke-linejoin='round'
					stroke-width='3'
				/>
				<path
					id='Path_28'
					data-name='Path 28'
					d='M18,12h0'
					transform='translate(-6.75 -5.25)'
					fill='none'
					stroke='#727272'
					stroke-linecap='round'
					stroke-linejoin='round'
					stroke-width='3'
				/>
			</g>
		</svg>
	)
}

export default InfoIcon
