import { Button, Typography } from 'antd'
import moment from 'moment'
import React from 'react'
import CloseIcon from '../../../Assets/SvgIcons/CloseIcon'
import EditIcon from '../../../Assets/SvgIcons/EditIcon'
import { Item } from '../itemSlice'
import AssigneesFilter from './FilterComponents/AssigneesFilter'
import OrderByFilter from './FilterComponents/OrderByFilter'
import SubCategoryFilter from './FilterComponents/SubCategoryFilter'

export interface Cell {
	id: React.Key
	name: string
	icon?: Function
	span?: number
	fieldKey?: undefined | 'name'
	getValue?: Function
}

export const itemsTableCells: Cell[] = [
	{
		id: 'ItemName',
		name: 'Item',
		span: 3,
		getValue: (item: Item) => <Typography.Text strong>{item.name}</Typography.Text>,
	},
	{
		id: 'ItemType',
		name: 'Type',
		span: 2,
		getValue: (item: Item) => <Typography.Text strong>{item.type}</Typography.Text>,
	},
	{
		id: 'ItemManufacturer',
		name: 'Manufacturer',
		span: 3,
		getValue: (item: Item) => <Typography.Text strong>{item.manufacturer}</Typography.Text>,
	},
	{
		id: 'ItemProdYear',
		name: 'ProdYear',
		span: 2,
		getValue: (item: Item) => <Typography.Text strong>{item.prodYear}</Typography.Text>,
	},
	{
		id: 'ItemSerialNumber',
		name: 'SerialNumber',
		span: 2,
		getValue: (item: Item) => <Typography.Text strong>{item.serialNumber}</Typography.Text>,
	},
	{
		id: 'ASSIGNTO',
		name: 'ASSIGNED TO',
		icon: () => <AssigneesFilter />,
		span: 3,
		getValue: (item: Item) => {
			const { assign } = item
			return `${assign?.firstName ?? ''} ${assign?.lastName ?? ''}`
		},
	},
	{
		id: 'SUB CATEGORY',
		name: 'SUB CATEGORY',
		icon: () => <SubCategoryFilter />,
		span: 3,
		getValue: (item: Item) => item.subCategory?.name,
	},
	{
		id: 'CREATEDBY',
		name: 'CREATED BY',
		span: 3,
		getValue: (item: Item) => {
			const { createdBy } = item
			return `${createdBy?.firstName ?? ''} ${createdBy?.lastName ?? ''}`
		},
	},
	{
		id: 'CREATED',
		name: 'CREATED',
		icon: () => <OrderByFilter field={'createdAt'} />,
		span: 2,
		getValue: (item: Item) => moment(item.createdAt).format('DD.MM.YYYY'),
	},
	{
		id: 'action1',
		name: '',
		getValue: (informAssignee: any, informLoading: boolean, editItem: any, editing: boolean, item: Item) => (
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Button
					onClick={editItem}
					style={{ height: 22, padding: 0 }}
					size='large'
					icon={editing ? <CloseIcon /> : <EditIcon />}
					type='text'
				/>
			</div>
		),
		span: 1,
	},
]
