import React, { useState } from 'react'
import { useFormik } from 'formik'
import {
	Avatar,
	Badge,
	Button,
	Col,
	Form,
	Input,
	notification,
	Row,
	Select,
	Space,
	Typography,
	Card,
	Switch,
} from 'antd'
import type { DatePickerProps } from 'antd'
import { DeleteOutlined } from '@ant-design/icons/lib'
import styled from 'styled-components'
import * as Yup from 'yup'
import moment from 'moment'
import { ChecklistContainerProps } from '../containers/AddChecklistContainer'
import { LayoutWrapper } from '../../../Components/LayoutWrapper'
import { CheckbeeDashboardContent, CheckbeeMenuContentHeader } from '../../../Components/containers.styled'
import { CheckbeeMenu } from '../../../Components/CheckbeeMenu'
import { CheckbeeMenuContent } from '../../../Components/CheckbeeMenuContent'
import { CheckbeeCascader } from '../../../Components/CheckbeeCascader'
import { colors } from '../../../Assets/colors'
import { useAsync } from 'react-async'
import { useSideEffect } from '../../../Hooks/useSideEffect'
import { fetchCheckListDeferFn, fetchCheckListPromiseFn } from '../actions/fetchCheckList'
import { CheckList, CheckListCategory, CheckPoint, updateChecklistSorting } from '../checklistSlice'
import { User } from '../../auth/authSlice'
import { createCheckListDeferFn } from '../actions/createCheckList'
import { fetchUsersPromiseFn } from '../../user/actions/fetchUsers'
import { deleteCheckListDeferFn } from '../actions/deleteCheckList'
import DatePicker from 'antd/lib/date-picker'
import { Category, SubCategory } from '../../category/categorySlice'
import { fetchCategoriesPromiseFn } from '../../category/actions/fetchCategories'
import { QuickEditInput } from '../../../Components/QuickEditInput/QuickEditInput'
import { Item } from '../../item/itemSlice'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { VisibleItem } from '../../../Components/VisibleItem'
import CloneIcon from '../../../Assets/SvgIcons/CloneIcon'
import { ChecklistApi } from '../actions/ChecklistApi'

interface Props extends ChecklistContainerProps {
	loggedInUser: User
	availableAssignees: User[]
	checklistCategories: CheckListCategory[]
	categories: Category[]
}

const REPEATED_EVERY_OPTIONS = [
	{ key: 'DAILY', value: 'Daily' },
	{ key: 'WEEKLY', value: 'Weekly' },
	{ key: 'MONTHLY', value: 'Monthly' },
	{ key: 'YEARLY', value: 'Yearly' },
]

const EditSchema = Yup.object().shape({
	title: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
	assign: Yup.array().optional(),
	repeatedValue: Yup.string().nullable(),
	count: Yup.number().optional().nullable(),
	dateTime: Yup.date().optional().nullable(),
})

const getEveryAndCount = (repeatedInfoString: string | null) => {
	return repeatedInfoString ? JSON.parse(repeatedInfoString) : { every: null, count: null }
}

export const AddChecklist: React.FC<Props> = (props: Props) => {
	const checklists = useSelector<RootState, CheckList[]>((state) => state.checklist.checklists)
	const dispatch = useDispatch()
	// ephemeral states
	/** if the field is set to edit or not */
	const [editState, setEditState] = React.useState({
		title: false,
		checkpoint: false,
		category: false,
	})

	const [syncLoading, setSyncLoading] = useState(false)
	const [subcategories, setSubCategories] = React.useState<SubCategory[]>([])
	const items: Item[] = useSelector<RootState, Item[]>((state) => state.item.list)

	const [selected, setSelected] = React.useState<CheckList | undefined>(checklists[0])
	const [selectedCategoryIndex, setSelectedCategoryIndex] = React.useState<number | undefined>(selected ? 0 : undefined)
	const [selectedSubCategoryId, setSelectedSubCategoryId] = React.useState<string | undefined>(
		(checklists[0]?.subcategory as SubCategory)?.id
	)
	const [selectedCheckPointIndex, setSelectedCheckPointIndex] = React.useState<number | undefined>(
		selected ? 0 : undefined
	)

	const [itemId, setItemId] = useState<string>(checklists[0]?.itemId ?? '')
	const itemsEnabled = useSelector<RootState, boolean>((state) => state.auth.user.company.itemsEnabled)

	const [toDeleteCatsIds, setToDeleteCatsIds] = useState<string[]>([])
	const [toDeleteCheckpointsIds, setToDeleteCheckpointsIds] = useState<string[]>([])

	const fetchChecklistAsync = useAsync({
		promiseFn: fetchCheckListPromiseFn,
		deferFn: fetchCheckListDeferFn,
	})
	useSideEffect({
		asyncFn: fetchChecklistAsync,
		onSuccess: () => {
			const { data: checklist } = fetchChecklistAsync
			props.receiveCheckList({ checklist })
			console.log(selected)
			if (selected?.isNew) {
				setSelected(checklist[checklist.length - 1])
			} else {
				const c = (checklist as CheckList[]).find((e) => e.id === selected?.id)
				if (c) {
					setSelected(c)
				} else {
					setSelected(undefined)
				}
			}
		},
	})

	// fetch categories block
	const fetchCategoriesAsync = useAsync({ promiseFn: fetchCategoriesPromiseFn })
	useSideEffect({
		asyncFn: fetchCategoriesAsync,
		onSuccess: () => {
			const { data: categories } = fetchCategoriesAsync
			props.receiveCategories({ categories })
			const subCats: SubCategory[] = []
			categories.forEach((m: Category) => subCats.push(...m.subCategories))
			subCats.sort((subCat1, subCats2) => subCat1.name.localeCompare(subCats2.name))
			setSubCategories(subCats)
		},
	})

	const fetchUsersAsync = useAsync({
		promiseFn: fetchUsersPromiseFn,
		query: { company: props.loggedInUser.company.id },
	})
	useSideEffect({
		asyncFn: fetchUsersAsync,
		onSuccess: () => {
			const { data: users } = fetchUsersAsync
			const unArchivedUsers = users.filter((user: any) => !user.archived)
			props.receiveAvailableAssignees({ users: unArchivedUsers })
		},
	})

	const editFormState = useFormik({
		validationSchema: EditSchema,
		enableReinitialize: true,
		initialValues: {
			title: selected ? selected.title : '',
			assign: selected ? (selected.assign as User[]) : [],
			count: JSON.parse(selected?.repeatedInfo ?? '{}').count ?? null,
			repeatedValue: JSON.parse(selected?.repeatedInfo ?? '{}').every ?? null,
			dateTime: selected?.nextPublishDate ? new Date(selected.nextPublishDate) : null,
			notificationEnabled: !!(selected ? selected.repeatedInfo && selected.nextPublishDate : false),
		},
		onSubmit: (values) => {
			setSyncLoading(true)
			setEditState({ title: false, checkpoint: false, category: false })
			const ni = checklists.findIndex((e) => e.id === selected?.id)
			const request: CheckList = {
				id: selected?.isNew ? '' : selected?.id,
				title: selected?.title !== values.title ? values.title : selected.title,
				subcategory: selectedSubCategoryId as string,
				assign: getChangedAssignees(),
				repeatedInfo: editFormState.values.notificationEnabled
					? JSON.stringify({ every: values.repeatedValue, count: parseInt(values.count) })
					: null,
				nextPublishDate: editFormState.values.notificationEnabled ? values.dateTime!.toISOString() : null,
				categories: getChangedCategories(ni).map((e, index) => ({
					id: e.isNew ? '' : e.id,
					name: e.isNew ? e.name : e.name !== props.checklistCategories[index]?.name ? e.name : '',
					checkpoints: e.checkpoints.map(
						(m): CheckPoint => ({
							id: m.isNew ? '' : m.id,
							title: m.isNew ? m.title : m.isEdited ? m.title : '',
							description: m.description || '',
							status: '',
							sort: m.sort,
						})
					),
					sort: e.sort,
				})),
			}

			if (!request.assign?.length) delete request.assign
			if (itemId) request.itemId = itemId

			createCheckListAsync.run(request)
		},
	})

	const createCheckListAsync = useAsync({ deferFn: createCheckListDeferFn })
	useSideEffect({
		asyncFn: createCheckListAsync,
		onSuccess: async () => {
			try {
				const deleteCatsPromises = toDeleteCatsIds.map((catId) => ChecklistApi.deleteCategory(catId))
				await Promise.all(deleteCatsPromises)
				const deleteCheckpointPromises = toDeleteCheckpointsIds.map((catId) => ChecklistApi.deleteCheckpoint(catId))
				await Promise.all(deleteCheckpointPromises)
				await fetchChecklistAsync.run()
				setToDeleteCatsIds([])
				setToDeleteCheckpointsIds([])
				setSyncLoading(false)
				notification.success({ message: 'A CheckList was saved successfully.' })
			} catch (e) {
				setSyncLoading(false)
				notification.success({ message: 'Something went wrong!' })
			}
		},
	})

	const deleteCheckListAsync = useAsync({ deferFn: deleteCheckListDeferFn })
	const initiateDeleteCheckList = (value: string) => {
		deleteCheckListAsync.run(value)
		setSelectedSubCategoryId(undefined)
		setSelectedCategoryIndex(undefined)
	}
	useSideEffect({
		asyncFn: deleteCheckListAsync,
		onSuccess: async () => {
			notification.success({ message: 'A CheckList was deleted successfully.' })
			await fetchChecklistAsync.run()

			setSelected(checklists[0])
			setSelectedCategoryIndex(0)
			setSelectedSubCategoryId((checklists[0]?.subcategory as SubCategory)?.id)
			setSelectedCheckPointIndex(0)
		},
	})

	const getChanges = () => {
		if (selected && !selected.isNew) {
			const ni = checklists.findIndex((e) => e.id === selected.id)
			const newAssignees = getChangedAssignees()
			const oldAssignees = checklists[ni].assign
			const isAssigneesChanged =
				newAssignees.length !== (oldAssignees?.length ?? 0) ||
				!newAssignees.every((user) => oldAssignees?.some((oldUser: any) => oldUser.id === user))
			const { every, count } = getEveryAndCount(selected.repeatedInfo)
			return (
				editFormState.values?.title !== selected.title ||
				getChangedCategories(ni).length > 0 ||
				isAssigneesChanged ||
				toDeleteCatsIds.length ||
				toDeleteCheckpointsIds.length ||
				editFormState.values.repeatedValue !== every ||
				editFormState.values.count !== count ||
				(editFormState.values.dateTime?.toISOString() ?? null) !== selected.nextPublishDate
			)
		}

		const isAtLeastOneCheckpoint = selected?.categories.length
			? selected?.categories.every((cat) => cat.checkpoints.length)
			: false

		return isAtLeastOneCheckpoint
	}

	const getChangedCategories = (ni: number) => {
		if (selected && !selected.isNew) {
			return selected.categories
				.map((e) => ({
					...e,
					checkpoints: e.checkpoints.filter((m) => m.isEdited || m.isNew),
				}))
				.filter((e, index) => {
					if (!e.isNew) {
						return (
							e.name !== checklists[ni].categories[index]?.name ||
							e.checkpoints.length > 0 ||
							e.sort !== checklists[ni].categories[index]?.sort
						)
					}
					return e.isNew
				})
		} else {
			return selected?.categories ?? []
		}
	}
	const getChangedAssignees = (): string[] => editFormState.values.assign.map((e) => e.id)
	const availableUsers = props.availableAssignees.filter((e) =>
		e.subCategories.some((subCat) => subCat.id === selectedSubCategoryId)
	)

	const addDuplicateFromCurrent = () => {
		const newDup = { ...selected }
		delete newDup.id

		newDup.subcategory = (newDup.subcategory as SubCategory)?.id
		newDup.categories = ((newDup.categories as CheckListCategory[]) ?? [])?.map((cat) => ({
			name: cat.name,
			checkpoints: cat.checkpoints.map((checkpoint) => ({
				title: checkpoint.title,
				description: checkpoint.description,
			})),
		})) as CheckListCategory[]
		newDup.assign = ((newDup.assign as User[]) ?? [])?.map((user) => user.id)

		newDup.title = newDup.title + ' copy'

		createCheckListAsync.run(newDup)
	}

	const renderActions = (type: string, { index }: { index: number }) => {
		const removeCheckpoint = () => {
			setSelected(
				selected && {
					...selected,
					categories:
						selected?.categories.map((cat, catIdx) => ({
							...cat,
							checkpoints: cat.checkpoints.filter((_, idx) => idx !== index && selectedCategoryIndex === catIdx),
						})) ?? [],
				}
			)

			const removedCheckpoint = selected?.categories[selectedCategoryIndex ?? 0].checkpoints[index]

			if (removedCheckpoint && !removedCheckpoint.isNew) {
				setToDeleteCheckpointsIds([...toDeleteCheckpointsIds, removedCheckpoint.id])
			}
		}

		const removeArea = () => {
			setSelected((cl) =>
				cl
					? {
							...cl,
							categories: cl.categories.filter((_, idx) => idx !== index),
					  }
					: undefined
			)

			// collect cats & checkpoints to remove
			const removedCat = selected?.categories[index]
			if (removedCat && !removedCat.isNew) {
				setToDeleteCatsIds([...toDeleteCatsIds, removedCat.id ?? ''])
				setToDeleteCheckpointsIds([
					...toDeleteCheckpointsIds,
					...removedCat.checkpoints.filter((cp) => !cp.isNew).map((cp) => cp.id),
				])
			}
		}

		const cloneCheckpoint = () => {
			const length = selected!.categories[selectedCategoryIndex ?? 0].checkpoints.length ?? 0
			const checkpointToClone = selected!.categories[selectedCategoryIndex ?? 0].checkpoints[
				selectedCheckPointIndex ?? 0
			]
			setSelected(
				selected && {
					...selected,
					categories:
						selected?.categories.map((cat, catIdx) => ({
							...cat,
							checkpoints:
								catIdx === selectedCategoryIndex
									? cat.checkpoints.concat([
											{
												...checkpointToClone,
												id: 'new_checkpoint-' + cat.checkpoints.length,
												title: checkpointToClone.title + ' copy',
												isNew: true,
												isEdited: true,
												sort: cat.checkpoints.length,
											},
									  ])
									: cat.checkpoints,
						})) ?? [],
				}
			)
			setSelectedCheckPointIndex(length)
		}

		const cloneArea = () => {
			const selectedArea = selected?.categories[index]
			setSelected((cl) =>
				cl
					? {
							...cl,
							categories: cl.categories.concat({
								name: selectedArea?.name + ' Copy',
								id: 'new-area-' + (cl.categories ?? []).length,
								isNew: true,
								checkpoints:
									selectedArea?.checkpoints.map((checkPoint, idx) => ({
										id: 'new_checkpoint-' + checkPoint.id,
										title: checkPoint?.title,
										description: checkPoint?.description,
										assign: undefined,
										createdBy: undefined,
										imageUrls: [],
										status: '',
										task: undefined,
										isEdited: true,
										isNew: true,
										sort: idx,
									})) ?? [],
								updatedBy: undefined,
								createdBy: undefined,
								sort: cl.categories.length,
							}),
					  }
					: undefined
			)
			setSelectedCheckPointIndex(selectedArea?.checkpoints.length ? 0 : undefined)
			setSelectedCategoryIndex(selected?.categories.length)
		}
		return (
			<div>
				<Button
					style={{ padding: 0, marginRight: 10 }}
					type='text'
					onClick={(e) => {
						e.stopPropagation()
						type === 'AREA' ? removeArea() : removeCheckpoint()
					}}>
					<DeleteOutlined style={{ fontSize: 18 }} />
				</Button>
				<Button
					style={{ padding: 0 }}
					type='text'
					onClick={(e) => {
						e.stopPropagation()
						type === 'AREA' ? cloneArea() : cloneCheckpoint()
					}}>
					<CloneIcon />
				</Button>
			</div>
		)
	}

	const onSortAreasEnd = async ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
		if (!selected) {
			return
		}
		let currentChecklistAreas = [...(selected?.categories ?? [])]
		const b = { ...currentChecklistAreas[oldIndex] }
		currentChecklistAreas.splice(oldIndex, 1)
		currentChecklistAreas.splice(newIndex, 0, b)

		setSelected({
			...selected,
			categories: currentChecklistAreas.map((item, idx) => ({ ...item, sort: idx })),
		})
		setSelectedCategoryIndex(newIndex)
	}

	const onSortCheckpointsEnd = async ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
		if (!selected) {
			return
		}
		let currentAreaCheckpoint = [...(selected?.categories[selectedCategoryIndex ?? 0].checkpoints ?? [])]
		const b = { ...currentAreaCheckpoint[oldIndex] }
		currentAreaCheckpoint.splice(oldIndex, 1)
		currentAreaCheckpoint.splice(newIndex, 0, b)

		setSelected({
			...selected,
			categories: selected.categories.map((cat, index) =>
				index === selectedCategoryIndex
					? {
							...cat,
							checkpoints: currentAreaCheckpoint.map((item, idx) => ({
								...item,
								isEdited: true,
								sort: idx,
							})),
					  }
					: cat
			),
		})
		setSelectedCheckPointIndex(newIndex)
		dispatch(
			updateChecklistSorting({
				checklistId: selected.id || '',
				categoryIdx: selectedCategoryIndex ?? 0,
				checkpoints: currentAreaCheckpoint,
			})
		)
	}

	return (
		<LayoutWrapper>
			<Typography.Title level={4}>
				Manage your CHECKLISTS here. Create checklists, add checkpoints and assign to users.
			</Typography.Title>

			<CheckbeeDashboardContent>
				<CheckbeeMenu
					items={checklists
						.map((e) => ({ key: e.id as string, content: e.title }))
						.concat(selected?.isNew ? { key: selected.id as string, content: selected.title } : [])}
					title='CHECKLISTS'
					loading={fetchChecklistAsync.isPending}
					onFooterClick={() => {
						setEditState({ ...editState, title: true })
						setSelected({
							createdBy: undefined,
							image: '',
							updatedBy: undefined,
							id: 'new-checklist',
							assign: [] as User[],
							title: 'New Checklist',
							categories: [],
							subcategory: selectedSubCategoryId as string,
							isNew: true,
							repeatedInfo: null,
							nextPublishDate: null,
						})
						setItemId('')
					}}
					renderRow={(row: any) => {
						return (
							<QuickEditInput
								value={row.content}
								onChange={(newName: string) => {
									editFormState.setFieldValue('title', newName)
									const newSelected = { ...selected, title: newName }
									setSelected(newSelected as CheckList)
									if (row.key !== 'new-checklist') {
										// not add mode
										editFormState.handleSubmit()
									}
								}}
							/>
						)
					}}
					selectedKeys={[selected?.id ?? '']}
					onSelect={({ key }) => {
						const value = checklists.find((el) => el.id == key)
						if (value) {
							setEditState({ title: false, checkpoint: false, category: false })
							setSelected(value)
							setSelectedCategoryIndex(0)
							setSelectedSubCategoryId((value.subcategory as SubCategory)?.id)
							setSelectedCheckPointIndex(0)
							setItemId(value.itemId ?? '')

							value.nextPublishDate
								? editFormState.setFieldValue('dateTime', new Date(value.nextPublishDate))
								: editFormState.setFieldValue('dateTime', null)
							const { every, count } = getEveryAndCount(value.repeatedInfo)
							editFormState.setFieldValue('repeatedValue', every)
							editFormState.setFieldValue('count', count)
							editFormState.setFieldValue('notificationEnabled', !!(value.repeatedInfo && value.nextPublishDate))
						}
					}}
				/>
				<Content>
					<Row gutter={24}>
						{/* menu content */}
						<Col span={22}>
							<CheckbeeMenuContent style={{ marginLeft: undefined }}>
								{/* header */}
								<CheckbeeMenuContentHeader>
									<Avatar size='large' style={{ marginRight: 8 }} />
									{editState.title ? (
										// checklist title - edit mode
										<Form
											layout='inline'
											size='large'
											onBlur={() => setEditState({ ...editState, title: !editState.title })}>
											<Form.Item
												validateStatus={editFormState.errors.title ? 'error' : ''}
												style={{ marginRight: 8, maxWidth: 120 }}>
												<Input
													placeholder={'Title'}
													size='large'
													name={'title'}
													value={editFormState.values.title}
													onChange={(e) => {
														editFormState.handleChange(e)
														editFormState.setFieldValue('title', e.target.value)
													}}
												/>
											</Form.Item>
										</Form>
									) : (
										// checklist title - view mode
										<div onClick={() => setEditState({ ...editState, title: !editState.title })}>
											<Typography.Title level={3} style={{ margin: '0 8px' }}>
												{editFormState.values.title}{' '}
											</Typography.Title>
										</div>
									)}
									{editState.category ? (
										// checklist title - edit mode
										<Form
											layout='inline'
											size='large'
											onBlur={() => setEditState({ ...editState, category: !editState.category })}>
											<Form.Item
												validateStatus={editFormState.errors?.title ? 'error' : ''}
												style={{ marginRight: 8, maxWidth: 120 }}>
												<Input
													placeholder='Area'
													size='large'
													disabled={selectedCategoryIndex === undefined}
													name='title'
													value={
														selectedCategoryIndex !== undefined && selected
															? selected.categories[selectedCategoryIndex]?.name
															: ''
													}
													onChange={(e) => {
														if (selectedCategoryIndex !== undefined) {
															setSelected((cl) =>
																cl
																	? {
																			...cl,
																			categories: cl.categories.map((m, index) => {
																				if (index === selectedCategoryIndex) {
																					return {
																						...m,
																						name: e.target.value,
																					}
																				}
																				return m
																			}),
																	  }
																	: undefined
															)
														}
													}}
												/>
											</Form.Item>
										</Form>
									) : (
										// checklist title - view mode
										<div onClick={() => setEditState({ ...editState, category: !editState.category })}>
											<Typography.Title level={3} style={{ margin: '0 8px' }}>
												{selectedCategoryIndex !== undefined && selected
													? selected.categories[selectedCategoryIndex]?.name
													: ''}{' '}
											</Typography.Title>
										</div>
									)}
									{/* changes unsaved badge */}
									{getChanges() ? <Badge status='warning' text='Unsaved' style={{ marginLeft: 12 }} /> : null}
									{/* right aligned header */}
									<VisibleItem visible={!selected?.isNew}>
										<Button onClick={addDuplicateFromCurrent} style={{ position: 'absolute', right: 0 }}>
											Duplicate
										</Button>
									</VisibleItem>
								</CheckbeeMenuContentHeader>

								<Row gutter={18} style={{ marginTop: 24 }}>
									{/* categories */}
									<Col span={7}>
										<CheckbeeCascader
											onSortEnd={onSortAreasEnd}
											selectedItem={selected?.categories[selectedCategoryIndex as number]?.id}
											showFooter
											showTickAll={false}
											title='AREAS'
											allowInlineEditing={true}
											renderItemRight={(data) => renderActions('AREA', data)}
											onFooterClick={() => {
												setSelected((cl) =>
													cl
														? {
																...cl,
																categories: cl.categories.concat({
																	name: 'New Area',
																	id: 'new-area-' + (cl.categories ?? []).length,
																	isNew: true,
																	checkpoints: [],
																	updatedBy: undefined,
																	createdBy: undefined,
																	sort: cl.categories.length,
																}),
														  }
														: undefined
												)
												setSelectedCheckPointIndex(undefined)
												setSelectedCategoryIndex(selected?.categories.length)
											}}
											itemObjects={
												selected
													? selected.categories.map((m) => ({
															key: m.id as string,
															value: m.name,
													  }))
													: []
											}
											onItemClick={(e) => {
												setSelectedCheckPointIndex(0)
												setSelectedCategoryIndex(e.index)
												setEditState({ ...editState, checkpoint: false, category: false })
											}}
											onChange={(index, value) => {
												setSelected((checklist) =>
													checklist
														? {
																...checklist,
																categories: checklist.categories.map((c, i) => {
																	if (index === i) {
																		return {
																			...c,
																			name: value,
																		}
																	}
																	return c
																}),
														  }
														: undefined
												)
											}}
										/>
									</Col>
									{/* checkpoints */}
									<Col span={7}>
										<CheckbeeCascader
											onSortEnd={onSortCheckpointsEnd}
											allowInlineEditing={true}
											selectedItem={
												selected?.categories[selectedCategoryIndex as number]?.checkpoints[
													selectedCheckPointIndex as number
												]?.id
											}
											showFooter={selected && selectedCategoryIndex !== undefined}
											showTickAll={false}
											renderItemRight={(data) => renderActions('CHECKPOINT', data)}
											title={'CHECKPOINTS'}
											itemObjects={
												selectedCategoryIndex !== undefined && selected
													? (selected.categories[selectedCategoryIndex]?.checkpoints ?? []).map((e) => ({
															key: e.id,
															value: e.title,
													  }))
													: []
											}
											onItemClick={(value) => {
												setSelectedCheckPointIndex(value.index)
												setEditState({ ...editState, checkpoint: false })
											}}
											onFooterClick={() => {
												setSelected((cl) =>
													cl
														? {
																...cl,
																categories: cl.categories.map((m, index) => {
																	if (index === selectedCategoryIndex) {
																		return {
																			...m,
																			checkpoints: [
																				...m.checkpoints,
																				{
																					id: 'new_checkpoint-' + (m.checkpoints ?? []).length,
																					title: 'new checkpoint',
																					description: 'checkpoint description',
																					assign: undefined,
																					createdBy: undefined,
																					imageUrls: [],
																					status: '',
																					task: undefined,
																					isEdited: true,
																					isNew: true,
																					sort: (m.checkpoints ?? []).length,
																				},
																			],
																		}
																	}
																	return m
																}),
														  }
														: undefined
												)
												setSelectedCheckPointIndex(
													selected?.categories[+(selectedCategoryIndex as number)].checkpoints.length
												)
											}}
											onChange={(index, value) =>
												setSelected((checklist) =>
													checklist
														? {
																...checklist,
																categories: checklist.categories.map((c, i) => {
																	if (selectedCategoryIndex === i) {
																		return {
																			...c,
																			checkpoints: c.checkpoints.map((chP, j) => {
																				if (index === j) {
																					return { ...chP, title: value, isEdited: true }
																				}
																				return chP
																			}),
																		}
																	}
																	return c
																}),
														  }
														: undefined
												)
											}
										/>
									</Col>
									{/* checklist details */}
									<Col span={10}>
										<DetailsHeader>
											{editState.checkpoint ? (
												// checklist title - edit mode
												<Input
													placeholder={'Title'}
													size='large'
													disabled={selectedCheckPointIndex === undefined}
													name='checkpoint'
													value={
														selectedCategoryIndex !== undefined && selectedCheckPointIndex !== undefined && selected
															? selected.categories[selectedCategoryIndex].checkpoints[selectedCheckPointIndex].title
															: ''
													}
													onBlur={() => setEditState({ ...editState, checkpoint: !editState.checkpoint })}
													onChange={(e) =>
														setSelected((cl) =>
															cl
																? {
																		...cl,
																		categories: cl.categories.map((m, index) => {
																			if (index === selectedCategoryIndex) {
																				return {
																					...m,
																					checkpoints: m.checkpoints.map((n, j) => {
																						if (j === selectedCheckPointIndex) {
																							return { ...n, title: e.target?.value, isEdited: true }
																						}
																						return n
																					}),
																				}
																			}
																			return m
																		}),
																  }
																: undefined
														)
													}
												/>
											) : (
												// checklist title - view mode
												<div onClick={() => setEditState({ ...editState, checkpoint: !editState.checkpoint })}>
													<Typography.Title
														level={4}
														ellipsis={{
															rows: 1,
														}}>
														{selectedCheckPointIndex !== undefined && selectedCategoryIndex !== undefined && selected
															? selected.categories[selectedCategoryIndex]?.checkpoints[selectedCheckPointIndex]?.title
															: ''}
													</Typography.Title>
												</div>
											)}
										</DetailsHeader>
										<DetailsContainer>
											<Form layout='vertical'>
												<Form.Item label={'DESCRIPTION'}>
													<Input.TextArea
														value={
															selectedCheckPointIndex !== undefined && selectedCategoryIndex !== undefined && selected
																? selected.categories[selectedCategoryIndex]?.checkpoints[selectedCheckPointIndex]
																		?.description
																: ''
														}
														onChange={(e) =>
															setSelected((cl) =>
																cl
																	? {
																			...cl,
																			categories: cl.categories.map((m, index) => {
																				if (index === selectedCategoryIndex) {
																					return {
																						...m,
																						checkpoints: m.checkpoints.map((n, j) => {
																							if (j === selectedCheckPointIndex) {
																								return { ...n, description: e.target?.value, isEdited: true }
																							}
																							return n
																						}),
																					}
																				}
																				return m
																			}),
																	  }
																	: undefined
															)
														}
													/>
												</Form.Item>
											</Form>
										</DetailsContainer>
									</Col>
								</Row>

								<Row gutter={24} style={{ marginTop: 30 }}>
									<Col span={12}>
										{/* subcategory */}
										<Form.Item hasFeedback style={{ width: '100%' }}>
											<Input.Group compact style={{ display: 'flex' }}>
												<Input disabled size='large' defaultValue='SUBCATEGORY' />
												<Select
													style={{ flex: '1 0 66%' }}
													size='large'
													value={selectedSubCategoryId || 'unselected'}
													onChange={(value) => {
														setSelectedSubCategoryId(value)
														editFormState.setFieldValue('assign', [])
													}}>
													{(subcategories ?? []).map((sub) => (
														<Select.Option key={sub.id} value={sub.id}>
															{sub.name}
														</Select.Option>
													))}
												</Select>
											</Input.Group>
										</Form.Item>
										<VisibleItem visible={itemsEnabled}>
											<Form.Item hasFeedback style={{ width: '100%' }}>
												<Input.Group compact style={{ display: 'flex' }}>
													<Input disabled size='large' defaultValue='ITEM' />
													<Select
														style={{ flex: '1 0 66%' }}
														size='large'
														value={itemId}
														onChange={(value) => {
															setItemId(value)
														}}>
														<Select.Option value={''}>Link an item(Optional)</Select.Option>
														{(items ?? [])
															.filter((item) => item.subCategoryId === selectedSubCategoryId)
															.map((item) => (
																<Select.Option key={item.id} value={item.id}>
																	{item.name}
																</Select.Option>
															))}
													</Select>
												</Input.Group>
											</Form.Item>
										</VisibleItem>
									</Col>

									<Col span={12}>
										{/* assign */}
										<Row>
											<Form.Item
												hasFeedback
												validateStatus={editFormState.touched.assign && editFormState.errors.assign ? 'error' : ''}
												style={{ minWidth: '100%' }}>
												<Input.Group compact style={{ display: 'flex' }}>
													<Input disabled size='large' defaultValue='ASSIGNED TO' />
													<Select
														style={{ flex: '1 0 65%' }}
														size='large'
														mode='multiple'
														allowClear
														placeholder='No assignee'
														disabled={!selected}
														value={(editFormState.values?.assign ?? []).map((e: User) => e?.id)}
														onBlur={() => editFormState.setFieldTouched('assign', true)}
														onChange={(value) => {
															if (value.length) {
																const d = (value ?? []).map((e: string) =>
																	props.availableAssignees.find((m) => m.id === e)
																)
																editFormState.setFieldValue('assign', d)
															} else {
																editFormState.setFieldValue('assign', [])
															}
														}}>
														{availableUsers.map((assign) => (
															<Select.Option key={assign.id} value={assign.id}>
																{assign.firstName + ' ' + assign.lastName}
															</Select.Option>
														))}
													</Select>
												</Input.Group>
											</Form.Item>
										</Row>
									</Col>
								</Row>
								<Card
									headStyle={{ paddingTop: 5, paddingBottom: 5 }}
									bodyStyle={{
										paddingBottom: 5,
										paddingTop: 25,
										display: editFormState.values.notificationEnabled ? 'block' : 'none',
									}}
									title='Is Notifications Enabled?'
									extra={
										<Switch
											defaultChecked={editFormState.values.notificationEnabled}
											onChange={(checked) => {
												editFormState.setFieldValue('repeatedValue', REPEATED_EVERY_OPTIONS[0].key)
												editFormState.setFieldValue('dateTime', new Date())
												editFormState.setFieldValue('notificationEnabled', checked)
												editFormState.setFieldValue('count', 1)

												if (!checked) {
													editFormState.setFieldValue('repeatedValue', null)
													editFormState.setFieldValue('dateTime', null)
													editFormState.setFieldValue('count', null)
												}
											}}
											checked={editFormState.values.notificationEnabled}
										/>
									}
									style={{ width: '100%', marginTop: 10 }}>
									{editFormState.values.notificationEnabled && (
										<Row gutter={24}>
											<Col span={12}>
												{/* Start From */}
												<Row>
													<Form.Item hasFeedback style={{ minWidth: '100%' }}>
														<Input.Group compact style={{ display: 'flex' }}>
															<Input disabled size='large' defaultValue='START FROM' />
															<DatePicker
																style={{ flex: '1 0 65%', paddingTop: 8 }}
																size='large'
																disabledDate={(current) => current.isBefore(moment())}
																showTime={{ format: 'hh:mm A', use12Hours: true }}
																use12Hours
																showSecond={false}
																minuteStep={30}
																allowClear={false}
																format={'DD.MM.YYYY h:mm A'}
																name='dateTime'
																value={moment(editFormState.values.dateTime)}
																onChange={(date) => editFormState.setFieldValue('dateTime', date)} // Set the dateTime field value
															/>
														</Input.Group>
													</Form.Item>
												</Row>
											</Col>
											<Col span={12}>
												{/* repeat */}
												<Row>
													<Form.Item hasFeedback style={{ minWidth: '100%' }}>
														<Input.Group compact style={{ display: 'flex' }}>
															<Input disabled size='large' defaultValue='REPEATED EVERY' />
															<div style={{ display: 'flex', flex: '1 0 56%' }}>
																<Select
																	value={editFormState.values.repeatedValue}
																	style={{ width: '100%', borderRadius: 0 }}
																	size='large'
																	defaultValue={REPEATED_EVERY_OPTIONS[0].key}
																	onChange={(value) => editFormState.setFieldValue('repeatedValue', value)}>
																	{REPEATED_EVERY_OPTIONS.map((item) => {
																		return (
																			<Select.Option key={item.key} value={item.key}>
																				{item.value}
																			</Select.Option>
																		)
																	})}
																</Select>

																<Input
																	size='large'
																	style={{ width: '37%', borderLeft: 0 }}
																	defaultValue='1'
																	name='count'
																	onChange={editFormState.handleChange}
																	onBlur={editFormState.handleBlur}
																	placeholder='count'
																	value={editFormState.values.count}
																/>
															</div>
														</Input.Group>
													</Form.Item>
												</Row>
											</Col>
										</Row>
									)}
								</Card>
							</CheckbeeMenuContent>
							<Space size={8} style={{ marginTop: 32, float: 'right' }}>
								<Button
									disabled={
										!getChanges() || syncLoading || Object.values(editFormState.errors).some((error) => !!error)
									}
									size={'large'}
									type={'primary'}
									loading={syncLoading}
									onClick={() => editFormState.handleSubmit()}>
									SAVE CHECKLIST
								</Button>
								<Button
									disabled={!selected || syncLoading}
									size={'large'}
									onClick={() => {
										if (selected && !selected?.isNew) {
											initiateDeleteCheckList(selected.id as string)
										}
										setSelected(undefined)
									}}>
									DELETE
								</Button>
							</Space>
						</Col>
					</Row>
				</Content>
			</CheckbeeDashboardContent>
		</LayoutWrapper>
	)
}

const Content = styled.div`
	width: 100%;
	margin-left: 32px;
`

const DetailsHeader = styled.div`
	border: 1px solid ${colors.borderColor};
	padding-left: 18px;
	padding-right: 18px;
	height: 42px;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: nowrap;
	margin-bottom: 8px;
`
const DetailsContainer = styled.div`
	border: 1px solid ${colors.borderColor};
	padding: 10px;
	font-weight: bold;
`
