import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from '../auth/authSlice'
import { SubCategory } from '../category/categorySlice'

export type CheckList = {
	id: string | undefined
	title: string
	image?: string
	assign?: User[] | string[]

	nextPublishDate: string | null
	repeatedInfo: string | null

	categories: CheckListCategory[]
	subcategory: SubCategory | string

	createdBy?: string
	updatedBy?: string

	isNew?: boolean
	itemId?: string
}

export type CheckListCategory = {
	id: string | undefined
	name: string

	checkpoints: CheckPoint[]

	createdBy?: string
	updatedBy?: string

	isNew?: boolean
	sort: number
}

export type CheckPoint = {
	id: string
	title: string
	description: string
	imageUrls?: string[]
	status: string
	task?: string

	createdBy?: string
	updatedBy?: string

	isNew?: boolean
	isEdited?: boolean
	sort: number
}

export const checklistSlice = createSlice({
	name: 'checklist',
	initialState: {
		checklists: [] as CheckList[],
		checklistCategories: [] as CheckListCategory[],
	},
	reducers: {
		/** example reducer */
		receiveCheckList: (state, action: PayloadAction<{ checklist: CheckList[] }>) => {
			state.checklists = action.payload.checklist
		},
		receiveCheckListCategories: (state, action: PayloadAction<{ checklistCategories: CheckListCategory[] }>) => {
			state.checklistCategories = action.payload.checklistCategories
		},
		updateChecklistSorting: (
			state,
			action: PayloadAction<{ checklistId: string; categoryIdx: number; checkpoints: CheckPoint[] }>
		) => {
			const { checklistId, categoryIdx, checkpoints } = action.payload
			const checklistIdx = state.checklists.findIndex((ch) => ch.id === checklistId)

			state.checklists[checklistIdx].categories[categoryIdx].checkpoints = checkpoints
		},
	},
})

export const { updateChecklistSorting } = checklistSlice.actions
