/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Col, Form, Input, Row, Radio, Upload, Button, Select, Tooltip, DatePicker, Modal } from 'antd'
import {
	ExclamationOutlined,
	FileTextOutlined,
	InfoCircleOutlined,
	MessageOutlined,
	UserOutlined,
	ApartmentOutlined,
	PlusOutlined,
} from '@ant-design/icons/lib'
import styled from 'styled-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { colors } from '../../../Assets/colors'
import moment, { Moment } from 'moment'
import PlusIcon from '../../../Assets/SvgIcons/PlusIcon'
import DateIcon from '../../../Assets/SvgIcons/DateIcon'
import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { uploadImage } from '../actions/uploadImage'
import { User } from '../../auth/authSlice'
import { Category } from '../../category/categorySlice'
import { Item } from '../../item/itemSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { VisibleItem } from '../../../Components/VisibleItem'

interface Props {
	isCreatingTask: boolean
	isGettingCategories: boolean
	isGettingUsers: boolean
	isCreateTaskFulfilled: boolean
	isGettingTasks: boolean
	createTask: (values: TaskForm) => void
	availableAssignees: User[]
	categories: Category[]
}

const StyledForm = styled(Form)`
	.ant-form-item-label > label {
		height: 38px;
	}
`

export type TaskForm = {
	title: string
	description?: string
	priority: number
	category: string // id
	subCategory: string // id
	assign?: string // id
	dueDate?: string
	item?: string
}

const TaskFormSchema = Yup.object().shape({
	title: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
	description: Yup.string().nullable(),
	priority: Yup.number().required('Required'),
	category: Yup.string().required('Required'),
	subCategory: Yup.string().required('Required'),
	dueDate: Yup.date(),
})

export const AddTask: React.FC<Props> = ({
	availableAssignees,
	isGettingUsers,
	isCreatingTask,
	isGettingCategories,
	isCreateTaskFulfilled,
	categories,
	createTask,
}) => {
	const [images, setImages] = React.useState<any[]>([])
	const [modalVisible, setModalVisible] = React.useState(false)
	const [selectedCatIndex, setSelectedCatIndex] = React.useState(0)
	const items: Item[] = useSelector<RootState, Item[]>((state) => state.item.list)
	const itemsEnabled = useSelector<RootState, boolean>((state) => state.auth.user.company.itemsEnabled)

	const initialValues: TaskForm = {
		title: '',
		description: '',
		priority: 1,
		category: categories[0]?.id ?? '',
		subCategory: categories[0]?.subCategories[0]?.id ?? '',
		assign: '',
		item: '',
		dueDate: '',
	}

	const [form] = Form.useForm()
	const formState = useFormik({
		enableReinitialize: true,
		initialValues: initialValues,
		validationSchema: TaskFormSchema,
		onSubmit: (values) => {
			const requestData = {
				...values,
				assign: values.assign ? values.assign : undefined,
				imageUrls: images.map((image) => image.url),
				description: values.description || undefined,
			}
			if (!requestData.dueDate) {
				delete requestData.dueDate
			}

			if (!requestData.item) {
				delete requestData.item
			}
			createTask(requestData)
		},
	})

	// effect to reset form after request is fulfilled
	React.useEffect(() => {
		if (isCreateTaskFulfilled) {
			// reset formik and antd forms
			formState.resetForm()
			form.resetFields()
			setImages([])
			setModalVisible(false)
		}
	}, [isCreateTaskFulfilled])

	return (
		<div>
			<Button
				onClick={() => setModalVisible(true)}
				style={{ marginTop: -5 }}
				size='large'
				icon={<PlusIcon />}
				type='text'
			/>
			<Modal width={800} title='Add Task' onCancel={() => setModalVisible(false)} footer={null} visible={modalVisible}>
				<Row gutter={{ xs: 8, sm: 12, md: 18, lg: 24 }}>
					<Col span={14}>
						<StyledForm form={form} labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
							{/* title */}
							<Form.Item
								label={<FileTextOutlined style={styles.iconLabel} />}
								name='title'
								validateStatus={formState.touched.title && formState.errors.title ? 'error' : ''}>
								<Input
									size='large'
									placeholder='TITLE'
									name='title'
									value={formState.values.title}
									onChange={formState.handleChange}
									onBlur={formState.handleBlur}
								/>
							</Form.Item>
							<Form.Item
								label={<MessageOutlined style={styles.iconLabel} />}
								name='description'
								validateStatus={formState.touched.description && formState.errors.description ? 'error' : ''}>
								<Input.TextArea
									autoSize={{ minRows: 4, maxRows: 8 }}
									placeholder='DESCRIPTION'
									name='description'
									value={formState.values.description}
									onChange={formState.handleChange}
									onBlur={formState.handleBlur}
								/>
							</Form.Item>

							{/* category */}
							<Form.Item
								label={<ApartmentOutlined style={styles.iconLabel} />}
								name='category'
								hasFeedback
								validateStatus={formState.errors.category ? 'error' : ''}>
								<Input.Group compact style={{ display: 'flex' }}>
									<Input disabled size='large' defaultValue='CATEGORY' />
									<Select
										style={{ flex: '1 0 65%' }}
										size='large'
										loading={isGettingCategories}
										disabled={isGettingCategories}
										value={formState.values.category}
										onChange={(value) => {
											formState.setFieldValue('category', value)
											const index = categories.findIndex((cat) => cat.id === value)
											setSelectedCatIndex(index)
											formState.setFieldValue('subCategory', categories[index].subCategories[0].id)
											formState.setFieldValue('item', '')
											formState.setFieldValue('assign', '')
										}}>
										{categories.length &&
											categories
												.filter((e) => e.subCategories.length)
												.map((cat) => {
													return (
														<Select.Option key={cat.id} value={cat.id}>
															{cat.name}
														</Select.Option>
													)
												})}
									</Select>
								</Input.Group>
							</Form.Item>

							{/* subcategory */}
							<Form.Item
								label={<ApartmentOutlined style={styles.iconLabel} />}
								name='category'
								hasFeedback
								validateStatus={formState.errors.category ? 'error' : ''}>
								<Input.Group compact style={{ display: 'flex' }}>
									<Input disabled size='large' defaultValue='SUBCATEGORY' />
									<Select
										style={{ flex: '1 0 65%' }}
										size='large'
										loading={isGettingCategories}
										disabled={isGettingCategories}
										value={formState.values.subCategory}
										onChange={(value) => {
											formState.setFieldValue('subCategory', value)
											formState.setFieldValue('item', '')
											formState.setFieldValue('assign', '')
										}}>
										{categories.length &&
											categories[selectedCatIndex].subCategories.map((subcat) => {
												return (
													<Select.Option key={subcat.id} value={subcat.id}>
														{subcat.name}
													</Select.Option>
												)
											})}
									</Select>
								</Input.Group>
							</Form.Item>

							{/* item */}
							<VisibleItem visible={itemsEnabled}>
								<Form.Item
									label={<ApartmentOutlined style={styles.iconLabel} />}
									name='item'
									hasFeedback
									validateStatus={formState.errors.item ? 'error' : ''}>
									<Input.Group compact style={{ display: 'flex' }}>
										<Input disabled size='large' defaultValue='ITEM' />
										<Select
											style={{ flex: '1 0 65%' }}
											size='large'
											value={formState.values.item}
											onChange={(value) => {
												formState.setFieldValue('item', value)
											}}>
											<Select.Option key={''} value={''}>
												{'Link an item(Optional)'}
											</Select.Option>
											{items
												.filter((item) => item.subCategoryId === formState.values.subCategory)
												.map((it) => (
													<Select.Option key={it.id} value={it.id}>
														{it.name}
													</Select.Option>
												))}
										</Select>
									</Input.Group>
								</Form.Item>
							</VisibleItem>

							{/* assignee */}
							<Form.Item
								hasFeedback
								label={<UserOutlined style={styles.iconLabel} />}
								validateStatus={formState.errors.assign ? 'error' : ''}>
								<Input.Group compact style={{ display: 'flex' }}>
									<Input disabled size='large' defaultValue='ASSIGN TO' />
									<Select
										style={{ flex: '1 0 65%' }}
										size='large'
										loading={isGettingUsers}
										disabled={isGettingUsers}
										value={formState.values.assign}
										onChange={(value) => formState.setFieldValue('assign', value)}>
										<Select.Option value={''}>{'No one'}</Select.Option>
										{availableAssignees.length &&
											availableAssignees
												.filter((user) =>
													user.subCategories.find((subCat) => subCat.id === formState.values.subCategory)
												)
												.map((user) => (
													<Select.Option key={user.id} value={user.id}>
														{user.firstName} {user.lastName}
													</Select.Option>
												))}
									</Select>
								</Input.Group>
							</Form.Item>

							{/* assignee */}
							<Form.Item hasFeedback label={<DateIcon />} validateStatus={formState.errors.assign ? 'error' : ''}>
								<Input.Group compact style={{ display: 'flex' }}>
									<Input disabled size='large' defaultValue='DUE DATE' />
									<DatePicker
										disabledDate={(current: Moment) => current < moment().startOf('D')}
										style={{ paddingTop: 8, flex: '1 0 65%' }}
										onChange={(value) => formState.setFieldValue('dueDate', moment(value).format('YYYY-MM-DD'))}
										defaultValue={formState.values?.dueDate ? moment(formState.values.dueDate) : undefined}
									/>
								</Input.Group>
							</Form.Item>

							{/* priority */}
							<Form.Item
								hasFeedback
								label={<ExclamationOutlined style={styles.iconLabel} />}
								validateStatus={formState.errors.priority ? 'error' : ''}>
								<Input.Group compact style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
									<Input disabled size='large' defaultValue='PRIORITY' />
									<Radio.Group
										size='large'
										name='priority'
										buttonStyle='solid'
										defaultValue='medium'
										style={{ marginRight: 12, flex: '1 0 60%' }}
										value={formState.values.priority}
										onChange={(e) => formState.setFieldValue('priority', e.target.value)}>
										<Radio.Button style={styles.radioButton} value={1}>
											!
										</Radio.Button>
										<Radio.Button style={styles.radioButton} value={2}>
											!!
										</Radio.Button>
										<Radio.Button style={styles.radioButton} value={3}>
											!!!
										</Radio.Button>
									</Radio.Group>
									<Tooltip title='Set the priority of this task' placement='right'>
										<InfoCircleOutlined />
									</Tooltip>
								</Input.Group>
							</Form.Item>
						</StyledForm>
					</Col>
					<Col span={10}>
						<Upload
							onRemove={(file) => {
								setImages(images.filter((image) => image.uid !== file.uid))
							}}
							customRequest={async ({ file }) => {
								const response = await uploadImage(file)
								setImages([...images, { uid: new Date().getTime().toString(), url: response.data, status: 'done' }])
							}}
							action={`${checkbeeAPIBase}/task/upload/image`}
							onChange={(files) => {
								console.log(files)
							}}
							listType='picture-card'
							fileList={images}>
							{images.length >= 4 ? null : (
								<div>
									<PlusOutlined />
									<div style={{ marginTop: 8 }}>Upload</div>
								</div>
							)}
						</Upload>
					</Col>
					<div className='task-edit-actions-container'>
						<Button
							className='task-action-button'
							type='primary'
							danger
							style={{ marginRight: 20 }}
							disabled={isCreatingTask}
							loading={isCreatingTask}
							onClick={() => {
								formState.resetForm()
								form.resetFields()
								setModalVisible(false)
								setImages([])
							}}>
							CANCEL
						</Button>
						<Button
							className='task-action-button'
							type='primary'
							disabled={isCreatingTask}
							loading={isCreatingTask}
							onClick={() => formState.handleSubmit()}>
							SAVE TASK AND SEND
						</Button>
					</div>
				</Row>
			</Modal>
		</div>
	)
}

const styles = {
	iconLabel: { fontSize: 24, color: colors.primaryColor },
	radioButton: { textAlign: 'center' as const },
}
