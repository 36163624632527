import React from 'react'

const EditIcon = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='13.619' height='13.619' viewBox='0 0 13.619 13.619'>
			<path
				id='Icon_material-mode-edit'
				data-name='Icon material-mode-edit'
				d='M4.5,15.278v2.837H7.337L15.7,9.748,12.867,6.911ZM17.9,7.554a.753.753,0,0,0,0-1.067l-1.77-1.77a.753.753,0,0,0-1.067,0L13.676,6.1l2.837,2.837L17.9,7.554Z'
				transform='translate(-4.5 -4.496)'
			/>
		</svg>
	)
}

export default EditIcon
