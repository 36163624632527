export const colors = {
	primaryColor: '#F7C456',
	borderColor: '#707070',
	archivedColor: '#989898',
	greyShade: '#F8F8F8',
	darkerGreyShade: '#EBEBEB',
	lowPriority: '#1FBC43',
	mediumPriority: '#F0E123',
	highPriority: '#BC3737',
	error: '#FF0000',
}
