import { DeferFn } from 'react-async'

import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { handleError } from '../../../Utils/handle-error'

/**
 * generate login otp
 * [manual trigger - deferFn]
 * https://docs.react-async.com/guide/async-actions
 * @param email
 * @param props
 * @param signal
 */
export const generateLoginOTPDeferFn: DeferFn<any> = async ([email], props, { signal }) => {
	const tenantKey = process.env.REACT_APP_TENANT_KEY
	console.log(tenantKey)
	const response = await fetch(`${checkbeeAPIBase}/auth`, {
		signal,
		method: 'POST',
		headers: {
			'Tenant-Key': `${tenantKey}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ emailAddress: email }),
	})

	const result = await response.json()
	if (result.status === 'success') {
		return result.data
	} else {
		const err = handleError(result, 'Failed to generate OTP!')
		throw new Error(err)
	}
}
