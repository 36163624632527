import { DeferFn } from 'react-async'

import { checkbeeAPIBase } from '../../../../Common/api-client.config'

/**
 * getTopWorkersDeferFn
 * [react-async's deferFn - manually invoked]
 *
 * @example usage
 * const getTopWorkersAsync = useAsync({ deferFn: getTopWorkersDeferFn })
 * const initiategetTopWorkers = (arg: any, anotherArg: any) => {
 * 		getTopWorkersAsync.run(arg, anotherArg)
 * }
 * useSideEffect({
 * 		asyncFn: getTopWorkersAsync,
 * 		message: 'Successful',
 * 		onSuccess: () => {},
 * })
 *
 * @param props
 * @param signal
 */
export const getTopWorkersDeferFn: DeferFn<any> = async ([values], props, { signal }) => {
	const token = localStorage.getItem('chibi-tkn')
	const tenantKey = process.env.REACT_APP_TENANT_KEY

	const response = await fetch(
		`${checkbeeAPIBase}/stats/top_workers${values.period ? `?period=${values.period}` : ''}`,
		{
			signal,
			method: 'GET',
			headers: {
				'Tenant-Key': `${tenantKey}`,
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
		}
	)

	const result = await response.json()
	if (result.status === 'success') {
		return result.data
	} else {
		throw new Error(result?.data?.message || result?.message || result?.data?.name || 'Something went wrong!')
	}
}
