import React from 'react'

export default (
	<svg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 30.857 27.429'>
		<path
			id='Icon_metro-users'
			data-name='Icon metro-users'
			d='M23.143,25.784V24.371A7.657,7.657,0,0,0,26.571,18c0-4.26,0-7.714-5.143-7.714S16.286,13.74,16.286,18a7.657,7.657,0,0,0,3.429,6.371v1.414C13.9,26.26,9.429,29.117,9.429,32.571h24c0-3.455-4.47-6.312-10.286-6.787Zm-11.807.662a16.287,16.287,0,0,1,5.351-2.154A9.67,9.67,0,0,1,15.6,22.711,9.816,9.816,0,0,1,14.357,18c0-2.3,0-4.482.82-6.261a5.581,5.581,0,0,1,4.265-3.2c-.453-2.049-1.659-3.4-4.87-3.4-5.143,0-5.143,3.454-5.143,7.714a7.657,7.657,0,0,0,3.429,6.371v1.414c-5.815.475-10.286,3.333-10.286,6.787h7.473a10.98,10.98,0,0,1,1.291-.982Z'
			transform='translate(-2.571 -5.143)'
			fill='#fff'
		/>
	</svg>
)
