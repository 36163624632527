import { DeferFn } from 'react-async'

import { checkbeeAPIBase } from '../../../Common/api-client.config'

/**
 * getPricingDetailsDeferFn
 * [react-async's deferFn - manually invoked]
 *
 * @example usage
 * const getPricingDetailsAsync = useAsync({ deferFn: getPricingDetailsDeferFn })
 * const initiategetPricingDetails = (arg: any, anotherArg: any) => {
 * 		getPricingDetailsAsync.run(arg, anotherArg)
 * }
 * useSideEffect({
 * 		asyncFn: getPricingDetailsAsync,
 * 		message: 'Successful',
 * 		onSuccess: () => {},
 * })
 *
 * @param props
 * @param signal
 */
export const getPricingDetailsDeferFn: DeferFn<any> = async ([values], props, { signal }) => {
	const token = localStorage.getItem('chibi-tkn')
	const tenantKey = process.env.REACT_APP_TENANT_KEY

	const response = await fetch(`${checkbeeAPIBase}/stripe_subscription/membership_details`, {
		signal,
		method: 'GET',
		headers: {
			'Tenant-Key': `${tenantKey}`,
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`,
		},
		body: JSON.stringify(values),
	})

	const result = await response.json()
	if (result.status === 'success') {
		return result.data
	} else {
		throw new Error(result?.data?.message || result?.message || result?.data?.name || 'Something went wrong!')
	}
}
