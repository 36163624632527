import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'

import { User } from '../auth/authSlice'
import { Item } from '../item/itemSlice'
import { fetchTasksPromiseFn } from './actions/fetchTasks'

export type Task = {
	title: string
	description: string
	priority: number
	createdBy: {
		firstName: string
		lastName: string
	}
	assign: {
		id: string
		firstName: string
		lastName: string
	}
	assignId: string | null
	subCategory: {
		id: string
		name: string
	}
	subCategoryId: string
	category: {
		id: string
		name: string
	}
	categoryId: string
	imageUrls: string[]
	id: string
	createdAt: string
	updatedAt: string
	status: 'INPROGRESS' | 'CREATED' | 'COMPLETED'
	dueDate?: string
	archived: string | null
	item?: Item
	itemId: string | null
}

export interface IFilter {
	[key: string]: string | string[] | number | undefined
}
export interface Filter extends IFilter {
	categoryIds?: string[]
	assignIds?: string[]
	orderBy?: string
	statuses?: string[]
	subCategoryIds?: string[]
	perPage?: number
	page?: number
	orderDirection?: 'ASC' | 'DESC'
}

export const getTasksThunk = createAsyncThunk('task/all', async (_, { getState }) => {
	const {
		task: { filter },
	} = getState() as RootState

	const response = await fetchTasksPromiseFn({ ...filter })
	return response
})

const initialFilter: Filter = {
	statuses: ['CREATED', 'INPROGRESS'],
	perPage: 50,
	page: 1,
	orderBy: 'createdAt',
	orderDirection: 'DESC',
}

export const taskSlice = createSlice({
	name: 'task',
	initialState: {
		mainList: [] as Task[],
		availableAssignees: [] as User[],
		filter: initialFilter,

		// loading tasks by filter
		loading: true,
		list: [] as Task[],
		pagesCount: 1,
	},
	reducers: {
		receiveTasks: (state, action: PayloadAction<{ tasks: Task[] }>) => {
			state.list = action.payload.tasks
			state.mainList = action.payload.tasks
		},
		receiveAvailableAssignees: (state, action: PayloadAction<{ users: User[] }>) => {
			state.availableAssignees = action.payload.users
		},
		applyFilter: (state, action: PayloadAction<any>) => {
			state.filter = { ...state.filter, ...action.payload, page: 1 }
		},
		deleteFilter: (state, action: PayloadAction<string>) => {
			delete state.filter[action.payload]
			state.filter.page = 1
		},
		loadNextPage: (state, action: PayloadAction<void>) => {
			state.filter = { ...state.filter, page: (state.filter?.page ?? 0) + 1 }
		},
		resetFilter: (state, action: PayloadAction<void>) => {
			state.filter = initialFilter
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getTasksThunk.pending, (state) => {
				state.loading = true
			})
			.addCase(getTasksThunk.fulfilled, (state, action) => {
				state.loading = false
				state.pagesCount = action.payload.pagesCount

				if ((state.filter?.page ?? 0) > 1) {
					state.list = state.list.concat(action.payload.data)
				} else {
					state.list = action.payload.data
				}
			})
	},
})

export const { applyFilter, deleteFilter, loadNextPage, resetFilter } = taskSlice.actions
