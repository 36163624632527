/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

interface Props {
	maximumAge?: number
	timeout?: number
	enableHighAccuracy?: boolean
}

const useGeoLocation = ({ enableHighAccuracy, maximumAge, timeout }: Props, callback: (props: any) => void) => {
	const [coordinates, setCoordinates] = useState({
		accuracy: null,
		altitude: null,
		altitudeAccuracy: null,
		heading: null,
		latitude: null,
		longitude: null,
		speed: null,
		timestamp: null,
		error: null,
	})

	useEffect(() => {
		let didCancel: boolean
		const updateCoordinates = ({ coords = {}, timestamp }: { coords: any; timestamp: any }) => {
			const { accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed } = coords
			if (!didCancel) {
				setCoordinates({
					accuracy,
					altitude,
					altitudeAccuracy,
					heading,
					latitude,
					longitude,
					speed,
					timestamp,
					error: null,
				})
				if (callback instanceof Function) {
					callback({
						accuracy,
						altitude,
						altitudeAccuracy,
						heading,
						latitude,
						longitude,
						speed,
						timestamp,
						error: null,
					})
				}
			}
		}

		const setError: any = (error: any) => {
			if (!didCancel) {
				setCoordinates({
					accuracy: null,
					altitude: null,
					altitudeAccuracy: null,
					heading: null,
					latitude: null,
					longitude: null,
					speed: null,
					timestamp: null,
					error,
				})
			}
		}

		let watchId: number
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(updateCoordinates, setError)
			watchId = navigator.geolocation.watchPosition(updateCoordinates, setError, {
				enableHighAccuracy,
				maximumAge,
				timeout,
			})
		}
		return () => {
			if (watchId) {
				navigator.geolocation.clearWatch(watchId)
			}
			didCancel = true
		}
	}, [])

	return coordinates
}

export default useGeoLocation
