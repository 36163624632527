import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'

import { User } from '../auth/authSlice'
import { fetchItemsPromiseFn } from './actions/fetchItems'

export type Item = {
	name: string
	type: string
	manufacturer: string
	prodYear: string
	serialNumber: string
	description: string
	createdBy: {
		firstName: string
		lastName: string
	}
	assign: {
		id: string
		firstName: string
		lastName: string
	}
	subCategory: {
		id: string
		name: string
	}
	subCategoryId: string
	assignId: string
	id: string
	createdAt: string
	updatedAt: string
	archived: string | null
}

export interface IFilter {
	[key: string]: string | string[] | number | undefined
}
export interface Filter extends IFilter {
	subCategoryId?: string[]
	assignIds?: string[]
	orderBy?: string
	statuses?: string[]
	subCategoryIds?: string[]
	perPage?: number
	page?: number
	orderDirection?: 'ASC' | 'DESC'
	count: string
}

export const getItemsThunk = createAsyncThunk('item/all', async (_, { getState }) => {
	const {
		item: { filter },
	} = getState() as RootState

	const response = await fetchItemsPromiseFn({ ...filter })
	return response
})

const initialFilter: Filter = {
	statuses: ['CREATED', 'INPROGRESS'],
	perPage: 50,
	page: 1,
	orderBy: 'createdAt',
	orderDirection: 'DESC',
	count: '1',
}

export const itemSlice = createSlice({
	name: 'item',
	initialState: {
		mainList: [] as Item[],
		availableAssignees: [] as User[],
		filter: initialFilter,

		// loading items by filter
		loading: true,
		list: [] as Item[],
		pagesCount: 1,
	},
	reducers: {
		receiveItems: (state, action: PayloadAction<{ items: Item[] }>) => {
			state.list = action.payload.items
			state.mainList = action.payload.items
		},
		receiveAvailableAssignees: (state, action: PayloadAction<{ users: User[] }>) => {
			state.availableAssignees = action.payload.users
		},
		applyFilter: (state, action: PayloadAction<any>) => {
			state.filter = { ...state.filter, ...action.payload, page: 1 }
		},
		deleteFilter: (state, action: PayloadAction<string>) => {
			delete state.filter[action.payload]
			state.filter.page = 1
		},
		loadNextPage: (state, action: PayloadAction<void>) => {
			state.filter = { ...state.filter, page: (state.filter?.page ?? 0) + 1 }
		},
		resetFilter: (state, action: PayloadAction<void>) => {
			state.filter = initialFilter
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getItemsThunk.pending, (state) => {
				state.loading = true
			})
			.addCase(getItemsThunk.fulfilled, (state, action) => {
				state.loading = false
				state.pagesCount = action.payload.pagesCount

				if ((state.filter?.page ?? 0) > 1) {
					state.list = state.list.concat(action.payload.data)
				} else {
					state.list = action.payload.data
				}
			})
	},
})

export const { applyFilter, deleteFilter, loadNextPage, resetFilter } = itemSlice.actions
