import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Category, SubCategory } from '../category/categorySlice'
import { Company } from '../company/companySlice'
import initialUser from './initial-user.json'

export type User = {
	id: string
	firstName: string
	lastName: string
	role: 'USER' | 'ADMIN'
	emailAddress: string
	activate: boolean
	archived: boolean
	categories: Category[]
	subCategories: SubCategory[]
	company: Company
	licenced: boolean
	isTrial: boolean
}

const isTrial = (company: Company) => {
	return !company.stripeSubscriptionId
}

export const authSlice = createSlice({
	name: 'auth',
	initialState: {
		isAuthenticated: false,
		user: initialUser as User,
	},
	reducers: {
		login: (state) => {
			state.isAuthenticated = true
		},
		receiveUser: (state, action: PayloadAction<{ user: User }>) => {
			state.user = {
				...initialUser,
				...action.payload.user,
				company: { ...action.payload.user.company, isTrial: isTrial(action.payload.user.company) },
			}
		},
		updateCompany: (state, action: PayloadAction<{ company: Company }>) => {
			state.user = { ...state.user, company: action.payload.company }
		},
	},
})
