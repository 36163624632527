import React from 'react'

const ArrowUp = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='25.266' height='25.929' viewBox='0 0 25.266 25.929'>
			<path
				id='Icon_awesome-arrow-up'
				data-name='Icon awesome-arrow-up'
				d='M2.09,17.151.805,15.866a1.383,1.383,0,0,1,0-1.962L12.049,2.654a1.383,1.383,0,0,1,1.962,0L25.256,13.9a1.383,1.383,0,0,1,0,1.962l-1.285,1.285a1.39,1.39,0,0,1-1.985-.023l-6.638-6.968V26.786a1.386,1.386,0,0,1-1.389,1.389H12.107a1.386,1.386,0,0,1-1.389-1.389V10.154L4.075,17.128a1.38,1.38,0,0,1-1.985.023Z'
				transform='translate(-0.397 -2.246)'
				fill='#bc3737'
			/>
		</svg>
	)
}

export default ArrowUp
