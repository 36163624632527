/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Checkbox, Input, Typography } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import styled from 'styled-components'
import { PlusOutlined } from '@ant-design/icons'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { colors } from '../Assets/colors'
import './styles.less'

const CascaderContainer = styled.div<{ boxed: boolean }>`
	width: 100%;
	min-width: 190px;
	border: 1px solid ${colors.borderColor};
	border-radius: ${(props) => (props.boxed ? '' : '5px 5px 0 0')};
	background: #fff;

	div:not(:last-child) {
		border-bottom: 1px solid ${colors.borderColor};
	}
`

interface ItemObject {
	key: string
	value: string
	archived?: null | string
}

export const CheckbeeCascader: React.FC<{
	title?: string
	itemObjects?: ItemObject[]
	tickedItems?: string[]
	showTickAll?: boolean
	showFooter?: boolean
	selectedItem?: string
	allowInlineEditing?: boolean

	titleIcon?: React.ComponentType
	renderItemRight?: React.ComponentType<{ index: number }>

	onChange?: (index: string | number, value: string) => void
	setTickedItems?: (ticked: string[]) => void
	onFooterClick?: () => void
	onClickAll?: () => void
	onItemClick: (value: { index: number; value: string }) => void
	onCheckOn?: (key: string) => void
	onCheckOff?: (key: string) => void
	onCheckAllOn?: () => void
	onCheckAllOff?: () => void
	onSortEnd?: (value: { oldIndex: number; newIndex: number }) => void
}> = ({
	title,
	selectedItem,
	itemObjects = [],
	showTickAll = true,
	showFooter = false,
	tickedItems = [],
	allowInlineEditing,
	titleIcon: TitleIcon,
	renderItemRight: ItemRight,

	onChange,
	setTickedItems,
	onFooterClick,
	onItemClick,
	onCheckOn,
	onCheckOff,
	onCheckAllOn,
	onCheckAllOff,
	onSortEnd,
}) => {
	const [prevItemsObject, setPrevItemsObject] = React.useState<ItemObject[]>(itemObjects)
	const [editableIndex, setEditableIndex] = React.useState<number | undefined>(undefined)
	React.useEffect(() => {
		const difference = itemObjects?.filter((i) => !prevItemsObject.find((pI) => pI.key === i.key))
		if (difference?.length) {
			let intersection = itemObjects?.filter((i) => prevItemsObject.find((pI) => pI.key === i.key))
			if (difference.length + intersection?.length === itemObjects?.length && intersection?.length) {
				setEditableIndex(itemObjects?.length - 1)
			} else {
				setEditableIndex(undefined)
			}
			setPrevItemsObject(itemObjects)
		}
	}, [itemObjects])

	const handleAllCheckChange = (event: CheckboxChangeEvent) => {
		if (event.target.checked) {
			if (onCheckAllOn) {
				onCheckAllOn()
			}
		} else {
			if (onCheckAllOff) {
				onCheckAllOff()
			}
		}
	}

	const handleItemChange = (itemKey: string) => (event: CheckboxChangeEvent) => {
		if (event.target.checked) {
			if (onCheckOn) {
				onCheckOn(itemKey)
			}
		} else {
			if (onCheckOff) {
				onCheckOff(itemKey)
			}
		}
	}

	const SortableItem = SortableElement(({ item, idx }: { item: ItemObject; idx: number; index: number }) => {
		return (
			<div
				key={idx}
				style={{
					...styles.cascaderChild,
					...(selectedItem === item.key ? styles.cascaderSelected : {}),
					...(item.archived && styles.cascaderArchived),
				}}
				className='cascader-row'
				onClick={(e) => {
					// to skip when edit mode is on
					if (allowInlineEditing && editableIndex === idx) {
						return
					}

					onItemClick({ index: idx, value: item.value })
					setTickedItems && setTickedItems(tickedItems)

					// to handle double click
					if (e.detail === 2) {
						setEditableIndex(idx)
					}
				}}
				contentEditable={false}>
				{allowInlineEditing && editableIndex === idx ? (
					<Input
						style={{ width: !!ItemRight ? '80%' : '100%' }}
						placeholder='Area'
						size='large'
						autoFocus={true}
						value={item.value}
						onChange={(event) => (onChange ? onChange(idx, event.target.value) : false)}
						onBlur={() => setEditableIndex(undefined)}
					/>
				) : (
					<Typography.Text strong ellipsis>
						{item.value}
					</Typography.Text>
				)}
				{ItemRight ? (
					<div className='cascader-actions'>
						<ItemRight index={idx} />
					</div>
				) : (
					<Checkbox checked={Boolean(tickedItems.find((k) => k === item.key))} onChange={handleItemChange(item.key)} />
				)}
			</div>
		)
	})

	const SortableList = SortableContainer(({ items }: { items: ItemObject[] }) => {
		return (
			<ul style={{ padding: 0, margin: 0 }}>
				{items.map((value, index) => (
					<SortableItem disabled={!onSortEnd} key={`item-${value.key}`} index={index} idx={index} item={value} />
				))}
			</ul>
		)
	})

	return (
		<CascaderContainer boxed={!Boolean(itemObjects.length)}>
			{title && (
				<div style={styles.cascaderHeader}>
					<Typography.Title level={4} style={{ margin: 0 }}>
						{title}
					</Typography.Title>
					{TitleIcon && <TitleIcon />}
				</div>
			)}

			{/* cascader children	*/}
			{showTickAll && (
				<div style={styles.cascaderChild}>
					<Typography.Text strong style={{ color: '#000' }}>
						ALL
					</Typography.Text>
					<Checkbox
						onChange={handleAllCheckChange}
						indeterminate={tickedItems?.length !== 0 && itemObjects?.length !== tickedItems?.length}
						checked={itemObjects?.length === tickedItems?.length}
					/>
				</div>
			)}

			<SortableList lockAxis={'y'} lockToContainerEdges items={itemObjects} onSortEnd={onSortEnd} distance={10} />
			{/* footer */}
			{showFooter && (
				<div style={styles.cascaderFooter} onClick={onFooterClick}>
					<PlusOutlined style={styles.cascaderFooterIcon} />
				</div>
			)}
		</CascaderContainer>
	)
}

const styles = {
	cascaderHeader: {
		height: 42,
		padding: '0 20px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'nowrap' as const,
	},
	cascaderChild: {
		height: 42,
		padding: '0 8px 0 20px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'nowrap' as const,
		cursor: 'pointer',
	},
	cascaderArchived: {
		backgroundColor: colors.archivedColor,
	},
	cascaderSelected: {
		backgroundColor: colors.primaryColor,
	},
	cascaderFooter: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: 42,
		background: colors.greyShade,
		cursor: 'pointer',
	},
	cascaderFooterIcon: {
		fontWeight: 'bold' as const,
		fontSize: '20px',
		color: colors.primaryColor,
	},
}
