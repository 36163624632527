import React from 'react'
import { Layout, Menu } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { images } from '../Assets/images'
import { initiateLogout } from '../Modules/auth/actions/initiateLogout'
import Tasks from '../Assets/SvgIcons/SideMenu/Tasks'
import TasksTemplates from '../Assets/SvgIcons/SideMenu/TaskTemplates'
import Checklists from '../Assets/SvgIcons/SideMenu/Checklists'
import Categories from '../Assets/SvgIcons/SideMenu/Categories'
import Users from '../Assets/SvgIcons/SideMenu/Users'
import Statistics from '../Assets/SvgIcons/SideMenu/Statistics'
import Admins from '../Assets/SvgIcons/SideMenu/Admins'
import Support from '../Assets/SvgIcons/SideMenu/Support'
import List from '../Assets/SvgIcons/SideMenu/List'
import Items from '../Assets/SvgIcons/SideMenu/Items'
import { RootState } from '../store'

type MenuItem = { key: string; icon: any; title: string }
const basics: MenuItem[] = [
	{ key: 'tasks', icon: Tasks, title: 'Tasks' },
	{ key: 'task-templates', icon: TasksTemplates, title: 'Tasks Templates' },
	{ key: 'add-checklist', icon: Checklists, title: 'Checklists' },
]
const administrates: MenuItem[] = [
	{ key: 'categories', icon: Categories, title: 'Categories' },
	{ key: 'users', icon: Users, title: 'Users' },
]

const BurgerContainer = styled.div<{ collapsed?: boolean }>`
	display: flex;
	margin: ${(props) => (props.collapsed ? '45px 24px' : '32px 24px')};
	justify-content: ${(props) => (props.collapsed ? 'center' : 'flex-start')};
	align-items: center;
	overflow: hidden;
	cursor: pointer;
`

export const CheckbeeSider: React.FC<{ collapsed: boolean; setCollapsed: (collapsed: boolean) => void }> = ({
	collapsed,
	setCollapsed,
}) => {
	const itemsEnabled = useSelector<RootState, boolean>((state) => state.auth.user.company.itemsEnabled)
	const history = useHistory()
	const dispatch = useDispatch()

	return (
		<Layout.Sider style={styles.sider} width={316} trigger={null} collapsible collapsed={collapsed}>
			<BurgerContainer collapsed={collapsed} onClick={() => setCollapsed(!collapsed)}>
				{List}
				{!collapsed && <img alt='' src={images.logoWhite} style={styles.logo} />}
			</BurgerContainer>
			<Menu theme='dark' mode='inline' defaultSelectedKeys={[basics[0].key]}>
				{basics.map(({ key, icon: Icon, title }) => (
					<Menu.Item className='checkbee-menu-item' key={key} icon={Icon} onClick={() => history.push(`/${key}`)}>
						<span style={styles.itemText}>{title}</span>
					</Menu.Item>
				))}
				{itemsEnabled && (
					<Menu.Item className='checkbee-menu-item' key={'items'} icon={Items} onClick={() => history.push('/items')}>
						<span style={styles.itemText}>Items</span>
					</Menu.Item>
				)}
				{administrates.map(({ key, icon: Icon, title }) => (
					<Menu.Item className='checkbee-menu-item' key={key} icon={Icon} onClick={() => history.push(`/${key}`)}>
						<span style={styles.itemText}>{title}</span>
					</Menu.Item>
				))}

				<Menu.Item className='checkbee-menu-item' key='statistics' icon={Statistics} onClick={() => history.push(`/`)}>
					<span style={styles.itemText}>Statistics</span>
				</Menu.Item>

				<Menu.Item className='checkbee-menu-item' key='admin' icon={Admins} onClick={() => history.push(`/admin`)}>
					<span style={styles.itemText}>Admin</span>
				</Menu.Item>
				<Menu.Item className='checkbee-menu-item' key='support' icon={Support} onClick={() => history.push(`/support`)}>
					<span style={styles.itemText}>Support</span>
				</Menu.Item>
			</Menu>

			<Menu theme='dark' mode='inline' style={styles.bottomMenu}>
				<Menu.Item
					key='log-out'
					icon={<LogoutOutlined style={styles.icon} />}
					onClick={() => dispatch(initiateLogout())}>
					<span style={styles.itemText}>Log out</span>
				</Menu.Item>
			</Menu>
		</Layout.Sider>
	)
}

const styles = {
	sider: {
		position: 'relative' as const,
	},
	icon: {
		color: '#fff',
	},
	logo: {
		width: '70%',
		objectFit: 'contain' as const,
		marginLeft: 32,
	},
	itemText: {
		fontWeight: 'bold' as const,
		fontSize: 18,
		color: '#fff',
	},
	bottomMenu: {
		position: 'absolute' as const,
		bottom: 24,
	},
}
