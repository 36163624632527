import { Dispatch } from '@reduxjs/toolkit'
import { DeferFn, PromiseFn } from 'react-async'

import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { handleError } from '../../../Utils/handle-error'

/**
 * this file contains 3 implementations of createItem function:
 * - action
 * - react-async promiseFn
 * - react-async deferFn
 */

/**
 * createItem action
 * [generic] - can be reused in both defer and promise functions
 */
export const createItem = (value: string) => (dispatch: Dispatch) => {
	console.log('[!] HEADS UP! -- executing createItem')
}

/**
 * createItemPromiseFn
 * {boilerplate} - remove this line if the function is functional
 * [react-async's promiseFn - automatically invoked]
 * - this will automatically be invoked if called
 * - no need to try/catch
 * - https://docs.react-async.com/guide/async-components
 *
 * @example [usage]
 * const createItemAsync = useAsync({
 * 		promiseFn: createItemPromiseFn,
 * 		id: SOME_ID_HERE, // arguments
 * })
 * useSideEffect({
 * 		asyncFn: createItemAsync,
 * 		message: 'Successful',
 * 		onSuccess: () => {},
 * })
 *
 * @param data
 * @param signal
 */
export const createItemPromiseFn: PromiseFn<any> = async (data, { signal }) => {
	/** if you ever need a tenantKey from process.env */
	const tenantKey = process.env.REACT_APP_TENANT_KEY

	/** [boilerplate code] example usage of `signal` with fetch */
	const response = await fetch(`${checkbeeAPIBase}/something`, {
		signal,
		method: 'GET', // or POST
		headers: {
			'Tenant-Key': `${tenantKey}`,
		},
	})

	const result = await response.json()
	if (result.status === 'success') {
		return result.data
	} else {
		throw new Error(result?.data?.message || result?.message || result?.data?.name || 'Failed to get something!')
	}
}

/**
 * createItemDeferFn
 * [react-async's deferFn - manually invoked]
 *
 * @example usage
 * const createItemAsync = useAsync({ deferFn: createItemDeferFn })
 * const initiateCreateItem = (arg: any, anotherArg: any) => {
 * 		createItemAsync.run(arg, anotherArg)
 * }
 * useSideEffect({
 * 		asyncFn: createItemAsync,
 * 		message: 'Successful',
 * 		onSuccess: () => {},
 * })
 *
 * @param values - {title, description, priority, category, subCategory, assign}
 * @param props
 * @param signal
 */
export const createItemDeferFn: DeferFn<any> = async ([values], props, { signal }) => {
	const tenantKey = process.env.REACT_APP_TENANT_KEY
	const token = localStorage.getItem('chibi-tkn')
	const response = await fetch(`${checkbeeAPIBase}/item`, {
		signal,
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Tenant-Key': `${tenantKey}`,
			'Authorization': `Bearer ${token}`,
		},
		body: JSON.stringify(values),
	})

	const result = await response.json()
	if (result.status === 'success') {
		return result.data
	} else {
		const err = handleError(result, 'Failed to create item!')
		throw new Error(err)
	}
}
