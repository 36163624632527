import { DeferFn, PromiseFn } from 'react-async'

import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { handleError } from '../../../Utils/handle-error'

/**
 * this file contains 3 implementations of fetchCheckList function:
 * - action
 * - react-async promiseFn
 * - react-async deferFn
 */

/**
 * fetchCheckList action
 * [generic] - can be reused in both defer and promise functions
 */
export const fetchCheckList = async (signal: AbortSignal) => {
	const token = localStorage.getItem('chibi-tkn')
	const tenantKey = process.env.REACT_APP_TENANT_KEY
	const response = await fetch(`${checkbeeAPIBase}/checklist`, {
		signal,
		method: 'GET',
		headers: {
			'Tenant-Key': `${tenantKey}`,
			'Authorization': `Bearer ${token}`,
		},
	})

	const result = await response.json()
	console.log('[RESULT] fetchCheckList -', result)

	if (response.ok && result.status === 'success') {
		if (result.data.length) {
			return result.data
		} else {
			return []
		}
	} else {
		const err = handleError(result, 'Failed to get checklist!')
		throw new Error(err)
	}
}

/**
 * fetchCategoriesPromiseFn
 * [react-async's promiseFn - automatic invocation]
 *
 * @param signal
 */
export const fetchCheckListPromiseFn: PromiseFn<any> = async (_, { signal }) => {
	return await fetchCheckList(signal)
}

/**
 * fetchCheckListDeferFn
 * [react-async's deferFn - manually invoked]
 *
 * @param rest
 * @param props
 * @param signal
 */
export const fetchCheckListDeferFn: DeferFn<any> = async (_, props, { signal }) => {
	return await fetchCheckList(signal)
}
