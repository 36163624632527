/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useAsync } from 'react-async'

import { SubscriptionDetails } from '../components/SubscriptionDetails'
import { RootState } from '../../../store'
import { useSideEffect } from '../../../Hooks/useSideEffect'
import { companySlice } from '../../company/companySlice'
import { authSlice } from '../../auth/authSlice'
import { getSubscriptionDetailsDeferFn } from '../actions/getSubscriptionDetails'
import { createSubscriptionDeferFn } from '../actions/createSubscription'
import { useStripe } from '@stripe/react-stripe-js'
import { cancelSubscriptionDeferFn } from '../actions/cancelSubscription'
import { notification } from 'antd'
import { updateSubscriptionDeferFn } from '../actions/updateSubscription'
import { Loader } from '../../../Components/Loader'
import { getPricingDetailsDeferFn } from '../actions/getPricingDetails'

const mapState = (state: RootState) => ({
	isAuthenticated: state.auth.isAuthenticated,
	company: state.auth.user.company,
})

/**
 * defining mapDispatchToProps as an object
 * https://react-redux.js.org/using-react-redux/connect-mapdispatch#defining-mapdispatchtoprops-as-an-object
 */
const mapDispatch = {
	receiveCompanies: companySlice.actions.receiveCompanies,
	receiveCompany: authSlice.actions.updateCompany,
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>

/** higher-order wrapper component for side effects */
const Wrapper: React.FC<PropsFromRedux> = (props) => {
	const [companySubscription, setCompanySubscription] = useState<any>(null)
	const stripe: any = useStripe()

	const completeCustomerAuth = (subscription: any) => {
		if (subscription && subscription.status === 'active') {
			// subscription is active, no customer actions required.
			return true
		}
		let paymentIntent = subscription.latest_invoice.payment_intent

		return stripe
			.confirmCardPayment(paymentIntent.client_secret, {
				payment_method: subscription.latest_invoice.payment_intent.payment_method,
			})
			.then((result: any) => {
				if (result.error) {
					notification.error({ message: 'You card is declined' })
					cancelSubscriptionAsync.run()
					throw result
				} else {
					if (result.paymentIntent.status === 'succeeded') {
						notification.success({ message: 'Your subscription has been approved' })
						return true
					}
					throw result
				}
			})
			.catch((error: any) => {
				return false
			})
	}
	const getSubscriptionDetailsAsync = useAsync({ deferFn: getSubscriptionDetailsDeferFn })

	useSideEffect({
		asyncFn: getSubscriptionDetailsAsync,
		onSuccess: async () => {
			const { data } = getSubscriptionDetailsAsync
			setCompanySubscription(data)
		},
	})

	const cancelSubscriptionAsync = useAsync({ deferFn: cancelSubscriptionDeferFn })

	useSideEffect({
		asyncFn: cancelSubscriptionAsync,
		message: 'Subscription canceled successfully!',
		onSuccess: () => {
			setCompanySubscription(null)
		},
	})

	const updateSubscriptionAsync = useAsync({ deferFn: updateSubscriptionDeferFn })

	useSideEffect({
		asyncFn: updateSubscriptionAsync,
		message: 'Subscription updated successfully!',
		onSuccess: () => {
			getSubscriptionDetailsAsync.reload()
		},
	})

	const createSubscriptionAsync = useAsync({ deferFn: createSubscriptionDeferFn })

	useSideEffect({
		asyncFn: createSubscriptionAsync,
		onSuccess: async () => {
			const { data } = createSubscriptionAsync
			const isApproved = await completeCustomerAuth(data)
			if (isApproved) {
				getSubscriptionDetailsAsync.reload()
			}
		},
	})

	const getPricingDetails = useAsync({ deferFn: getPricingDetailsDeferFn })

	useEffect(() => {
		getPricingDetails.run()
		getSubscriptionDetailsAsync.run()
	}, [])

	const isLoading =
		getSubscriptionDetailsAsync.isLoading ||
		createSubscriptionAsync.isLoading ||
		cancelSubscriptionAsync.isLoading ||
		updateSubscriptionAsync.isLoading ||
		getPricingDetails.isLoading

	if (isLoading) {
		return (
			<div className='loader-container'>
				<Loader />
			</div>
		)
	}
	return (
		<SubscriptionDetails
			{...props}
			companySubscription={companySubscription}
			isUpdateing={false}
			createSubscription={createSubscriptionAsync.run}
			cancelSubscription={cancelSubscriptionAsync.run}
			updateSubscription={updateSubscriptionAsync.run}
			pricingDetails={getPricingDetails.data}
		/>
	)
}

export const SubscriptionDetailsContainer = connector(Wrapper)
