import { DeferFn } from 'react-async'

import { checkbeeAPIBase } from '../../../Common/api-client.config'

export const getInvoicesHistoryDeferFn: DeferFn<any> = async (_, props, { signal }) => {
	const token = localStorage.getItem('chibi-tkn')
	const tenantKey = process.env.REACT_APP_TENANT_KEY

	const response = await fetch(`${checkbeeAPIBase}/stripe_subscription/invoice_history`, {
		signal,
		method: 'GET',
		headers: {
			'Tenant-Key': `${tenantKey}`,
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`,
		},
	})

	const result = await response.json()
	if (result.status === 'success') {
		return result.data
	} else {
		throw new Error(result?.data?.message || result?.message || result?.data?.name || 'Something went wrong!')
	}
}
