import React from 'react'

export default (
	<svg xmlns='http://www.w3.org/2000/svg' width='24' height='23.5' viewBox='0 0 29 27'>
		<path
			id='Icon_open-graph'
			data-name='Icon open-graph'
			d='M25.484,0,14.5,10.125,10.875,6.75,0,16.976l3.625,3.375,7.25-6.851L14.5,16.875,29,3.375ZM0,23.625V27H29V23.625Z'
			fill='#fff'
		/>
	</svg>
)
