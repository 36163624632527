import { DeferFn, PromiseFn } from 'react-async'

import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { handleError } from '../../../Utils/handle-error'
import { SubCategory } from '../categorySlice'

export const updateSubCategory = async (subcategory: SubCategory, signal: AbortSignal) => {
	const tenantKey = process.env.REACT_APP_TENANT_KEY
	const token = localStorage.getItem('chibi-tkn')

	const response = await fetch(`${checkbeeAPIBase}/subcategory/${subcategory.id}`, {
		signal,
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json',
			'Tenant-Key': `${tenantKey}`,
			'Authorization': `Bearer ${token}`,
		},
		body: JSON.stringify(subcategory),
	})

	const result = await response.json()
	console.log(result)
	if (result.status === 'success') {
		return result.data
	} else {
		const err = handleError(result, 'Failed to update subcategory!')
		throw new Error(err)
	}
}

export const updateCategoryPromiseFn: PromiseFn<any> = async ({ data: { companyId, ...rest } }, { signal }) => {
	return await updateSubCategory({ company: companyId, ...rest }, signal)
}

export const updateSubCategoryDeferFn: DeferFn<any> = async ([{ companyId, ...rest }], props, { signal }) => {
	return await updateSubCategory({ company: companyId, ...rest }, signal)
}
