import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useAsync } from 'react-async'

import { CompanyDetails } from '../components/CompanyDetails'
import { RootState } from '../../../store'
import { useSideEffect } from '../../../Hooks/useSideEffect'
import { companySlice } from '../../company/companySlice'
import { updateCompanyDeferFn } from '../actions/updateCompany'
import { authSlice } from '../../auth/authSlice'

const mapState = (state: RootState) => ({
	isAuthenticated: state.auth.isAuthenticated,
	company: state.auth.user.company,
})

/**
 * defining mapDispatchToProps as an object
 * https://react-redux.js.org/using-react-redux/connect-mapdispatch#defining-mapdispatchtoprops-as-an-object
 */
const mapDispatch = {
	receiveCompanies: companySlice.actions.receiveCompanies,
	receiveCompany: authSlice.actions.updateCompany,
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>

/** higher-order wrapper component for side effects */
const Wrapper: React.FC<PropsFromRedux> = (props) => {
	const updateCompanyAsync = useAsync({ deferFn: updateCompanyDeferFn })

	const initiateUpdateCompany = (values: any) => {
		updateCompanyAsync.run(values)
	}

	useSideEffect({
		asyncFn: updateCompanyAsync,
		message: 'Company updated successfully!',
		onSuccess: () => {
			const { data } = updateCompanyAsync
			props.receiveCompany({ company: data })
		},
	})

	return <CompanyDetails {...props} isUpdateing={false} updateCompany={initiateUpdateCompany} />
}

export const CompanyDetailsContainer = connector(Wrapper)
