import { DeferFn, PromiseFn } from 'react-async'

import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { handleError } from '../../../Utils/handle-error'

/**
 * this file contains 3 implementations of fetchUser function:
 * - plain action
 * - react-async promiseFn
 * - react-async deferFn
 */

/**
 * fetchUsers
 * [plain action] - reusable action
 * @param query
 * @param signal
 */
export const fetchUsers = async (query: any, signal: AbortSignal) => {
	const queryStrings = Object.keys(query).map((key) => `${key}=${query[key]}`)
	const qs = queryStrings.join('&')
	const token = localStorage.getItem('chibi-tkn')

	const tenantKey = process.env.REACT_APP_TENANT_KEY
	const response = await fetch(`${checkbeeAPIBase}/user?${qs}`, {
		signal,
		method: 'GET',
		headers: {
			'Tenant-Key': `${tenantKey}`,
			'Authorization': `Bearer ${token}`,
		},
	})

	const result = await response.json()
	if (result.status === 'success') {
		if (result.data.length) {
			return result.data
		} else {
			throw new Error('No users found!')
		}
	} else {
		const err = handleError(result, 'Failed to get user!')
		throw new Error(err)
	}
}

/**
 * fetchUsersPromiseFn
 * [react-async's promiseFn - automatically invoked]
 *
 * @example [usage]
 * const fetchUsersAsync = useAsync({
 * 		promiseFn: fetchUsersPromiseFn,
 * 		id: SOME_ID_HERE, // arguments
 * })
 * useSideEffect({
 * 		asyncFn: fetchUsersAsync,
 * 		message: 'Successful',
 * 		onSuccess: () => {},
 * })
 *
 * @param query
 * @param signal
 */
export const fetchUsersPromiseFn: PromiseFn<any> = async ({ query }, { signal }) => {
	return await fetchUsers(query, signal)
}

/**
 * fetchUsersDeferFn
 * [react-async's deferFn - manually invoked]
 * @param query {object}
 * @param props
 * @param signal
 */
export const fetchUsersDeferFn: DeferFn<any> = async ([query], props, { signal }) => {
	return await fetchUsers(query, signal)
}
