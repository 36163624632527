import React from 'react'
import { Layout, Typography } from 'antd'

import { CheckbeeSider } from './CheckbeeSider'
import { images } from '../Assets/images'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import moment from 'moment'

/**
 * a wrapper for dashboard-specific screens
 * - includes sider, header, and header
 *
 * @example - simple usage
 * 	<LayoutWrapper>
 *  	<div>Hello world!</div>
 * 	</LayoutWrapper>
 *
 * @example - usage with dashboard-specific screen (hierarchy)
 * 	<LayoutWrapper>
 * 		<Typography.Title level={4} />
 * 		<CheckbeeDashboardContent>
 * 			<CheckbeeMenu />
 * 			<CheckbeeMenuContent>
 * 				<CheckbeeMenuContentHeader>{children}</CheckbeeMenuContentHeader>
 * 				...
 * 			</CheckbeeMenuContent>
 * 		</CheckbeeDashboardContent>
 * 	</LayoutWrapper>
 */
export const LayoutWrapper: React.FC<{}> = ({ children }) => {
	const [collapsed, setCollapsed] = React.useState(true)
	const firstName = useSelector<RootState>(({ auth }) => auth.user.firstName)
	const company: any = useSelector<RootState>(({ auth }) => auth.user.company)

	const renderSubscription = () => {
		if (company.stripeSubscriptionId) {
			return (
				<>
					Your subscription ends at <strong>{moment(company.subscriptionEndDate).format('dddd DD.MM.YYYY')}</strong>
				</>
			)
		}
		return (
			<>
				Your free trial ends at <strong>{moment(company.subscriptionEndDate).format('dddd DD.MM.YYYY')}</strong>
			</>
		)
	}
	return (
		<Layout style={{ minHeight: '100vh' }}>
			<CheckbeeSider collapsed={collapsed} setCollapsed={setCollapsed} />
			<Layout>
				<Layout.Header style={styles.header}>
					{collapsed && <img alt='' src={images.logo} style={styles.headerImage} />}
					<div style={styles.headerRight}>
						<Typography.Title style={{ margin: 0 }} level={3}>
							Welcome, {firstName}!
						</Typography.Title>
						<Typography.Text type={'secondary'}>{moment(new Date()).format('dddd DD.MM.YYYY')}</Typography.Text>
						<br />
						<Typography.Text type={'secondary'}>{renderSubscription()}</Typography.Text>
					</div>
				</Layout.Header>

				{/* header starts here */}
				<Layout.Content style={styles.content}>{children}</Layout.Content>
			</Layout>
		</Layout>
	)
}

const styles = {
	header: {
		background: '#fff',
		display: 'flex',
		position: 'relative' as const,
		alignItems: 'center',
		padding: '0 40px',
		height: 102,
		width: '100%',
	},
	headerImage: { width: '200px', height: '41px', objectFit: 'contain' as const },
	headerRight: { position: 'absolute' as const, right: 98, lineHeight: 'normal' },
	content: {
		margin: '38px 98px 38px 78px',
	},
}
