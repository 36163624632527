import React from 'react'
import { Empty, Skeleton } from 'antd'

import { SubCategoryFormContainer, SubCategoryFormState } from '../containers/SubCategoryFormContainer'
import { Category } from '../categorySlice'

type ViewCategoryProps = {
	category: Category
	subCategory: SubCategoryFormState
	loading: boolean
}

export const ViewCategory: React.FC<ViewCategoryProps> = ({ category, subCategory, loading }) => {
	return (
		<>
			{/* still loading */}
			{!Boolean(category.id) && loading && <Skeleton active avatar paragraph={{ rows: 4 }} />}
			{/* empty data, not loading anymore */}
			{!Boolean(category.id) && !loading && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
			{/* data loaded */}
			{Boolean(category.id) && (
				<SubCategoryFormContainer
					subCategory={subCategory}
					newCategory={category.id === ''}
					category={category}
					initialFormState={subCategory}
				/>
			)}
		</>
	)
}
