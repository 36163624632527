/**
 * error formatter specific to checkbee's backend
 * @param result - result of API request
 * @param fallbackMessage
 */
export const handleError = (result: any, fallbackMessage: string) => {
	if (result?.data?.errors?.length) {
		const errs = result.data.errors.map((e: { constraints: any }) => {
			const keyOfFirstConstraint = Object.keys(e.constraints)[0]
			return e.constraints[keyOfFirstConstraint]
		})
		return errs.join(', ')
	} else {
		return result.data?.message || result?.message || result.data?.name || fallbackMessage
	}
}
