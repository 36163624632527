import axios from 'axios'
import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { Category, SubCategory } from '../categorySlice'

interface ISubCategoryAPICreateDTO extends SubCategory {
	category: string
}

enum AxiosStatus {
	SUCCESS = 'success',
}

interface AxiosResponse<T> {
	status: AxiosStatus | string
	data: T
}

const SUBCATEGORY_BASE = 'subcategory'
const DEFAULT_IMAGE_URL =
	'https://checkbee-storage.s3.eu-north-1.amazonaws.com/25514AEA-AB82-46BC-86BC-61FFAD8AEEB7.jpg'
export const subCategoryAPI = {
	get: async (params: { category: Category }) => {
		const token = localStorage.getItem('chibi-tkn')
		const tenantKey = process.env.REACT_APP_TENANT_KEY
		return await axios
			.get<AxiosResponse<SubCategory[]>>(`${checkbeeAPIBase}/${SUBCATEGORY_BASE}`, {
				headers: {
					'Tenant-Key': `${tenantKey}`,
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				params: params,
			})
			.then((result) => result.data.data)
	},
	create: async (subcategory: ISubCategoryAPICreateDTO) => {
		const token = localStorage.getItem('chibi-tkn')
		const tenantKey = process.env.REACT_APP_TENANT_KEY

		const requestBody: ISubCategoryAPICreateDTO = {
			...subcategory,
			imageUrl: subcategory.imageUrl || DEFAULT_IMAGE_URL,
		}

		return await axios
			.post<AxiosResponse<SubCategory>>(`${checkbeeAPIBase}/${SUBCATEGORY_BASE}`, requestBody, {
				headers: {
					'Tenant-Key': `${tenantKey}`,
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			})
			.then((result) => result.data)
	},
	getOne: async (subcategoryId: string) => {
		const token = localStorage.getItem('chibi-tkn')
		const tenantKey = process.env.REACT_APP_TENANT_KEY
		return await axios
			.get<AxiosResponse<SubCategory>>(`${checkbeeAPIBase}/${SUBCATEGORY_BASE}/${subcategoryId}`, {
				headers: {
					'Tenant-Key': `${tenantKey}`,
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			})
			.then((result) => result.data)
	},
	update: async (subcategory: SubCategory) => {
		const token = localStorage.getItem('chibi-tkn')
		const tenantKey = process.env.REACT_APP_TENANT_KEY
		return await axios
			.patch<AxiosResponse<SubCategory>>(`${checkbeeAPIBase}/${SUBCATEGORY_BASE}/${subcategory.id}`, subcategory, {
				headers: {
					'Tenant-Key': `${tenantKey}`,
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			})
			.then((result) => result.data)
	},
	delete: async (subcategory: SubCategory) => {
		const token = localStorage.getItem('chibi-tkn')
		const tenantKey = process.env.REACT_APP_TENANT_KEY
		return await axios
			.delete<AxiosResponse<SubCategory>>(`${checkbeeAPIBase}/${SUBCATEGORY_BASE}/${subcategory.id}`, {
				headers: {
					'Tenant-Key': `${tenantKey}`,
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			})
			.then((result) => result.data)
	},
	CUDWithCompare: async (from: SubCategory[], to: SubCategory[], categoryId: string) => {
		const subcategoriesForCreating = to.filter((tS) => tS.id === undefined)
		const subcategoriesForUpdating = to.filter((tS) => from.find((fS) => fS.id === tS.id))
		const subcategoriesForDeleting = from.filter((fS) => !to.find((tS) => tS.id === fS.id))

		await Promise.all(subcategoriesForCreating.map((sC) => subCategoryAPI.create({ ...sC, category: categoryId })))
		await Promise.all(subcategoriesForUpdating.map((sC) => subCategoryAPI.update(sC)))
		await Promise.all(subcategoriesForDeleting.map((sC) => subCategoryAPI.delete(sC)))
	},
}
