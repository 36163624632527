import React, { useState } from 'react'
import { Button, Typography } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import './styles.less'

interface Props {
	value: string
	onChange: (value: any) => void
}
export const QuickEditInput: React.FC<Props> = ({ value, onChange }) => {
	const [open, setOpen] = useState(false)
	const [newValue, setNewValue] = useState(value)

	return (
		<div className='quick-edit-input' onDoubleClick={() => setOpen(true)}>
			{open ? (
				<>
					<input
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								// to save on click enter
								onChange(newValue)
								setOpen(false)
							}

							if (e.key === 'Escape') {
								// to close on click escape
								setOpen(false)
							}
						}}
						autoFocus
						value={newValue}
						onChange={({ target }) => setNewValue(target.value)}
						className='input'
					/>
					<Button
						onClick={() => {
							onChange(newValue)
							setOpen(false)
						}}
						type='text'
						className='close-button'>
						<CheckOutlined />
					</Button>
					<Button onClick={() => setOpen(false)} type='text' className='save-button'>
						<CloseOutlined />
					</Button>
				</>
			) : (
				<Typography.Text strong>{value}</Typography.Text>
			)}
		</div>
	)
}
