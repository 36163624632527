import React from 'react'

export default (
	<svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 32.121 30'>
		<g id='Icon_feather-check-square' data-name='Icon feather-check-square' transform='translate(-3 -3)'>
			<path
				id='Path_37'
				data-name='Path 37'
				d='M13.5,16.5,18,21,33,6'
				fill='none'
				stroke='#fff'
				stroke-linecap='round'
				stroke-linejoin='round'
				stroke-width='3'
			/>
			<path
				id='Path_38'
				data-name='Path 38'
				d='M31.5,18V28.5a3,3,0,0,1-3,3H7.5a3,3,0,0,1-3-3V7.5a3,3,0,0,1,3-3H24'
				fill='none'
				stroke='#fff'
				stroke-linecap='round'
				stroke-linejoin='round'
				stroke-width='3'
			/>
		</g>
	</svg>
)
