import { DeferFn } from 'react-async'

import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { handleError } from '../../../Utils/handle-error'

/**
 * createItemDeferFn
 * [react-async's deferFn - manually invoked]
 *
 * @example usage
 * const createItemAsync = useAsync({ deferFn: deleteItemDeferFn })
 * const initiateCreateItem = (arg: any, anotherArg: any) => {
 * 		createItemAsync.run(arg, anotherArg)
 * }
 * useSideEffect({
 * 		asyncFn: createItemAsync,
 * 		message: 'Successful',
 * 		onSuccess: () => {},
 * })
 *
 * @param values - {title, description, priority, category, subCategory, assign}
 * @param props
 * @param signal
 */
export const deleteItemDeferFn: DeferFn<any> = async ([id], props, { signal }) => {
	const tenantKey = process.env.REACT_APP_TENANT_KEY
	const token = localStorage.getItem('chibi-tkn')
	const response = await fetch(`${checkbeeAPIBase}/item/${id}`, {
		signal,
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			'Tenant-Key': `${tenantKey}`,
			'Authorization': `Bearer ${token}`,
		},
	})

	const result = await response.json()
	if (result.status === 'success') {
		return result.data
	} else {
		const err = handleError(result, 'Failed to delete item!')
		throw new Error(err)
	}
}
