import React, { useState } from 'react'
import styled from 'styled-components'
import { Period } from '../../Types/StatsTypes'

const Choice = styled.div<{ active: boolean }>`
	padding: 5px 15px;
	background-color: white;
	border-radius: 6px;
	font-size: 12px;
	color: black;
	border-style: solid;
	border-width: 1px;
	border-color: ${({ active }) => (active ? '#f0e123' : 'white')};
	margin: 5px;
	filter: drop-shadow(0 0 0.75rem lightgrey);
	cursor: pointer;
`

const choices: {
	title: string
	value: Period
}[] = [
	{
		title: 'This week',
		value: 'week',
	},
	{
		title: 'This month',
		value: 'month',
	},
	{
		title: 'All time',
		value: undefined,
	},
]
const QuickDatePeriodSelector: React.FC<{ onChange: (value: Period) => void }> = ({ onChange }) => {
	const [choice, setChoice] = useState<Period>('week')
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			{choices.map((e) => (
				<Choice
					onClick={() => {
						onChange(e.value)
						setChoice(e.value)
					}}
					active={choice === e.value}>
					{e.title}
				</Choice>
			))}
		</div>
	)
}

export default QuickDatePeriodSelector
