import { Typography } from 'antd'
import React, { useState } from 'react'
import { Row, Col } from 'antd'
import './styles.less'
import { Cell } from './TasksTableStructure'
import { Task } from '../taskSlice'
import { EditTaskContainer } from '../containers/EditTaskContainer'
import { useAsync } from 'react-async'
import { notifyTaskAssigneeDeferFn } from '../actions/notifyTaskAssignee'
import { useSideEffect } from '../../../Hooks/useSideEffect'

interface CellProps {
	cell: Cell
	task: Task
	informAssignee: Function
	informLoading: boolean
	editTask: Function
	editing: boolean
}

const RowCell = ({ cell, task, informAssignee, informLoading, editTask, editing }: CellProps) => {
	if (cell.id === 'action1') {
		return (
			<div>
				<Typography className='tasks-header-text'>
					{cell?.getValue ? cell.getValue(informAssignee, informLoading, editTask, editing, task) : null}
				</Typography>
			</div>
		)
	}
	return (
		<div>
			<Typography className='tasks-header-text'>
				{cell.fieldKey ? task[cell.fieldKey] : cell?.getValue ? cell.getValue(task) : ''}
			</Typography>
		</div>
	)
}

interface TaskTableRow {
	cells: Cell[]
	even: boolean
	task: Task
}

export const TasksTableRow = ({ cells, even, task }: TaskTableRow) => {
	const [editing, setEditing] = useState(false)

	// notify task assignees
	const notifyTaskAssigneeAsync = useAsync({ deferFn: notifyTaskAssigneeDeferFn })
	const initiateNotifyTaskAssignee = (id: string) => {
		notifyTaskAssigneeAsync.run(id)
	}
	useSideEffect({
		asyncFn: notifyTaskAssigneeAsync,
		message: 'Notifications is on the way!',
	})

	return (
		<Row className='task-table-row' style={{ backgroundColor: !even ? '#F1F4F5' : 'white' }}>
			{cells.map((e) => (
				<Col key={`${task.id}-${e.id}`} span={e.span}>
					<RowCell
						editing={editing}
						editTask={() => setEditing(!editing)}
						informAssignee={() => initiateNotifyTaskAssignee(task.id)}
						task={task}
						cell={e}
						informLoading={notifyTaskAssigneeAsync.isLoading}
					/>
				</Col>
			))}
			{editing ? (
				<Col style={{ marginTop: 20 }} span={24}>
					<EditTaskContainer task={task} />
				</Col>
			) : null}
		</Row>
	)
}
