import { checkbeeAPIBase } from '../../../Common/api-client.config'

export class ChecklistApi {
	public static async deleteCategory(id: string) {
		const token = localStorage.getItem('chibi-tkn')
		try {
			await fetch(`${checkbeeAPIBase}/checklist_category/${id}`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`,
				},
			})
		} catch (e) {}
	}

	public static async deleteCheckpoint(id: string) {
		const token = localStorage.getItem('chibi-tkn')
		try {
			await fetch(`${checkbeeAPIBase}/checkpoint/${id}`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`,
				},
			})
		} catch (e) {}
	}

	public static async sortAreas(checklistId: string, ids: string[]) {
		const token = localStorage.getItem('chibi-tkn')
		try {
			await fetch(`${checkbeeAPIBase}/checklist_category/sort`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`,
				},
				body: JSON.stringify({
					checklistId,
					ids,
				}),
			})
		} catch (e) {}
	}

	public static async sortCheckpoints(categoryId: string, ids: string[]) {
		const token = localStorage.getItem('chibi-tkn')
		try {
			await fetch(`${checkbeeAPIBase}/checkpoint/sort`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`,
				},
				body: JSON.stringify({
					categoryId,
					ids,
				}),
			})
		} catch (e) {}
	}
}
