import { DeferFn, PromiseFn } from 'react-async'

import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { handleError } from '../../../Utils/handle-error'
/**
 * this file contains 3 implementations of createCategory function:
 * - action
 * - react-async promiseFn
 * - react-async deferFn
 */

/**
 * deleteSubCategory action
 * [generic] - can be reused in both defer and promise functions
 */
export const deleteSubCategory = async (subcategoryId: string, signal: AbortSignal) => {
	const tenantKey = process.env.REACT_APP_TENANT_KEY
	const token = localStorage.getItem('chibi-tkn')

	const response = await fetch(`${checkbeeAPIBase}/subcategory/${subcategoryId}`, {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			'Tenant-Key': `${tenantKey}`,
			'Authorization': `Bearer ${token}`,
		},
		signal,
	})
	const result = await response.json()
	if (result.status === 'success') {
		return result.data
	} else {
		const err = handleError(result, 'Failed to delete subcategory!')
		throw new Error(err)
	}
}

/**
 * deleteSubCategoryPromiseFn
 * [react-async's promiseFn - automatically invoked]
 *
 * @example [usage]
 * const deleteSubCategoryAsync = useAsync({
 * 		promiseFn: createCategoryPromiseFn,
 * 		id: SOME_ID_HERE, // arguments
 * })
 * useSideEffect({
 * 		asyncFn: deleteSubCategoryAsync,
 * 		message: 'Successful',
 * 		onSuccess: () => {},
 * })
 *
 * @param companyId
 * @param rest
 * @param signal
 */
export const deleteSubCategoryPromiseFn: PromiseFn<any> = async ({ data: { categoryId, ...rest } }, { signal }) => {
	return await deleteSubCategory(categoryId, signal)
}
/**
 * deleteSubCategoryDeferFn
 * [react-async's deferFn - manually invoked]
 *
 * @param companyId
 * @param rest
 * @param props
 * @param signal
 */
export const deleteSubCategoryDeferFn: DeferFn<any> = async ([{ subcategoryId, ...rest }], props, { signal }) => {
	return await deleteSubCategory(subcategoryId, signal)
}
