import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { useAsync } from 'react-async'

import { Signup } from '../components/Signup'
import { RootState } from '../../../store'
import { loginUser } from '../actions/loginUser'
import { useSideEffect } from '../../../Hooks/useSideEffect'
import { companySlice } from '../../company/companySlice'
import { createAuthUserDeferFn } from '../actions/createAuthUser'
import useGeoLocation from '../../../Hooks/useGeoLocation'

const mapState = (state: RootState) => ({
	isAuthenticated: state.auth.isAuthenticated,
})

/**
 * defining mapDispatchToProps as an object
 * https://react-redux.js.org/using-react-redux/connect-mapdispatch#defining-mapdispatchtoprops-as-an-object
 */
const mapDispatch = {
	initiateLogin: loginUser,
	receiveCompanies: companySlice.actions.receiveCompanies,
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>

/** extra type definitions */
export type SignupContainerProps = PropsFromRedux & {
	isLoading: boolean
	isCreatingUser: boolean
	createCompanyThenAdmin: (values: any) => void
}

/** higher-order wrapper component for side effects */
const Wrapper: React.FC<SignupContainerProps> = (props) => {
	const history = useHistory()
	const geoPosition = useGeoLocation(
		{
			enableHighAccuracy: true,
			maximumAge: 15000,
			timeout: 12000,
		},
		() => {}
	)

	const createAuthUserAsync = useAsync({ deferFn: createAuthUserDeferFn })

	const initiateCreateAuthUser = (values: any) => {
		createAuthUserAsync.run({
			firstName: values.firstName,
			lastName: values.lastName,
			emailAddress: values.emailAddress,
			company: {
				name: values.orgName,
				address: values.orgAddress,
				phoneNumber: values.orgPhoneNumber,
				organizationNumber: values.orgNumber,
				emailAddress: values.orgEmail,
				geoLocation: {
					latitude: geoPosition.latitude || 10, // incase if the user disallowed location permission
					longitude: geoPosition.longitude || 10, // incase if the user disallowed location permission
				},
			},
		})
	}

	useSideEffect({
		asyncFn: createAuthUserAsync,
		message: 'Sign up successful!',
		description: 'Check your email for verification and then log in!',
		onSuccess: () => {
			history.push('/login')
		},
	})

	if (props.isAuthenticated) return <Redirect to={{ pathname: '/' }} />
	return <Signup {...props} isLoading={createAuthUserAsync.isPending} createCompanyThenAdmin={initiateCreateAuthUser} />
}

export const SignupContainer = connector(Wrapper)
