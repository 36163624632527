/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import {
	Badge,
	Button,
	Col,
	Empty,
	Form,
	Input,
	Modal,
	notification,
	Radio,
	Row,
	Skeleton,
	Space,
	Table,
	Tooltip,
	Typography,
} from 'antd'
import { EditOutlined, InfoCircleOutlined, SearchOutlined } from '@ant-design/icons/lib'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { UsersContainerProps } from '../containers/ManageUsersContainer'
import { LayoutWrapper } from '../../../Components/LayoutWrapper'
import { CheckbeeMenu } from '../../../Components/CheckbeeMenu'
import { CheckbeeCascader } from '../../../Components/CheckbeeCascader'
import { CheckbeeMenuContent } from '../../../Components/CheckbeeMenuContent'
import { CheckbeeDashboardContent, CheckbeeMenuContentHeader } from '../../../Components/containers.styled'
import { formatDate } from '../../../Utils/format-date'
import { colors } from '../../../Assets/colors'
import { User } from '../../auth/authSlice'
import { SubCategory } from '../../category/categorySlice'
import { VisibleItem } from '../../../Components/VisibleItem'

interface Props extends UsersContainerProps {}

type AddFormState = {
	firstName: string
	lastName: string
	emailAddress: string
	role: 'USER' | 'ADMIN'
	licenced: boolean
}
type EditFormState = {
	firstName: string
	lastName: string
	emailAddress: string
	activate: boolean
	archived: boolean
	role: 'USER' | 'ADMIN'
	categories: any
	subCategories: any
	licenced: boolean
}

type SelectedCategory = {
	[key: string]: string[]
}

const AddUserSchema = Yup.object().shape({
	firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
	lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
	emailAddress: Yup.string().email().required('Required'),
	role: Yup.string().oneOf(['USER', 'ADMIN']).required('Required'),
	licenced: Yup.boolean().optional(),
})
const EditUserSchema = Yup.object().shape({
	firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
	lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
	emailAddress: Yup.string().email().required('Required'),
	role: Yup.string().oneOf(['USER', 'ADMIN']).required('Required'),
	activate: Yup.boolean().required('Required'),
	licenced: Yup.boolean().optional(),
})

export const ManageUsers: React.FC<Props> = ({
	updatedUser,
	users,
	categories,
	loggedInUser,
	isGettingUsers,
	isAddingUser,
	isRemovingUser,
	isUpdatingUser,
	isAddUserFulfilled,
	isUpdateUserFulfilled,
	addUser,
	removeUser,
	updateUser,
	resetUpdatedUser,
}) => {
	const initialEditState = { name: false, role: false, email: false, activate: false, archived: false, licenced: false }

	const [selectedUser, setSelectedUser] = React.useState<User | undefined>(users[0])
	const [isAddModalOpen, setIsAddModalOpen] = React.useState(false)
	const [currentCategory, setCurrentCategory] = React.useState('')

	const [tickedCategories, setTickedCategories] = React.useState<string[]>([])
	const [tickedSubCategories, setTickedSubCategories] = React.useState<string[]>([])

	/** if the fields are set to edit mode or not */
	const [editState, setEditState] = React.useState(initialEditState)
	const [selectedSubCategories, setSelectedSubCategories] = React.useState<SubCategory[]>([])

	const [selectedCategories, setSelectedCategories] = React.useState<SelectedCategory>({})

	const formState = useFormik({
		validationSchema: AddUserSchema,
		enableReinitialize: true,
		initialValues: {
			firstName: '',
			lastName: '',
			emailAddress: '',
			role: 'USER',
			licenced: false,
		} as AddFormState,
		onSubmit: (values) => {
			addUser(values)
		},
	})

	const list = ['firstName', 'lastName', 'emailAddress', 'role', 'activate', 'archived', 'licenced'] as const
	type valueType = typeof list[number]
	const checkEdited: any = (fieldName: valueType, value: string) =>
		selectedUser?.[fieldName] === value ? true : { [fieldName]: value }

	const editFormState = useFormik({
		validationSchema: EditUserSchema,
		enableReinitialize: true,
		initialValues: {
			firstName: selectedUser?.firstName,
			lastName: selectedUser?.lastName,
			emailAddress: selectedUser?.emailAddress,
			role: selectedUser?.role,
			activate: selectedUser?.activate,
			archived: selectedUser?.archived,
			categories: selectedUser?.categories,
			subCategories: selectedUser?.subCategories,
			licenced: selectedUser?.licenced,
		} as EditFormState,
		onSubmit: (values) => {
			if (selectedUser?.id) {
				values.categories = tickedCategories
				if (tickedCategories.find((id) => selectedCategories[id].length === 0)) {
					notification.warn({ message: 'Every selected category should have at least one sub category selected.' })
					return
				}
				const selectedSubCategories = tickedCategories.reduce((res: string[], id: string) => {
					return [...res, ...selectedCategories[id]]
				}, [])

				let resultValues: any = {}
				resultValues = { ...resultValues, ...checkEdited('firstName', values.firstName) }
				resultValues = { ...resultValues, ...checkEdited('lastName', values.lastName) }
				resultValues = { ...resultValues, ...checkEdited('emailAddress', values.emailAddress) }
				resultValues = { ...resultValues, ...checkEdited('role', values.role) }
				resultValues = { ...resultValues, ...checkEdited('activate', values.activate) }
				resultValues = { ...resultValues, ...checkEdited('archived', values.archived) }
				resultValues = { ...resultValues, ...checkEdited('licenced', values.licenced) }

				if (
					JSON.stringify(selectedUser.categories.map((e) => e.id).sort()) !== JSON.stringify(tickedCategories.sort())
				) {
					resultValues.categories = tickedCategories
				}

				if (
					JSON.stringify(selectedUser.subCategories.map((e) => e.id).sort()) !==
					JSON.stringify(selectedSubCategories.sort())
				) {
					resultValues.subCategories = selectedSubCategories
				}

				if (Object.keys(resultValues).length) {
					updateUser(selectedUser.id, resultValues)
				}
			} else {
				notification.warn({ message: 'No user selected!' })
			}
		},
	})

	// add - reset form & close modal if request is fulfilled
	React.useEffect(() => {
		if (isAddUserFulfilled) {
			formState.resetForm()
			setIsAddModalOpen(false)
		}
	}, [isAddUserFulfilled])

	// edit - reset form & close modal if request is fulfilled
	React.useEffect(() => {
		if (isUpdateUserFulfilled) {
			editFormState.resetForm()
			setEditState(initialEditState)
		}
	}, [isUpdateUserFulfilled])

	// [edit-specific] - listen for changes in the user object
	React.useEffect(() => {
		if (updatedUser) {
			setSelectedUser(users.find((u) => u.id === updatedUser.id))
			resetUpdatedUser()
		}
	}, [users])

	React.useEffect(() => {
		if (!selectedUser) return
		let tempSelectedCategories: SelectedCategory = {}
		const subCategoryIds = selectedUser.subCategories.map((c: any) => c.id)
		selectedUser.categories.forEach((category: any) => {
			const rawCategory = categories.find((c) => c.id === category.id)
			const rawSubCategoryIds = rawCategory?.subCategories.map((c) => c.id)
			const categoryId = (category.id as string) || ''
			tempSelectedCategories[categoryId] = rawSubCategoryIds?.filter((x) => subCategoryIds.includes(x)) || []
		})
		setSelectedCategories(tempSelectedCategories)
		setTickedCategories(selectedUser.categories.map((c: any) => c.id))
		setCurrentCategory(categories[0]?.id)
		setTickedSubCategories(tempSelectedCategories[categories[0]?.id])
	}, [selectedUser])

	// function same as componentDidUpdate of React
	React.useEffect(() => {
		if (currentCategory === '') return // if currentCategory value is null string, don't run this function
		const category = categories.find((c) => c.id === currentCategory) // to find elements of categories data that id of categories is same wtih currentCategory

		if (!category) return // if currentCategory are not among to categories, don't run this function
		setTickedSubCategories(selectedCategories[category.id]) // to set element of selectedCategories, that the key of selectedCategories is same with category.id, into tickedSubCategories
		setSelectedSubCategories(category.subCategories) // to set the subCategories data of category , that id of categories is same wtih currentCategory, into selectedSubCategories data
	}, [currentCategory])

	const isTrial = loggedInUser?.company?.isTrial
	return (
		<LayoutWrapper>
			<Typography.Title level={4}>
				Add or delete USERS here and assign access to CATEGORIES and CHECKLISTS.
			</Typography.Title>

			<CheckbeeDashboardContent>
				{/* checkbee menu items */}
				<CheckbeeMenu
					title='USERS'
					items={users.map((user) => ({ key: user.id, content: `${user.firstName} ${user.lastName}` }))}
					loading={isGettingUsers}
					selectedKeys={[selectedUser?.id ?? '']} // id is also the key
					onFooterClick={() => setIsAddModalOpen(true)}
					onSelect={(event) => {
						editFormState.resetForm()
						const userIndex = users.findIndex((user) => user.id === event.key)
						setSelectedUser(users[userIndex])
					}}
				/>

				{/* add user modal */}
				<Modal
					title='Add User'
					visible={isAddModalOpen}
					okButtonProps={{
						disabled: Boolean(Object.values(formState.errors).length) || isAddingUser || !formState.dirty,
						loading: isAddingUser,
					}}
					cancelButtonProps={{ disabled: isAddingUser }}
					onOk={() => formState.handleSubmit()}
					onCancel={() => setIsAddModalOpen(false)}>
					<Form layout='vertical'>
						{[
							{ key: 'firstName' as const, label: 'First Name' },
							{ key: 'lastName' as const, label: 'Last Name' },
							{ key: 'emailAddress' as const, label: 'Email' },
						].map(({ key, label }) => (
							<Form.Item label={label} validateStatus={formState.touched[key] && formState.errors[key] ? 'error' : ''}>
								<Input
									placeholder={label}
									size='large'
									name={key}
									value={formState.values[key]}
									onBlur={formState.handleBlur}
									onChange={formState.handleChange}
								/>
							</Form.Item>
						))}
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label='Role'>
									<Radio.Group
										name={'role'}
										size={'large'}
										value={formState.values.role}
										onChange={(e) => formState.setFieldValue('role', e.target.value)}>
										<Radio.Button value='USER'>User</Radio.Button>
										<Radio.Button value='ADMIN'>Admin</Radio.Button>
									</Radio.Group>
								</Form.Item>
							</Col>
							<VisibleItem visible={!isTrial}>
								<Col span={12}>
									<Form.Item label='Licenced'>
										<Radio.Group
											name={'licenced'}
											size={'large'}
											value={formState.values.licenced}
											onChange={(e) => formState.setFieldValue('licenced', e.target.value)}>
											<Radio.Button value={true}>YES</Radio.Button>
											<Radio.Button value={false}>NO</Radio.Button>
										</Radio.Group>
									</Form.Item>
								</Col>
							</VisibleItem>
						</Row>
					</Form>
				</Modal>

				{/* checkbee menu header */}
				<CheckbeeMenuContent>
					{/* still loading */}
					{!Boolean(selectedUser) && isGettingUsers && <Skeleton active avatar paragraph={{ rows: 4 }} />}
					{/* empty data, not loading anymore */}
					{!Boolean(selectedUser) && !isGettingUsers && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
					{/* data loaded */}
					{Boolean(selectedUser) && (
						<>
							<CheckbeeMenuContentHeader>
								{/* <Avatar size='large' style={{ marginRight: 8 }} /> */}
								{editState.name ? (
									// user name - edit mode
									<Form layout='inline' size='large'>
										{[
											{ key: 'firstName' as const, label: 'First Name' },
											{ key: 'lastName' as const, label: 'Last Name' },
										].map(({ key, label }) => (
											<Form.Item
												validateStatus={editFormState.errors[key] ? 'error' : ''}
												style={{ marginRight: 8, maxWidth: 120 }}>
												<Input
													placeholder={label}
													size='large'
													name={key}
													value={editFormState.values[key]}
													onChange={editFormState.handleChange}
												/>
											</Form.Item>
										))}
									</Form>
								) : (
									// user name - view mode
									<Typography.Title level={3} style={{ margin: '0 8px' }}>
										{editFormState?.values?.firstName || selectedUser?.firstName}{' '}
										{editFormState?.values?.lastName || selectedUser?.lastName}
									</Typography.Title>
								)}
								{/* edit name button */}
								<Button
									type='text'
									style={{ padding: 0 }}
									onClick={() => setEditState({ ...editState, name: !editState.name })}>
									<EditOutlined />
								</Button>
								{/* changes unsaved badge */}
								{editFormState.dirty && <Badge status='warning' text='Unsaved' style={{ marginLeft: 12 }} />}

								{/* right aligned header */}
								<Button type='text' style={{ marginLeft: 'auto' }} onClick={() => setSelectedUser(undefined)}>
									<strong>CLOSE</strong>
								</Button>
							</CheckbeeMenuContentHeader>

							{/* user information table */}
							<Table
								style={{ marginTop: 45 }}
								bordered
								pagination={false}
								columns={[
									{
										title: 'FIRM',
										dataIndex: 'company.name',
										key: 'company.name',
										render: () => {
											return loggedInUser.company?.name
										},
									},
									{
										title: 'EMAIL',
										dataIndex: 'emailAddress',
										key: 'emailAddress',
										render: (email) => {
											return (
												<div style={styles.flexed}>
													{editState.email ? (
														// email - edit mode
														<Form.Item
															style={styles.editField}
															validateStatus={editFormState.errors.emailAddress ? 'error' : ''}>
															<Input
																placeholder='Email'
																size='large'
																name='emailAddress'
																value={editFormState.values.emailAddress}
																onChange={editFormState.handleChange}
															/>
														</Form.Item>
													) : (
														// email - view mode
														<a href={`mailto:${email}`}>{editFormState?.values?.emailAddress ?? email}</a>
													)}
													<Button
														type='text'
														style={styles.editButton}
														onClick={() =>
															setEditState({
																...editState,
																email: !editState.email,
															})
														}>
														<EditOutlined />
													</Button>
												</div>
											)
										},
									},
									{
										title: 'LICENCED',
										dataIndex: 'licenced',
										key: 'licenced',
										render: (role) => {
											return (
												<>
													<VisibleItem visible={!isTrial}>
														<div style={styles.flexed}>
															{editState.licenced ? (
																// role - edit mode
																<Form.Item
																	style={styles.editField}
																	validateStatus={editFormState.errors.licenced ? 'error' : ''}>
																	<Radio.Group
																		name={'licenced'}
																		value={editFormState.values.licenced}
																		onChange={(e) => editFormState.setFieldValue('licenced', e.target.value)}>
																		<Radio.Button value={true}>YES</Radio.Button>
																		<Radio.Button value={false}>NO</Radio.Button>
																	</Radio.Group>
																</Form.Item>
															) : (
																// role - view mode
																<div>{(editFormState?.values?.licenced ? 'YES' : 'NO') ?? role}</div>
															)}
															<Button
																type='text'
																style={styles.editButton}
																onClick={() =>
																	setEditState({
																		...editState,
																		licenced: !editState.licenced,
																	})
																}>
																<EditOutlined />
															</Button>
														</div>
													</VisibleItem>
													<VisibleItem visible={isTrial}>
														<span>FREE TRIAL</span>
													</VisibleItem>
												</>
											)
										},
									},
									{
										title: 'USER TYPE',
										dataIndex: 'role',
										key: 'role',
										render: (role) => {
											return (
												<div style={styles.flexed}>
													{editState.role ? (
														// role - edit mode
														<Form.Item
															style={styles.editField}
															validateStatus={editFormState.errors.role ? 'error' : ''}>
															<Radio.Group
																name={'role'}
																value={editFormState.values.role}
																onChange={(e) => editFormState.setFieldValue('role', e.target.value)}>
																<Radio.Button value='USER'>User</Radio.Button>
																<Radio.Button value='ADMIN'>Admin</Radio.Button>
															</Radio.Group>
														</Form.Item>
													) : (
														// role - view mode
														<div>{editFormState?.values?.role ?? role}</div>
													)}
													<Button
														type='text'
														style={styles.editButton}
														onClick={() =>
															setEditState({
																...editState,
																role: !editState.role,
															})
														}>
														<EditOutlined />
													</Button>
												</div>
											)
										},
									},
									{
										title: 'ACTIVE?',
										dataIndex: 'activate',
										key: 'activate',
										render: (_) => {
											return (
												<div style={styles.flexed}>
													{editState.activate ? (
														// activate - edit mode
														<Form.Item
															style={styles.editField}
															validateStatus={editFormState.errors.activate ? 'error' : ''}>
															<Radio.Group
																name={'activate'}
																value={editFormState.values.activate}
																onChange={(e) => editFormState.setFieldValue('activate', e.target.value)}>
																<Radio.Button value={true}>YES</Radio.Button>
																<Radio.Button value={false}>NO</Radio.Button>
															</Radio.Group>
														</Form.Item>
													) : (
														// activate - view mode
														<div>
															{editFormState?.values?.activate ? (
																<Badge status='success' text='ACTIVATED' />
															) : (
																<Badge status='error' text='NOT ACTIVATED' />
															)}
														</div>
													)}
													<Button
														type='text'
														style={styles.editButton}
														onClick={() =>
															setEditState({
																...editState,
																activate: !editState.activate,
															})
														}>
														<EditOutlined />
													</Button>
												</div>
											)
										},
									},
									{
										title: 'ARCHIVED?',
										dataIndex: 'archived',
										key: 'archived',
										render: (_) => (
											<div style={styles.flexed}>
												{editState.archived ? (
													// activate - edit mode
													<Form.Item style={styles.editField}>
														<Radio.Group
															name={'activate'}
															value={editFormState.values.archived}
															onChange={(e) => editFormState.setFieldValue('archived', e.target.value)}>
															<Radio.Button value={true}>YES</Radio.Button>
															<Radio.Button value={false}>NO</Radio.Button>
														</Radio.Group>
													</Form.Item>
												) : (
													// activate - view mode
													<div>
														{editFormState?.values?.archived ? (
															<Badge status='error' text='ARCHIVED' />
														) : (
															<Badge status='success' text='UNARCHIVED' />
														)}
													</div>
												)}
												<Button
													type='text'
													style={styles.editButton}
													onClick={() =>
														setEditState({
															...editState,
															archived: !editState.archived,
														})
													}>
													<EditOutlined />
												</Button>
											</div>
										),
									},
									{
										title: 'MEMBER SINCE',
										dataIndex: 'createdAt',
										key: 'createdAt',
										render: (date) => formatDate(date),
									},
								]}
								dataSource={selectedUser ? [selectedUser] : []}
							/>

							{/* categories, subcategories	*/}
							<div style={{ marginTop: 30 }}>
								<Space align='center' size={8}>
									<Typography.Text strong>
										To which categories or subcategories do you want this user to have access?
									</Typography.Text>
									<Tooltip
										placement='right'
										title='Choose which categories and subcategories you would like this user to have access to. This will enable this user to create new tasks, and see others tasks in this category and or subcategory.'
										color={colors.primaryColor}>
										<InfoCircleOutlined />
									</Tooltip>
								</Space>

								<Row gutter={24} style={{ marginTop: 26 }}>
									<Col span={5}>
										<CheckbeeCascader
											key={selectedUser?.id}
											title='CATEGORIES'
											titleIcon={SearchOutlined}
											itemObjects={categories.map((category) => ({
												key: category.id,
												value: category.name,
											}))}
											tickedItems={tickedCategories}
											selectedItem={currentCategory}
											onItemClick={({ index }) => {
												setCurrentCategory(categories[index].id)
											}}
											onCheckOn={(key) => {
												let calculatedResult = [...tickedCategories, key]
												setTickedCategories(calculatedResult)

												const foundCategory = categories.find((c) => c.id === key)
												if (foundCategory === undefined) {
													return
												}
												calculatedResult = foundCategory.subCategories.map((sC) => sC.id)
												setTickedSubCategories(calculatedResult)
												selectedCategories[key] = calculatedResult
											}}
											onCheckOff={(key) => {
												let calculatedResult = [...tickedCategories.filter((tC) => tC !== key)]
												setTickedCategories(calculatedResult)

												const foundCategory = categories.find((c) => c.id === currentCategory)
												if (foundCategory === undefined) {
													return
												}
												setTickedSubCategories([])
												selectedCategories[currentCategory] = []
											}}
											onCheckAllOn={() => {
												setTickedCategories(categories.map((c) => c.id))

												categories.forEach((c) => {
													selectedCategories[c.id] = []
													c.subCategories.forEach((sC) => {
														selectedCategories[c.id].push(sC.id)
													})
												})
												setTickedSubCategories(selectedCategories[currentCategory])
											}}
											onCheckAllOff={() => {
												setTickedCategories([])
												setTickedSubCategories([])
												setSelectedCategories({})
											}}
										/>
									</Col>
									<Col span={5}>
										{currentCategory && (
											<CheckbeeCascader
												key={selectedUser?.id}
												onItemClick={(_) => {}}
												title='SUBCATEGORIES'
												tickedItems={tickedSubCategories}
												itemObjects={selectedSubCategories.map((subcat) => ({
													key: subcat.id,
													value: subcat.name,
												}))}
												onCheckOn={(key) => {
													const calculatedResult = [...(tickedSubCategories ? tickedSubCategories : []), key]
													setTickedSubCategories(calculatedResult)
													setTickedCategories(
														[...tickedCategories, currentCategory].filter(
															(c, index, array) => array.indexOf(c) === index
														)
													)
													selectedCategories[currentCategory] = calculatedResult
												}}
												onCheckOff={(key) => {
													const calculatedResult = [...tickedSubCategories.filter((tS) => tS !== key)]
													setTickedSubCategories(calculatedResult)
													selectedCategories[currentCategory] = calculatedResult
												}}
												onCheckAllOn={() => {
													const foundCategory = categories.find((c) => c.id === currentCategory)
													if (foundCategory === undefined) {
														return
													}
													const calculatedResult = foundCategory.subCategories.map((sC) => sC.id)
													setTickedSubCategories(calculatedResult)
													selectedCategories[currentCategory] = calculatedResult
												}}
												onCheckAllOff={() => {
													const foundCategory = categories.find((c) => c.id === currentCategory)
													if (foundCategory === undefined) {
														return
													}
													setTickedSubCategories([])
													selectedCategories[currentCategory] = []
												}}
											/>
										)}
									</Col>
								</Row>
							</div>

							<Space size={8} style={{ position: 'absolute', bottom: 32, right: 32 }}>
								<Button
									type='primary'
									size='large'
									loading={isAddingUser || isRemovingUser || isUpdatingUser}
									disabled={isAddingUser || isRemovingUser || isUpdatingUser}
									onClick={() => editFormState.handleSubmit()}>
									SAVE USER
								</Button>
							</Space>
						</>
					)}
				</CheckbeeMenuContent>
			</CheckbeeDashboardContent>
		</LayoutWrapper>
	)
}

const styles = {
	flexed: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	editButton: {
		padding: 0,
		marginLeft: 12,
	},
	editField: { margin: 0, width: '100%' },
}
