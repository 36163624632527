/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Col, Form, Input, Row, Button, Select, Typography, InputNumber } from 'antd'
import { FileTextOutlined, MessageOutlined, UserOutlined, ApartmentOutlined } from '@ant-design/icons/lib'
import styled from 'styled-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { colors } from '../../../Assets/colors'
import { Item } from '../itemSlice'
import { Category, SubCategory } from '../../category/categorySlice'
import { User } from '../../auth/authSlice'
import { VisibleItem } from '../../../Components/VisibleItem'
import { RootState } from '../../../store'
import { useSelector } from 'react-redux'

interface Props {
	isEditingItem: boolean
	isGettingCategories: boolean
	isGettingUsers: boolean
	isEditItemFulfilled: boolean
	isGettingItems: boolean
	editItem: (values: any) => void
	deleteItem: (id: string) => void
	isDeletingItem: boolean
	item: Item
	categories: Category[]
	availableAssignees: User[]
}

const StyledForm = styled(Form)`
	margin-top: 10px;
	.ant-form-item-label > label {
		height: 38px;
	}
`

export type ItemForm = {
	name: string
	type: string
	manufacturer: string
	prodYear: string
	serialNumber: string
	description: string
	subCategory: string // id
	assign?: string // id
}

const ItemFormSchema = Yup.object().shape({
	name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
	description: Yup.string().min(2, 'Too Short!').optional(),
	category: Yup.string().optional(),
	subCategory: Yup.string(),
})

type ItemFields =
	| 'name'
	| 'type'
	| 'manufacturer'
	| 'prodYear'
	| 'serialNumber'
	| 'description'
	| 'subCategoryId'
	| 'assignId'

export const EditItem: React.FC<Props> = ({
	item,
	availableAssignees,
	isGettingUsers,
	isEditingItem,
	isGettingCategories,
	editItem,
	deleteItem,
	isDeletingItem,
}) => {
	const subCategories = useSelector<RootState, SubCategory[]>((state) =>
		state.category.list.reduce((result: SubCategory[], row: Category) => [...result, ...row.subCategories], [])
	)

	const initialValues: ItemForm = {
		name: item.name,
		type: item.type,
		manufacturer: item.manufacturer,
		prodYear: item.prodYear,
		serialNumber: item.serialNumber,
		description: item.description,
		subCategory: item.subCategoryId,
		assign: item.assignId,
	}

	const [form] = Form.useForm()
	const formState = useFormik({
		enableReinitialize: true,
		initialValues: initialValues,
		validationSchema: ItemFormSchema,
		onSubmit: (values) => {
			let requestData: { [key: string]: string | number | undefined } = {
				id: item.id,
			}

			const addIfEdited = (fieldName: ItemFields, newValue: string | number | undefined) =>
				item[fieldName] !== newValue ? { [fieldName]: newValue } : {}

			requestData = { ...requestData, ...addIfEdited('name', values.name) }
			requestData = { ...requestData, ...addIfEdited('type', values.type) }
			requestData = { ...requestData, ...addIfEdited('manufacturer', values.manufacturer) }
			requestData = { ...requestData, ...addIfEdited('prodYear', values.prodYear) }
			requestData = { ...requestData, ...addIfEdited('serialNumber', values.serialNumber) }
			requestData = { ...requestData, ...addIfEdited('description', values.description) }
			requestData = { ...requestData, ...addIfEdited('subCategoryId', values.subCategory) }
			requestData = { ...requestData, ...addIfEdited('assignId', values.assign) }

			if (Object.keys(requestData).length > 1) {
				editItem(requestData)
			}
		},
	})

	const disabled = !!item.archived
	return (
		<Row style={{ pointerEvents: disabled ? 'none' : 'unset' }} gutter={32}>
			{/* ITEM FORM */}
			<Col span={24}>
				<VisibleItem visible={disabled}>
					<Typography.Text style={{ marginLeft: 20 }} type={'danger'}>
						Item is archived, You can't change it anymore
					</Typography.Text>
				</VisibleItem>
				<StyledForm form={form} labelCol={{ span: 2 }} wrapperCol={{ span: 32 }}>
					{/* name */}
					<Row>
						<Col span={11}>
							{['name' as const, 'type' as const, 'manufacturer' as const, 'serialNumber' as const].map((field) => (
								<Form.Item
									label={<FileTextOutlined style={styles.iconLabel} />}
									name={field}
									validateStatus={formState.touched[field] && formState.errors[field] ? 'error' : ''}>
									<Input
										size='large'
										placeholder={field.toLocaleUpperCase()}
										name={field}
										defaultValue={formState.values[field]}
										value={formState.values[field]}
										onChange={formState.handleChange}
										onBlur={formState.handleBlur}
									/>
								</Form.Item>
							))}
							<Form.Item
								label={<FileTextOutlined style={styles.iconLabel} />}
								name={'prodYear'}
								validateStatus={formState.touched.prodYear && formState.errors.prodYear ? 'error' : ''}>
								<InputNumber
									style={{ width: '100%' }}
									size='large'
									placeholder={'PRODUCTION YEAR'}
									name='prodYear'
									min={1900}
									defaultValue={parseInt(formState.values.prodYear, 10)}
									max={new Date().getFullYear() as number}
									value={parseInt(formState.values.prodYear, 10)}
									onChange={(value) => formState.setFieldValue('prodYear', value)}
									onBlur={formState.handleBlur}
								/>
							</Form.Item>
						</Col>
						<Col span={11}>
							<Form.Item
								label={<MessageOutlined style={styles.iconLabel} />}
								name='description'
								validateStatus={formState.touched.description && formState.errors.description ? 'error' : ''}>
								<Input.TextArea
									autoSize={{ minRows: 6.5, maxRows: 6.5 }}
									placeholder='DESCRIPTION'
									name='description'
									defaultValue={formState.initialValues.description}
									value={formState.values.description}
									onChange={formState.handleChange}
									onBlur={formState.handleBlur}
								/>
							</Form.Item>

							{/* subcategory */}
							<Form.Item
								label={<ApartmentOutlined style={styles.iconLabel} />}
								name='subcategory'
								hasFeedback
								validateStatus={formState.errors.subCategory ? 'error' : ''}>
								<Input.Group compact style={{ display: 'flex' }}>
									<Input disabled size='large' defaultValue='SUBCATEGORY' />
									<Select
										style={{ flex: '1 0 65%' }}
										size='large'
										loading={isGettingCategories}
										disabled={isGettingCategories}
										value={formState.values.subCategory}
										onChange={(value) => {
											formState.setFieldValue('subCategory', value)
											formState.setFieldValue('assign', '')
										}}>
										{subCategories.map((subcat) => {
											return (
												<Select.Option key={subcat.id} value={subcat.id}>
													{subcat.name}
												</Select.Option>
											)
										})}
									</Select>
								</Input.Group>
							</Form.Item>

							{/* assignee */}
							<Form.Item
								hasFeedback
								label={<UserOutlined style={styles.iconLabel} />}
								validateStatus={formState.errors.assign ? 'error' : ''}>
								<Input.Group compact style={{ display: 'flex' }}>
									<Input disabled size='large' defaultValue='ASSIGN TO' />
									<Select
										style={{ flex: '1 0 65%' }}
										size='large'
										loading={isGettingUsers}
										disabled={isGettingUsers}
										value={formState.values.assign}
										onChange={(value) => formState.setFieldValue('assign', value)}>
										<Select.Option value={''}>{'No one'}</Select.Option>
										{availableAssignees.length &&
											availableAssignees
												.filter((user) =>
													user.subCategories.find((subCat) => subCat.id === formState.values.subCategory)
												)
												.map((user) => (
													<Select.Option key={user.id} value={user.id}>
														{user.firstName} {user.lastName}
													</Select.Option>
												))}
									</Select>
								</Input.Group>
							</Form.Item>
						</Col>
					</Row>
				</StyledForm>
			</Col>

			<VisibleItem visible={!disabled}>
				<div className='item-edit-actions-container'>
					<Button
						className='item-action-button'
						type='primary'
						style={{ marginRight: 20 }}
						disabled={isEditingItem}
						loading={isEditingItem}
						onClick={() => formState.handleSubmit()}>
						SAVE ITEM
					</Button>

					<Button
						className='item-action-button'
						type='primary'
						danger
						disabled={isDeletingItem}
						loading={isDeletingItem}
						onClick={() => deleteItem(item.id)}>
						ARCHIVE
					</Button>
				</div>
			</VisibleItem>
		</Row>
	)
}

const styles = {
	iconLabel: { fontSize: 24, color: colors.primaryColor },
	radioButton: { textAlign: 'center' as const },
}
