import React from 'react'

const ListIcon = () => (
	<svg
		style={{ marginLeft: 4 }}
		xmlns='http://www.w3.org/2000/svg'
		width='11.437'
		height='7.624'
		viewBox='0 0 11.437 7.624'>
		<path
			id='Icon_material-sort'
			data-name='Icon material-sort'
			d='M4.5,16.624H8.312V15.354H4.5ZM4.5,9v1.271H15.937V9Zm0,4.448h7.624V12.177H4.5Z'
			transform='translate(-4.5 -9)'
		/>
	</svg>
)

export default ListIcon
