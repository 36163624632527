import React from 'react'

export default (
	<svg xmlns='http://www.w3.org/2000/svg' width='30' height='20' viewBox='0 0 38.769 25.846'>
		<path
			id='Icon_material-playlist-add-check'
			data-name='Icon material-playlist-add-check'
			d='M25.154,16.385H3v3.692H25.154Zm0-7.385H3v3.692H25.154ZM3,27.462H17.769V23.769H3Zm36-8.308,2.769,2.769-12.9,12.923-8.326-8.308,2.769-2.769,5.557,5.538,3.813-3.82Z'
			transform='translate(-3 -9)'
			fill='#fff'
		/>
	</svg>
)
