import { Dispatch } from '@reduxjs/toolkit'
import { DeferFn, PromiseFn } from 'react-async'

import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { handleError } from '../../../Utils/handle-error'

/**
 * this file contains 3 implementations of getUser function:
 * - action
 * - react-async promiseFn
 * - react-async deferFn
 */

/**
 * getUser action
 * [generic] - can be reused in both defer and promise functions
 */
export const getUser = (value: string) => (dispatch: Dispatch) => {
	console.log('[!] HEADS UP! -- executing getUser')
}

/**
 * getUserPromiseFn
 * {boilerplate} - remove this line if the function is functional
 * [react-async's promiseFn - automatically invoked]
 * - this will automatically be invoked if called
 * - no need to try/catch
 * - https://docs.react-async.com/guide/async-components
 *
 * @example [usage]
 * const getUserAsync = useAsync({
 * 		promiseFn: getUserPromiseFn,
 * 		id: SOME_ID_HERE, // arguments
 * })
 * useSideEffect({
 * 		asyncFn: getUserAsync,
 * 		message: 'Successful',
 * 		onSuccess: () => {},
 * })
 *
 * @param data
 * @param signal
 */
export const getUserPromiseFn: PromiseFn<any> = async (data, { signal }) => {
	/** if you ever need a tenantKey from process.env */
	const tenantKey = process.env.REACT_APP_TENANT_KEY

	/** [boilerplate code] example usage of `signal` with fetch */
	const response = await fetch(`${checkbeeAPIBase}/something`, {
		signal,
		method: 'GET', // or POST
		headers: {
			'Tenant-Key': `${tenantKey}`,
		},
	})

	const result = await response.json()
	if (result.status === 'success') {
		return result.data
	} else {
		throw new Error(result?.data?.message || result?.message || result?.data?.name || 'Failed to get something!')
	}
}

/**
 * getUserDeferFn
 * [react-async's deferFn - manually invoked]
 *
 * @example usage
 * const getUserAsync = useAsync({ deferFn: getUserDeferFn })
 * const initiateGetUser = (arg: any, anotherArg: any) => {
 * 		getUserAsync.run(arg, anotherArg)
 * }
 * useSideEffect({
 * 		asyncFn: getUserAsync,
 * 		message: 'Successful',
 * 		onSuccess: () => {},
 * })
 *
 * @param userId
 * @param props
 * @param signal
 */
export const getUserDeferFn: DeferFn<any> = async ([userId], props, { signal }) => {
	const tenantKey = process.env.REACT_APP_TENANT_KEY
	const response = await fetch(`${checkbeeAPIBase}/user/${userId}`, {
		signal,
		method: 'GET',
		headers: {
			'Tenant-Key': `${tenantKey}`,
		},
	})

	const result = await response.json()
	if (result.status === 'success') {
		return result.data
	} else {
		const err = handleError(result, 'Failed to get user!')
		throw new Error(err)
	}
}
