import { DownloadOutlined } from '@ant-design/icons'
import { Table } from 'antd'
import moment from 'moment'
import React from 'react'

type AlignType = 'left' | 'center' | 'right'
const columns = [
	{
		title: 'Due Date',
		dataIndex: 'period_start',
		key: 'period_start',
		render: (periodStart: any) => moment.unix(periodStart).format('YYYY-MM-DD'),
	},
	{
		title: 'Amount',
		dataIndex: 'total',
		key: 'total',
		render: (total: any) => `${total / 100}kr`,
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
	},
	{
		title: 'Download',
		dataIndex: 'invoice_pdf',
		key: 'invoice_pdf',
		align: 'right' as AlignType,
		render: (invoicePdf: any) => (
			<a href={invoicePdf} target='_blank' rel='noopener noreferrer'>
				<DownloadOutlined style={{ fontSize: '22px' }} />
			</a>
		),
	},
]

const InvoicesHistory: React.FC<{ invoices: any[] }> = ({ invoices }) => {
	return (
		<div>
			<Table pagination={false} dataSource={invoices} columns={columns} />
		</div>
	)
}

export default InvoicesHistory
