import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { User } from '../auth/authSlice'

export type SubCategory = {
	name: string
	imageUrl: null | string
	id: string
	archived: null | string
	users?: User[]
	powerUrl: null | string
}

export type Category = {
	id: string
	name: string
	imageUrl: string
	subCategories: SubCategory[]
}

export const categorySlice = createSlice({
	name: 'category',
	initialState: {
		list: [] as Category[],
		users: [] as User[],
	},
	reducers: {
		receiveCategories: (state, action: PayloadAction<{ categories: Category[] }>) => {
			state.list = action.payload.categories
		},
		receiveCategoryUsers: (state, action: PayloadAction<{ users: User[] }>) => {
			state.users = action.payload.users
		},
	},
})
