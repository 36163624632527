import moment, { Moment } from 'moment'

export class DatetimeService {
	public static formatDuration(start: Moment, end: Moment): string {
		const duration = moment.duration(end.diff(start))

		//Get Days
		let days = Math.floor(duration.asDays()) - 1 // .asDays returns float but we are interested in full days only
		days = days < 0 ? 0 : days // remove for minutes
		const daysFormatted = days ? `${days} days ` : '' // if no full days then do not display it at all

		//Get Hours
		const hours = duration.hours()
		const hoursFormatted = `${hours} hours `

		//Get Minutes
		const minutes = duration.minutes()
		const minutesFormatted = `${minutes} minutes`

		return [daysFormatted, hoursFormatted, minutesFormatted].join('')
	}

	public static timeInMinutes(avg: any) {
		if (!avg) return 'N/A'
		return avg?.hours * 60 + avg?.minutes
	}
}
