import { CheckOutlined } from '@ant-design/icons'
import { Badge, Button, Popover, Typography } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListIcon from '../../../../Assets/SvgIcons/ListIcon'
import { RootState } from '../../../../store'
import { Category, SubCategory } from '../../../category/categorySlice'
import { applyFilter, deleteFilter } from '../../taskTemplatesSlice'

interface SubCategoryRowProps {
	title: string
	selected: boolean
	onClick: Function
}
const SubCategoryRow: React.FC<SubCategoryRowProps> = ({ title, selected, onClick }) => {
	return (
		<div onClick={() => onClick()} className='subcategory-filter-row'>
			<Typography className='subcategory-filter-row-title'>{title}</Typography>
			{selected ? <CheckOutlined style={{ color: '#F7C456' }} /> : null}
		</div>
	)
}

const SubCategoryFilter = () => {
	const categories = useSelector<RootState, Category[]>((state) => state.category.list)
	const dispatch = useDispatch()
	const selectedCategoryIds = useSelector<RootState, string[] | undefined>((state) => state.task.filter?.categoryIds)

	const [selected, setSelected] = useState<string[]>([])
	const [visible, setVisible] = useState(false)

	let data: SubCategory[] = []
	categories
		.filter((cat) => !selectedCategoryIds || selectedCategoryIds.some((id) => cat.id === id))
		.forEach((cat) => {
			data = data.concat(cat.subCategories)
		})

	return (
		<>
			<Popover
				placement='bottomRight'
				visible={visible}
				onVisibleChange={(newVisible) => {
					setVisible(newVisible)
				}}
				content={
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 200 }}>
						<Typography className='filter-title'>CHOOSE SUBCATEGORY</Typography>
						<div className='select-container'>
							{data.map((subCat) => (
								<SubCategoryRow
									onClick={() => {
										let newFilters = []
										if (selected.some((e) => e === subCat.id)) {
											newFilters = selected.filter((e) => e !== subCat.id)
										} else {
											newFilters = [...selected, subCat.id]
										}
										setSelected(newFilters)
										if (newFilters.length) {
											dispatch(applyFilter({ subCategoryIds: newFilters }))
										} else {
											dispatch(deleteFilter('subCategoryIds'))
										}
									}}
									selected={selected.some((e) => e === subCat.id)}
									title={subCat.name}
								/>
							))}
						</div>
						<Button
							onClick={() => {
								setSelected([])
								setVisible(false)
								dispatch(deleteFilter('subCategoryIds'))
							}}
							color='lightgray'
							className='task-action-button'
							style={{ marginTop: 10 }}
							type='link'>
							Clear filter
						</Button>
					</div>
				}
				trigger='click'>
				<Button type='link' className='filter-button'>
					{ListIcon()}
				</Button>
			</Popover>
			<Badge className='tasks-filter-badge' size='small' count={selected.length} />
		</>
	)
}

export default SubCategoryFilter
