import React from 'react'
import { Form, Input, Button } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import { LoginContainerProps } from '../containers/LoginContainer'
import { images } from '../../../Assets/images'
import { ActivationLink } from './ActivationLink'

interface Props extends LoginContainerProps {}

export const Login: React.FC<Props> = ({
	isSendingOTP,
	isGenerateOTPSuccess,
	isVerifyingOTP,
	initiateOTPGeneration,
	initiateOTPVerification,
}) => {
	// TODO: replace with formik
	const [email, setEmail] = React.useState('')
	const [otp, setOtp] = React.useState('')

	const signIn = () => {
		if (isGenerateOTPSuccess) {
			initiateOTPVerification(email, otp)
		} else {
			initiateOTPGeneration(email)
		}
	}

	return (
		<div style={styles.container}>
			<div style={styles.content}>
				<div style={styles.logoContainer}>
					<img alt='' src={images.logo} style={styles.logo} />
				</div>

				<Form>
					<Form.Item name='email'>
						<Input
							disabled={isSendingOTP || isVerifyingOTP}
							size={'large'}
							prefix={<UserOutlined />}
							placeholder='Email'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									signIn()
								}
							}}
						/>
					</Form.Item>

					{isGenerateOTPSuccess && (
						<Form.Item name='otp'>
							<Input
								disabled={isSendingOTP || isVerifyingOTP}
								size={'large'}
								prefix={<LockOutlined />}
								placeholder='OTP'
								value={otp}
								onChange={(e) => setOtp(e.target.value)}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										signIn()
									}
								}}
							/>
						</Form.Item>
					)}

					<Form.Item style={styles.buttonContainer}>
						<Button
							block
							size={'large'}
							type='primary'
							disabled={isSendingOTP || isVerifyingOTP}
							loading={isSendingOTP || isVerifyingOTP}
							onClick={signIn}>
							Sign in
						</Button>
					</Form.Item>
					<ActivationLink email={email} />
				</Form>
			</div>

			<div style={styles.footer}>
				<Link to='/sign-up'>
					<div style={styles.linkAsAText}>Create account</div>
				</Link>
				<a href='https://www.checkbee.app/' rel='noopener noreferrer' target='_blank'>
					<i>What is Checkbee?</i>
				</a>
			</div>
		</div>
	)
}

/** @type {{search: React.CSSProperties}} */
const styles = {
	container: {
		width: '100%',
		height: '100vh',
		position: 'relative' as const,
	},
	content: {
		maxWidth: 300,
		margin: '0 auto',
		padding: '200px 0',
	},
	logoContainer: {
		overflow: 'hidden',
		marginBottom: 72,
		textAlign: 'center' as const,
	},
	logo: {
		width: '60%',
	},
	buttonContainer: {
		marginTop: 32,
	},
	footer: {
		width: '100%',
		textAlign: 'center' as const,
		position: 'absolute' as const,
		bottom: 56,
	},
	linkAsAText: {
		color: 'rgba(0, 0, 0, 0.65)',
		marginBottom: 14,
	},
}
