import React from 'react'
import { colors } from '../colors'

const DateIcon = () => (
	<svg style={{ marginRight: -3 }} width='30' height='30' fill={colors.primaryColor} viewBox='0 0 256 256'>
		<rect width='256' height='256' fill='none'></rect>
		<rect
			x='40'
			y='40'
			width='176'
			height='176'
			rx='8'
			strokeWidth='16'
			stroke={colors.primaryColor}
			strokeLinecap='round'
			strokeLinejoin='round'
			fill='none'></rect>
		<line
			x1='176'
			y1='24'
			x2='176'
			y2='56'
			fill='none'
			stroke={colors.primaryColor}
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth='16'></line>
		<line
			x1='80'
			y1='24'
			x2='80'
			y2='56'
			fill='none'
			stroke={colors.primaryColor}
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth='16'></line>
		<line
			x1='40'
			y1='88'
			x2='216'
			y2='88'
			fill='none'
			stroke={colors.primaryColor}
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth='16'></line>
		<path
			d='M92,127.99666h28l-16,20a16,16,0,1,1-11.30681,27.3206'
			fill='none'
			stroke={colors.primaryColor}
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth='16'></path>
		<polyline
			points='144 139.997 160 127.997 160 179.997'
			fill='none'
			stroke={colors.primaryColor}
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth='16'></polyline>
	</svg>
)

export default DateIcon
