import { DeferFn, PromiseFn } from 'react-async'

import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { handleError } from '../../../Utils/handle-error'

interface CreateCheckList {
	id?: string
	title?: string
	image?: string
	assign?: string[]
	checkpoints?: {
		id?: string
		title?: string
		description?: string
		assign?: string[]
	}[]
}

/**
 * this file contains 3 implementations of createCheckList function:
 * - action
 * - react-async promiseFn
 * - react-async deferFn
 */

/**
 * createCheckList action
 * [generic] - can be reused in both defer and promise functions
 */
export const createCheckList = async (checkList: CreateCheckList, signal: AbortSignal) => {
	const tenantKey = process.env.REACT_APP_TENANT_KEY
	const token = localStorage.getItem('chibi-tkn')
	const response = await fetch(`${checkbeeAPIBase}/checklist`, {
		signal,
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Tenant-Key': `${tenantKey}`,
			'Authorization': `Bearer ${token}`,
		},
		body: JSON.stringify(checkList),
	})

	const result = await response.json()
	if (result.status === 'success') {
		return result.data
	} else {
		const err = handleError(result, 'Failed to create checklist!')
		throw new Error(err)
	}
}

/**
 * createCheckListPromiseFn
 * [react-async's promiseFn - automatically invoked]
 *
 * @example [usage]
 * const createCheckListAsync = useAsync({
 * 		promiseFn: createCheckListPromiseFn,
 * 		id: SOME_ID_HERE, // arguments
 * })
 * useSideEffect({
 * 		asyncFn: createCheckListAsync,
 * 		message: 'Successful',
 * 		onSuccess: () => {},
 * })
 *
 * @param rest
 * @param signal
 */
export const createCheckListPromiseFn: PromiseFn<any> = async ({ data: rest }, { signal }) => {
	return await createCheckList(rest, signal)
}

/**
 * createCheckListDeferFn
 * [react-async's deferFn - manually invoked]
 *
 * @param rest
 * @param props
 * @param signal
 */
export const createCheckListDeferFn: DeferFn<any> = async ([rest], props, { signal }) => {
	return await createCheckList(rest, signal)
}
