/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import { PrivateRoute } from '../../../Components/PrivateRoute'
import { Checkbee404 } from '../../../Components/Checkbee404'
import { LoginContainer } from '../../../Modules/auth/containers/LoginContainer'
import { ManageUsersContainer } from '../../../Modules/user/containers/ManageUsersContainer'
import { DashboardContainer } from '../../../Modules/dashboard/containers/DashboardContainer'
import { ManageCategoriesContainer } from '../../../Modules/category/containers/ManageCategoriesContainer'
import { SignupContainer } from '../../../Modules/auth/containers/SignupContainer'
import { AdminOverviewContainer } from '../../../Modules/dashboard/containers/AdminOverviewContainer'
import { AddChecklistContainer } from '../../../Modules/checklist/containers/AddChecklistContainer'
import { TasksListContainer } from '../../../Modules/task/containers/TasksContainer'
import { TaskTemplatesListContainer } from '../../taskTemplates/containers/TaskTemplatesContainer'
import { useAsync } from 'react-async'
import { getUserDataDeferFn } from '../actions/getUserData'
import { useSideEffect } from '../../../Hooks/useSideEffect'
import { connect, ConnectedProps } from 'react-redux'
import { authSlice } from '../../auth/authSlice'
import { RootState } from '../../../store'
import { initiateLogout } from '../../auth/actions/initiateLogout'
import storage from 'redux-persist/lib/storage'
import { ItemsListContainer } from '../../item/containers/ItemsContainer'

// intersept fetch to logout
const { fetch: origFetch } = window
window.fetch = async (...args) => {
	const response = await origFetch(...args)

	response
		.clone()
		.json()
		.then((body) => {
			if (body?.data?.httpCode === 401 && window.location.pathname !== '/login') {
				storage.removeItem('persist:root')
				storage.removeItem('chibi-tkn')
				window.location.reload()
			}
		})
		.catch((err) => console.error(err))
	return response
}

const mapState = (state: RootState) => ({
	user: state.auth.user,
})

/**
 * defining mapDispatchToProps as an object
 * see https://react-redux.js.org/using-react-redux/connect-mapdispatch#defining-mapdispatchtoprops-as-an-object
 */
const mapDispatch = {
	initiateLogout,
	receiveUser: authSlice.actions.receiveUser,
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
type AppContainerProps = PropsFromRedux & {}

/** higher-order wrapper component for side effects */
const Wrapper: React.FC<AppContainerProps> = (props) => {
	global.addEventListener('fetch', (props) => {
		console.log('theyeeee', props)
	})

	const getUserDataAsync = useAsync({ deferFn: getUserDataDeferFn })
	const routeName = useLocation()

	useSideEffect({
		asyncFn: getUserDataAsync,
		onSuccess: () => {
			const { data } = getUserDataAsync
			if (data) {
				props.receiveUser({ user: data })
			}
		},
	})

	useEffect(() => {
		getUserDataAsync.run()
	}, [routeName.pathname])

	return (
		<Switch>
			{/* NOTE: ALL PATHS THAT'S INSIDE THE LAYOUT SHOULD BE DEFINED IN <App /> */}
			<PrivateRoute exact path='/' component={DashboardContainer} />
			<PrivateRoute path='/users' component={ManageUsersContainer} />
			<PrivateRoute path='/categories' component={ManageCategoriesContainer} />
			<PrivateRoute path='/tasks' component={TasksListContainer} />
			<PrivateRoute path='/task-templates' component={TaskTemplatesListContainer} />
			<PrivateRoute path='/items' component={ItemsListContainer} />
			<PrivateRoute path='/add-checklist' component={AddChecklistContainer} />
			<PrivateRoute path='/admin' component={AdminOverviewContainer} />
			<Route path='/login' component={LoginContainer} />
			<Route path='/sign-up' component={SignupContainer} />
			<Route path='*' component={Checkbee404} />
		</Switch>
	)
}

export const AppContainer = connector(Wrapper)
