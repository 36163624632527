import React from 'react'
import { Spin, Typography } from 'antd'

export const Loader: React.FC<{}> = () => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
			<Spin size='large' />
			<Typography.Title type='secondary' style={{ margin: 10 }} level={5}>
				Loading...
			</Typography.Title>
		</div>
	)
}
