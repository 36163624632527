import React from 'react'

const MegaPhoneIcon = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='16.168' height='16.16' viewBox='0 0 16.168 16.16'>
			<g id='Icon_ionic-ios-megaphone' data-name='Icon ionic-ios-megaphone' transform='translate(-3.368 -3.375)'>
				<path
					id='Path_35'
					data-name='Path 35'
					d='M5.007,10.477A3.568,3.568,0,0,1,6.7,7.412a.126.126,0,0,0-.066-.233h0A3,3,0,0,0,3.753,9.89a.552.552,0,0,0-.385.579.53.53,0,0,0,.42.575A3.428,3.428,0,0,0,6.165,13.55h0a.169.169,0,0,0,.148-.291A3.871,3.871,0,0,1,5.007,10.477Z'
					transform='translate(0 -1.702)'
				/>
				<path
					id='Path_36'
					data-name='Path 36'
					d='M21.154,5.5c-.039-.1-.082-.2-.124-.3a3.985,3.985,0,0,0-.843-1.251,1.917,1.917,0,0,0-1.352-.579,1.776,1.776,0,0,0-1,.3,13.855,13.855,0,0,1-5.982,1.787c-1.62,0-1.531.008-1.531.008a3.642,3.642,0,0,0-.4,6.41c.47.392.917.342.82.862s-.874,3.788-.971,4.467-.113,1.068.155,1.4a5.733,5.733,0,0,0,2.642.925c.719,0,.536-.338.536-1.7s-.361-5.116.641-5.116c1.876,0,2.855.672,4.324,1.231a2.082,2.082,0,0,0,.781.183,1.916,1.916,0,0,0,1.348-.707,4.025,4.025,0,0,0,.843-1.251c.043-.1.085-.2.128-.311a9.071,9.071,0,0,0,.532-3.182A9.077,9.077,0,0,0,21.154,5.5Zm-1.033,6.1c-.249.61-.684,1.1-1.045,1.1s-.691-.486-.94-1.1a8.01,8.01,0,0,1-.478-2.9,8.041,8.041,0,0,1,.478-2.91c.249-.606.579-.971.936-.971s.789.369,1.041.971a7.827,7.827,0,0,1,.008,5.811Z'
					transform='translate(-2.159)'
				/>
			</g>
		</svg>
	)
}

export default MegaPhoneIcon
