import React from 'react'
import { Button, Col, Row, Skeleton, Spin, Typography } from 'antd'
import { AddTaskTemplatesContainer } from '../containers/AddTaskTemplatesContainer'
import { LayoutWrapper } from '../../../Components/LayoutWrapper'
import { TasksTemplatesTableHeader } from './TasksTemplatesTableHeader'
import { tasksTableCells } from './TaskTemplatesTableStructure'
import { TasksTemplatesTableRow } from './TasksTemplatesTableRow'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { LoadingOutlined } from '@ant-design/icons'
import { VisibleItem } from '../../../Components/VisibleItem'
import { loadNextPage } from '../taskTemplatesSlice'
import { TaskContainerProps } from '../containers/TaskTemplatesContainer'

interface Props extends TaskContainerProps {}

export const TaskTemplatesList: React.FC<Props> = ({ tasks }) => {
	const loading = useSelector<RootState, boolean>((state) => state.taskTemplates.loading)
	const dispatch = useDispatch()
	const pagesCount = useSelector<RootState, number>((state) => state.taskTemplates.pagesCount)
	const page = useSelector<RootState, number>((state) => state.taskTemplates.filter?.page ?? 1)

	return (
		<LayoutWrapper>
			<Row gutter={14}>
				{/* TASK HISTORY */}
				<Col span={23}>
					<TasksTemplatesTableHeader cells={tasksTableCells} />
					{tasks.length < 1 && !loading ? <div className='empty-tasks'>You don't have any template yet</div> : null}

					<div style={{ overflow: 'scroll', position: 'relative', minHeight: '80vh' }}>
						{tasks.map((task, index) => {
							return <TasksTemplatesTableRow key={task.id} even={index % 2 === 0} task={task} cells={tasksTableCells} />
						})}
						<VisibleItem visible={loading && page === 1}>
							<div className='loading-container'>
								<Spin indicator={<LoadingOutlined spin />} size='large' spinning={true} />
								<Typography.Text strong>Loading...</Typography.Text>
							</div>
						</VisibleItem>
						<VisibleItem visible={pagesCount > page || loading}>
							{loading ? (
								<div>
									<Skeleton.Button className='task-skeleton' active />
									<Skeleton.Button className='task-skeleton' active />
								</div>
							) : (
								<Row justify='center' align='middle'>
									<Button disabled={loading} onClick={() => dispatch(loadNextPage())} type='primary'>
										Load more
									</Button>
								</Row>
							)}
						</VisibleItem>
					</div>
				</Col>
				<Col span={1}>
					<AddTaskTemplatesContainer />
				</Col>
			</Row>
		</LayoutWrapper>
	)
}
