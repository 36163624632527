/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Form, Input, Modal, Typography } from 'antd'
import { Route, Switch, useRouteMatch, useHistory, useLocation } from 'react-router-dom'

import { CategoriesContainerProps } from '../containers/ManageCategoriesContainer'
import { CheckbeeDashboardContent } from '../../../Components/containers.styled'
import { LayoutWrapper } from '../../../Components/LayoutWrapper'
import { CheckbeeMenu } from '../../../Components/CheckbeeMenu'
import { CheckbeeMenuContent } from '../../../Components/CheckbeeMenuContent'
import { SubCategoryFormContainer } from '../containers/SubCategoryFormContainer'
import { ViewCategory } from './ViewCategory'
import { Category, SubCategory } from '../categorySlice'
import categoryJSON from '../initial-category.json'
import { QuickEditInput } from '../../../Components/QuickEditInput/QuickEditInput'

interface CategoryPatch {
	id: string
	name: string
}
interface Props extends CategoriesContainerProps {
	updateCategory: (props: CategoryPatch) => void
	isUpdatingCategory: boolean
	isUpdateCategoryFulfilled: boolean
}

/**
 * Nesting:
 * - [to view category] -- ViewCategory > CategoryForm
 * - [to create category] -- CategoryForm
 */
export const ManageCategories: React.FC<Props> = ({
	categories,
	categoryUsers,
	isFetchingCategories,
	updateCategory,
	updateSubCategory,
	isUpdatingCategory,
	isUpdateCategoryFulfilled,
}) => {
	const [formattedCategories, setFormattedCategories] = React.useState(
		categories.map((el) => ({ key: el.id, content: el.name, ...el }))
	)
	const [formattedSubCategories, setFormattedSubCategories] = React.useState(
		categories.length ? categories[0].subCategories.map((el) => ({ key: el.id, content: el.name, ...el })) : []
	)
	const [selectedCategory, setSelectedCategory] = React.useState(formattedCategories[0])
	const [selectedSubCategory, setSelectedSubCategory] = React.useState(formattedSubCategories[0])
	const [isEditCatModalOpen, setIsEditCatModalOpen] = React.useState('')
	const [categoryName, setCategoryName] = React.useState('')

	const match = useRouteMatch('/categories')
	const location = useLocation()
	const history = useHistory()

	const seedCategoryMenuWithDraft = () => {
		const initialCategory = (categoryJSON as unknown) as Category
		const category = {
			key: `new-cat-${categories.length + 1}`,
			content: 'Draft Category',
			...initialCategory,
		}

		setFormattedCategories([...formattedCategories, category])
		setSelectedCategory(category)

		const formatedSubCats = initialCategory.subCategories.map((subCat) => ({
			key: `new-subcat-1`,
			content: 'Draft Sub Category',
			...subCat,
		}))
		setFormattedSubCategories(formatedSubCats)
		setSelectedSubCategory(formatedSubCats[0])
		history.push(`${match?.url}/add`)
	}

	const seedSubCategoryMenuWithDraft = (category: Category) => {
		const subCatsLength = category.subCategories.length
		const formatedSubCats = [
			...category.subCategories.map((el) => ({ key: el.id, content: el.name, ...el })),
			{
				id: `new-subcat-${subCatsLength + 1}`,
				key: `new-subcat-${subCatsLength + 1}`,
				content: 'Draft Sub Category',
				name: 'Draft Sub Category',
				imageUrl: null,
				archived: null,
				powerUrl: null,
				users: [],
			},
		]
		setFormattedSubCategories(formatedSubCats)
		setSelectedSubCategory(formatedSubCats[formatedSubCats.length - 1])
		history.push(`${match?.url}/add`)
	}

	const removeDraftSeed = () => {
		const newTransformedCategories = [...formattedCategories]
		newTransformedCategories.splice(formattedCategories.length - 1, 1)
		setFormattedCategories(newTransformedCategories)
	}

	// effect for seeding
	React.useEffect(() => {
		if (location.pathname === `${match?.url}` && formattedCategories.length > categories.length) {
			removeDraftSeed()
		}
	}, [location.pathname])

	React.useEffect(() => {
		if (!categories.length || !categories[0].subCategories.length) {
			setFormattedCategories([])
			setFormattedSubCategories([])
			return () => {}
		}

		const formatedCats = categories.map((el) => ({ key: el.id, content: el.name, ...el }))
		setFormattedCategories(formatedCats)

		const newCategory = formatedCats.find((f) => f.name === selectedCategory?.name) ?? formatedCats[0]
		setSelectedCategory(newCategory)

		const formatedSubCats = newCategory.subCategories.map((el) => ({ key: el.id, content: el.name, ...el }))
		setFormattedSubCategories(formatedSubCats)
		setSelectedSubCategory(formatedSubCats.find((f) => f.name === selectedSubCategory?.name) ?? formatedSubCats[0])
	}, [JSON.stringify(categories)])

	React.useEffect(() => {
		if (isUpdateCategoryFulfilled) {
			setIsEditCatModalOpen('')
		}
	}, [isUpdateCategoryFulfilled])

	return (
		<LayoutWrapper>
			<Typography.Title level={4}>
				Manage and add CATEGORIES and SUBCATEGORIES for your organization here
			</Typography.Title>

			<CheckbeeDashboardContent>
				<CheckbeeMenu
					title='CATEGORIES'
					items={formattedCategories}
					loading={isFetchingCategories}
					selectedKeys={[selectedCategory?.key]}
					renderRow={(row: any) => {
						return (
							<QuickEditInput
								value={row.content}
								onChange={(newName) => {
									setFormattedCategories(
										formattedCategories.map((e) => (e.key === row.key ? { ...e, content: newName } : e))
									)
									setSelectedCategory({ ...selectedCategory, name: newName })
									if (!row.key.includes('new-cat-')) {
										// not add mode
										updateCategory({ id: row.key, name: newName })
									}
								}}
							/>
						)
					}}
					onSelect={({ key }) => {
						const category = formattedCategories.find((el) => el.key === key)
						const formatedSubCats =
							category?.subCategories.map((subCat) => ({
								...subCat,
								key: subCat.id,
								content: subCat.name,
							})) || []
						if (category) {
							setSelectedCategory(category)
							setFormattedSubCategories(formatedSubCats)
							setSelectedSubCategory(formatedSubCats?.[0])
							// go back to list if ever the route is on add
							history.push('/categories')
						}
					}}
					onFooterClick={() => {
						if (formattedCategories.length <= categories.length) {
							seedCategoryMenuWithDraft()
						}
					}}
				/>
				<Modal
					title='Edit Category'
					visible={!!isEditCatModalOpen}
					onOk={() => {
						updateCategory({ id: isEditCatModalOpen, name: categoryName })
					}}
					confirmLoading={isUpdatingCategory}
					onCancel={() => setIsEditCatModalOpen('')}>
					<Form.Item style={{ margin: 0 }}>
						<Input
							placeholder='Category name'
							size='large'
							maxLength={28}
							name={'name'}
							value={categoryName}
							onChange={(e) => setCategoryName(e.target.value)}
						/>
					</Form.Item>
				</Modal>
				<div style={{ width: 20 }} />
				<CheckbeeMenu
					renderRow={(row: any) => {
						return (
							<QuickEditInput
								value={row.content}
								onChange={(newName) => {
									setFormattedSubCategories(
										formattedSubCategories.map((e) => (e.key === row.key ? { ...e, content: newName } : e))
									)
									setSelectedSubCategory({ ...selectedSubCategory, name: newName })
									if (!row.key.includes('new-subcat-')) {
										// not add mode
										updateSubCategory({ id: row.key, name: newName })
									}
								}}
							/>
						)
					}}
					style={{ width: 250 }}
					title='SUB CATEGORIES'
					items={formattedSubCategories}
					loading={isFetchingCategories}
					selectedKeys={[selectedSubCategory?.key]}
					onSelect={({ key }) => {
						const subCat = formattedSubCategories.find((subCat) => subCat.id === key)
						if (subCat) {
							setSelectedSubCategory(subCat)
						}
					}}
					onFooterClick={() => {
						if (formattedCategories.length <= categories.length && categories.length) {
							seedSubCategoryMenuWithDraft(selectedCategory)
						}
					}}
				/>

				<CheckbeeMenuContent>
					<Switch>
						<Route
							path={`${match?.url}`}
							exact={true}
							render={() => {
								// filter users to show only can access this category
								const filteredUsers = categoryUsers.filter((e) => {
									const subcategories: SubCategory[] = e.subCategories
									const canAccess = subcategories.some((e) => e.id === selectedSubCategory?.id)
									return canAccess && !e.archived
								})
								const category = { ...selectedCategory, users: filteredUsers }
								const subCategory = { ...selectedSubCategory, users: filteredUsers }
								return <ViewCategory category={category} subCategory={subCategory} loading={isFetchingCategories} />
							}}
						/>
						<Route
							path={`${match?.url}/add`}
							render={() => (
								<SubCategoryFormContainer
									subCategory={selectedSubCategory}
									category={selectedCategory}
									newCategory={selectedCategory?.id === ''}
								/>
							)}
						/>
					</Switch>
				</CheckbeeMenuContent>
			</CheckbeeDashboardContent>
		</LayoutWrapper>
	)
}
