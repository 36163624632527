import { LoadingOutlined } from '@ant-design/icons'
import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/colors'
import { VisibleItem } from '../VisibleItem'

const ContainerCard = styled.div`
	padding: 10px;
	background: #f8f8f8;
	box-shadow: 0px 2px 4px rgba(108, 108, 108, 0.25);
	width: 175px;
	font-size: 30px;
	font-weight: 600;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 166px;
	justify-content: space-between;
`

const flexRowContainer: any = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
}

const SingleValueKpi: React.FC<{
	loading: boolean
	title: string
	subtitle: string
	titleValue: string
	renderContent: () => React.ReactNode
}> = ({ loading, title, subtitle, titleValue, renderContent }) => {
	return (
		<ContainerCard>
			<VisibleItem visible={loading}>
				<LoadingOutlined spin style={{ fontSize: 32, margin: 'auto', color: colors.mediumPriority }} />
			</VisibleItem>
			<VisibleItem visible={!loading}>
				<div style={flexRowContainer}>
					<div style={{ height: 30 }}>{titleValue}</div>
					{title}
				</div>
				<div style={flexRowContainer}>
					{renderContent()}
					<span style={{ fontSize: 15, fontWeight: 600 }}>{subtitle}</span>
				</div>
			</VisibleItem>
		</ContainerCard>
	)
}

export default SingleValueKpi
