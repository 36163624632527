import { DeferFn } from 'react-async'

import { checkbeeAPIBase } from '../../../../Common/api-client.config'

/**
 * getTotalTasksDeferFn
 * [react-async's deferFn - manually invoked]
 *
 * @example usage
 * const getTotalTasksAsync = useAsync({ deferFn: getTotalTasksDeferFn })
 * const initiategetTotalTasks = (arg: any, anotherArg: any) => {
 * 		getTotalTasksAsync.run(arg, anotherArg)
 * }
 * useSideEffect({
 * 		asyncFn: getTotalTasksAsync,
 * 		message: 'Successful',
 * 		onSuccess: () => {},
 * })
 *
 * @param props
 * @param signal
 */
export const getTotalTasksDeferFn: DeferFn<any> = async (_, props, { signal }) => {
	const token = localStorage.getItem('chibi-tkn')
	const tenantKey = process.env.REACT_APP_TENANT_KEY

	const response = await fetch(`${checkbeeAPIBase}/stats/total_created_tasks`, {
		signal,
		method: 'GET',
		headers: {
			'Tenant-Key': `${tenantKey}`,
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`,
		},
	})

	const result = await response.json()
	if (result.status === 'success') {
		return result.data
	} else {
		throw new Error(result?.data?.message || result?.message || result?.data?.name || 'Something went wrong!')
	}
}
