import React from 'react'
import { colors } from '../../../../Assets/colors'
import { Top5SubCats } from '../../../../Types/StatsTypes'

const normalize = (val: number, max: number, min: number) => {
	return (val - min) / (max - min)
}

const Prios: {
	id: 'highCount' | 'mediumCount' | 'lowCount'
	color: string
}[] = [
	{
		color: colors.highPriority,
		id: 'highCount',
	},
	{
		color: colors.mediumPriority,
		id: 'mediumCount',
	},
	{
		color: colors.lowPriority,
		id: 'lowCount',
	},
]

const SubCategoryItem: React.FC<{ subCatStats: Top5SubCats; maxValue: number }> = ({ subCatStats, maxValue }) => {
	return (
		<div style={{ height: 350, position: 'relative' }}>
			<div style={styles.percentContainer}>
				{Prios.map((e) => (
					<div
						key={e.id}
						style={{
							...styles.percentCol,
							height: normalize(subCatStats[e.id], maxValue, 0) * 300,
						}}>
						{subCatStats[e.id]}
					</div>
				))}
			</div>
			{/* prios row */}
			<div style={styles.prioContainer}>
				{Prios.map((e) => (
					<div
						key={e.id}
						style={{
							width: 45,
							height: 10,
							backgroundColor: e.color,
						}}
					/>
				))}
			</div>
		</div>
	)
}

const styles = {
	prioContainer: { width: 150, display: 'flex', justifyContent: 'space-between' },
	percentContainer: {
		height: '100%',
		width: 150,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
	},
	percentCol: {
		width: 45,
		backgroundColor: '#E6E6E6',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-end',
		fontWeight: 200,
		fontSize: 12,
	},
}

export default SubCategoryItem
