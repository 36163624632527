/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { notification } from 'antd'

type UseSideEffect = {
	asyncFn: any
	message?: string
	type?: 'success' | 'open'
	description?: string
	/** deprecated */
	notificationFn?: () => void
	onSuccess?: () => void
	useEffectExtraDeps?: any[]
}

/**
 * a hook for useAsync side effect
 * @param asyncFn
 * @param {string|null} [message] - notification message
 * @param {'success'|'info'} [type] - notification type
 * @param {string|null} [description] - notification description
 * @param {function|null} [notificationFn] - custom notification
 * @param {function} [onSuccess] - extra side effects (callback) after successful request
 * @param {array} [useEffectExtraDeps] - array of additional useEffect dependencies
 */
export const useSideEffect = ({
	asyncFn,
	message,
	type = 'success',
	description,
	notificationFn,
	onSuccess,
	useEffectExtraDeps = [],
}: UseSideEffect) => {
	useEffect(() => {
		if (asyncFn.error) {
			console.log('[!] ERROR - useSideEffect --', asyncFn.error)
			notification.error({ message: asyncFn.error.message })
		}

		if (asyncFn.isFulfilled) {
			if (notificationFn && typeof notificationFn === 'function') {
				// if there is a custom notification
				notificationFn()
			} else if (message) {
				// if there is only a message that is a string
				notification[type]({ message, description })
			}

			// if onSuccess exists
			onSuccess && onSuccess()
		}
	}, [asyncFn.error, asyncFn.isFulfilled, ...useEffectExtraDeps])
}
