import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useAsync } from 'react-async'

import { RootState } from '../../../store'
import { categorySlice } from '../categorySlice'
import { ManageCategories } from '../components/ManageCategories'
import { fetchCategoriesPromiseFn } from '../actions/fetchCategories'
import { useSideEffect } from '../../../Hooks/useSideEffect'
import { fetchUsersDeferFn } from '../../user/actions/fetchUsers'
import { updateCategoryDeferFn } from '../actions/updateCategory'
import { updateSubCategoryDeferFn } from '../actions/updateSubCategory'
import { SubCategoryFormState } from './SubCategoryFormContainer'

const mapState = (state: RootState) => ({
	loggedInUser: state.auth.user,
	categories: state.category.list,
	categoryUsers: state.category.users,
})

const mapDispatch = {
	receiveCategories: categorySlice.actions.receiveCategories,
	receiveCategoryUsers: categorySlice.actions.receiveCategoryUsers,
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export type CategoriesContainerProps = PropsFromRedux & {
	isFetchingCategories: boolean
	updateSubCategory: Function
}

/** higher-order wrapper component for side effects */
const Wrapper: React.FC<CategoriesContainerProps> = (props) => {
	// fetch categories block
	const fetchCategoriesAsync = useAsync({
		promiseFn: fetchCategoriesPromiseFn,
		companyId: props.loggedInUser.company.id,
	})
	useSideEffect({
		asyncFn: fetchCategoriesAsync,
		onSuccess: () => {
			const { data: categories } = fetchCategoriesAsync
			props.receiveCategories({ categories })
			initiateFetchUsers({ company: props.loggedInUser.company.id })
		},
	})

	// fetch users block
	const fetchUsersAsync = useAsync({ deferFn: fetchUsersDeferFn })
	const initiateFetchUsers = (queries: { company: string }) => {
		fetchUsersAsync.run(queries)
	}
	useSideEffect({
		asyncFn: fetchUsersAsync,
		onSuccess: () => {
			const { data: users } = fetchUsersAsync
			props.receiveCategoryUsers({ users })
		},
	})

	// fetch users block
	const updateCategoryAsync = useAsync({ deferFn: updateCategoryDeferFn })
	const initiateUpdateCategory = (queries: { id: string; name: string }) => {
		updateCategoryAsync.run(queries)
	}
	useSideEffect({
		asyncFn: updateCategoryAsync,
		onSuccess: () => {
			fetchCategoriesAsync.reload()
		},
	})

	const updateSubCategoryAsync = useAsync({ deferFn: updateSubCategoryDeferFn })
	const handleUpdateSubCategory = (values: SubCategoryFormState) => {
		updateSubCategoryAsync.run({
			...values,
		})
	}
	useSideEffect({
		asyncFn: updateSubCategoryAsync,
		onSuccess: async () => {
			fetchCategoriesAsync.reload()
		},
	})

	return (
		<ManageCategories
			{...props}
			isUpdateCategoryFulfilled={updateCategoryAsync.isFulfilled}
			isUpdatingCategory={updateCategoryAsync.isLoading}
			updateCategory={initiateUpdateCategory}
			updateSubCategory={handleUpdateSubCategory}
			isFetchingCategories={fetchCategoriesAsync.isPending}
		/>
	)
}

export const ManageCategoriesContainer = connector(Wrapper)
