import styled from 'styled-components'

export const CheckbeeDashboardContent = styled.div`
	display: flex;
	margin-top: 22px;
`

export const CheckbeeMenuContentHeader = styled.div<{ align?: 'center' | 'flex-start' }>`
	position: relative;
	display: flex;
	align-items: ${(props) => props.align ?? 'center'};
`
