import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { User } from '../auth/authSlice'

export const userSlice = createSlice({
	name: 'user',
	initialState: {
		message: '',
		list: [] as User[],
	},
	reducers: {
		receiveUsers: (state, action: PayloadAction<{ users: User[] }>) => {
			state.list = action.payload.users
		},
		receiveUserUpdate: (state, action) => {},
	},
})
