import { Typography } from 'antd'
import React from 'react'
import { Top5SubCats } from '../../../../Types/StatsTypes'
import { EmptyContentPlaceholder } from '../Dashboard'
import SubCategoryItem from './SubCategoryItem'

const SubCategoryChart: React.FC<{ subCats: Top5SubCats[] }> = ({ subCats }) => {
	if (!subCats.length) {
		return <EmptyContentPlaceholder>No enough data</EmptyContentPlaceholder>
	}

	const maxValue = subCats.reduce(
		(newValue, current) => Math.max(newValue, current.highCount, current.mediumCount, current.lowCount),
		0
	)

	return (
		<div style={styles.container}>
			{subCats.map((e) => (
				<div style={styles.subCat}>
					<SubCategoryItem maxValue={maxValue} subCatStats={e} />
					<Typography.Text style={{ marginTop: 10 }} strong>
						{e.name}
					</Typography.Text>
				</div>
			))}
		</div>
	)
}

const styles = {
	container: {
		height: 400,
		width: '100%',
		display: 'flex',
		justifyContent: 'space-evenly',
		alignItems: 'flex-end',
	},
	subCat: {
		display: 'flex',
		flexDirection: 'column' as 'column',
		alignItems: 'center',
		height: '100%',
	},
}

export default SubCategoryChart
