import React from 'react'
import Avatars from '../../Assets/SvgIcons/Avatars'
import { EmptyContentPlaceholder } from '../../Modules/dashboard/components/Dashboard'
import './styles.css'

const normalize = (val: number, max: number, min: number) => {
	return (val - min) / (max - min)
}

const UCol = ({ index, color, name }: { index: number; color: string; name: string }) => {
	return (
		<>
			<div className='chart-user-col' style={{ backgroundColor: color }}>
				<span style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>{name}</span>
				{index} Tasks
			</div>
		</>
	)
}

const UsersColumnsChart = ({ topUsers }: { topUsers: any[] }) => {
	if (!topUsers.length) {
		return <EmptyContentPlaceholder> No enough data </EmptyContentPlaceholder>
	}
	const highest = topUsers[0].compeletedTasksCount

	return (
		<div className='users-columns-container'>
			{topUsers.map((e, index) => (
				<div className='user-col-container'>
					<div className='profile-photo'>{Avatars[index % Avatars.length]}</div>
					<div
						className='shadow'
						style={{ height: `${Math.max(normalize(e.compeletedTasksCount, highest, 0), 0.32) * 100 - 15}%` }}>
						<UCol color={'#F8CB60'} index={e.compeletedTasksCount} name={e.firstName} />
					</div>
					{/* <div className="shadow small-col" style={{height: `${normalize(e.today, highest + highest / 2, 0)*100}%`}}><UCol color={'#F7DB98'} index={e.today} /></div> */}
				</div>
			))}
		</div>
	)
}

export default UsersColumnsChart
