import { DeferFn } from 'react-async'

import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { handleError } from '../../../Utils/handle-error'

export const createTaskTemplateDeferFn: DeferFn<any> = async ([values], props, { signal }) => {
	const tenantKey = process.env.REACT_APP_TENANT_KEY
	const token = localStorage.getItem('chibi-tkn')
	const response = await fetch(`${checkbeeAPIBase}/task_template`, {
		signal,
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Tenant-Key': `${tenantKey}`,
			'Authorization': `Bearer ${token}`,
		},
		body: JSON.stringify(values),
	})

	const result = await response.json()
	if (result.status === 'success') {
		return result.data
	} else {
		const err = handleError(result, 'Failed to create task!')
		throw new Error(err)
	}
}
