import React from 'react'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { useAsync } from 'react-async'

import { RootState } from '../../../store'
import { AddItem, ItemForm } from '../components/AddItem'
import { useSideEffect } from '../../../Hooks/useSideEffect'
import { createItemDeferFn } from '../actions/createItem'
import { fetchCategoriesPromiseFn } from '../../category/actions/fetchCategories'
import { fetchUsersPromiseFn } from '../../user/actions/fetchUsers'
import { applyFilter, itemSlice } from '../itemSlice'
import { categorySlice } from '../../category/categorySlice'

const mapState = (state: RootState) => ({
	loggedInUser: state.auth.user,
	categories: state.category.list,
	items: state.item.list,
	availableAssignees: state.item.availableAssignees,
})

/**
 * defining mapDispatchToProps as an object
 * see https://react-redux.js.org/using-react-redux/connect-mapdispatch#defining-mapdispatchtoprops-as-an-object
 */
const mapDispatch = {
	receiveItems: itemSlice.actions.receiveItems,
	receiveCategories: categorySlice.actions.receiveCategories,
	receiveAvailableAssignees: itemSlice.actions.receiveAvailableAssignees,
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>

/** higher-order wrapper component for side effects */
const Wrapper: React.FC<PropsFromRedux> = (props) => {
	const dispatch = useDispatch()
	// fetch categories block
	const fetchCategoriesAsync = useAsync({
		promiseFn: fetchCategoriesPromiseFn,
		companyId: props.loggedInUser.company.id,
	})
	useSideEffect({
		asyncFn: fetchCategoriesAsync,
		onSuccess: () => {
			const { data: categories } = fetchCategoriesAsync
			props.receiveCategories({ categories })
		},
	})

	// fetch users block
	const fetchUsersAsync = useAsync({
		promiseFn: fetchUsersPromiseFn,
		query: { company: props.loggedInUser.company.id },
	})
	useSideEffect({
		asyncFn: fetchUsersAsync,
		onSuccess: () => {
			const { data: users } = fetchUsersAsync
			const unArchivedUsers = users.filter((user: any) => !user.archived)
			props.receiveAvailableAssignees({ users: unArchivedUsers })
		},
	})

	// create item block
	const createItemAsync = useAsync({ deferFn: createItemDeferFn })
	const initiateCreateItem = (values: ItemForm) => {
		createItemAsync.run(values)
	}
	useSideEffect({
		asyncFn: createItemAsync,
		message: 'Added new item!',
		onSuccess: () => {
			dispatch(applyFilter({ perPage: props.items.length + 1, page: 1 }))
		},
	})

	return (
		<AddItem
			{...props}
			isCreatingItem={createItemAsync.isPending}
			isGettingCategories={fetchCategoriesAsync.isPending}
			isGettingUsers={fetchUsersAsync.isPending}
			isGettingItems={false}
			isCreateItemFulfilled={createItemAsync.isFulfilled}
			createItem={initiateCreateItem}
		/>
	)
}

export const AddItemContainer = connector(Wrapper)
