import { DeferFn } from 'react-async'

import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { handleError } from '../../../Utils/handle-error'

export const notifyTaskAssigneeDeferFn: DeferFn<any> = async ([id], props, { signal }) => {
	const tenantKey = process.env.REACT_APP_TENANT_KEY
	const token = localStorage.getItem('chibi-tkn')
	const response = await fetch(`${checkbeeAPIBase}/task/notify_assignee`, {
		signal,
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Tenant-Key': `${tenantKey}`,
			'Authorization': `Bearer ${token}`,
		},
		body: JSON.stringify({ taskId: id }),
	})

	if (response.status === 204) {
		return response
	} else {
		const err = handleError(response, 'Failed to notify task assignee!')
		throw new Error(err)
	}
}
