import { DeferFn, PromiseFn } from 'react-async'

import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { handleError } from '../../../Utils/handle-error'

/**
 * this file contains 3 implementations of fetchCategories function:
 * - action
 * - react-async promiseFn
 * - react-async deferFn
 */

/**
 * fetchCategories action
 * [generic] - can be reused in both defer and promise functions
 */
export const fetchCategories = async (companyId: string, signal: AbortSignal) => {
	const token = localStorage.getItem('chibi-tkn')
	const tenantKey = process.env.REACT_APP_TENANT_KEY
	const response = await fetch(`${checkbeeAPIBase}/category`, {
		signal,
		method: 'GET',
		headers: {
			'Tenant-Key': `${tenantKey}`,
			'Authorization': `Bearer ${token}`,
		},
	})

	const result = await response.json()

	if (response.ok && result.status === 'success') {
		if (result.data.length) {
			return result.data
		} else {
			return []
		}
	} else {
		const err = handleError(result, 'Failed to get categories!')
		throw new Error(err)
	}
}

/**
 * fetchCategoriesPromiseFn
 * [react-async's promiseFn - automatic invocation]
 *
 * @param companyId
 * @param signal
 */
export const fetchCategoriesPromiseFn: PromiseFn<any> = async ({ companyId }, { signal }) => {
	return await fetchCategories(companyId, signal)
}

/**
 * createCategoryDeferFn
 * [react-async's deferFn - manually invoked]
 *
 * @param companyId
 * @param rest
 * @param props
 * @param signal
 */
export const fetchCategoriesDeferFn: DeferFn<any> = async ([companyId], props, { signal }) => {
	return await fetchCategories(companyId, signal)
}
