import React from 'react'

const OrderIcon = () => (
	<svg
		style={{ marginLeft: 4 }}
		xmlns='http://www.w3.org/2000/svg'
		width='5.584'
		height='7.769'
		viewBox='0 0 5.584 7.769'>
		<path
			id='Icon_awesome-sort'
			data-name='Icon awesome-sort'
			d='M1.66,8.513H6.3a.469.469,0,0,1,.332.8L4.314,11.635a.466.466,0,0,1-.662,0L1.329,9.313A.469.469,0,0,1,1.66,8.513ZM6.636,6.464,4.314,4.142a.466.466,0,0,0-.662,0L1.329,6.464a.469.469,0,0,0,.332.8H6.3A.469.469,0,0,0,6.636,6.464Z'
			transform='translate(-1.191 -4.004)'
		/>
	</svg>
)

export default OrderIcon
