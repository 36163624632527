import { Button, Typography } from 'antd'
import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import CloseIcon from '../../../Assets/SvgIcons/CloseIcon'
import EditIcon from '../../../Assets/SvgIcons/EditIcon'
import MegaPhoneIcon from '../../../Assets/SvgIcons/MegaPhoneIcon'
import { VisibleItem } from '../../../Components/VisibleItem'
import { RootState } from '../../../store'
import { Task } from '../taskSlice'
import AssigneesFilter from './FilterComponents/AssigneesFilter'
import CategoryFilter from './FilterComponents/CategoryFilter'
import ItemFilter from './FilterComponents/ItemsFilter'
import OrderByFilter from './FilterComponents/OrderByFilter'
import StatusFilter from './FilterComponents/StatusFilter'
import SubCategoryFilter from './FilterComponents/SubCategoryFilter'

export interface Cell {
	id: React.Key
	name: string
	icon?: Function
	span?: number
	fieldKey?: undefined | 'title' | 'priority'
	getValue?: Function
}

const mapState = (state: RootState) => ({
	categories: state.category.list,
})
const connector = connect(mapState, {})
type PropsFromRedux = ConnectedProps<typeof connector>
type TaskContainerProps = PropsFromRedux & {
	subCategoryId: string
}

const CategoryItem: React.FC<TaskContainerProps> = ({ categories, subCategoryId }) => {
	const categoryName =
		categories.find((cat) => cat.subCategories.some((subCat) => subCat.id === subCategoryId))?.name || ''
	return <div> {categoryName} </div>
}

const CategoryItemWrapper = connector(CategoryItem)

export const tasksTableCells: Cell[] = [
	{
		id: 'TaskName',
		name: 'Task',
		span: 3,
		getValue: (task: Task) => <Typography.Text strong>{task.title}</Typography.Text>,
	},
	{
		id: 'ASSIGNTO',
		name: 'ASSIGNED TO',
		icon: () => <AssigneesFilter />,
		span: 3,
		getValue: (task: Task) => {
			const { assign } = task
			return `${assign?.firstName ?? ''} ${assign?.lastName ?? ''}`
		},
	},
	{
		id: 'CATEGORY',
		name: 'CATEGORY',
		icon: () => <CategoryFilter />,
		span: 3,
		getValue: (task: Task) => <CategoryItemWrapper subCategoryId={task.subCategory?.id} />,
	},
	{
		id: 'SUB CATEGORY',
		name: 'SUB CATEGORY',
		icon: () => <SubCategoryFilter />,
		span: 3,
		getValue: (task: Task) => task.subCategory?.name,
	},
	{
		id: 'PRIORITY',
		name: 'PRIORITY',
		icon: () => <OrderByFilter field={'priority'} />,
		span: 2,
		getValue: (task: Task) => {
			let res = ''

			switch (task.priority) {
				case 3:
					res = '!!!'
					break
				case 2:
					res = '!!'
					break
				case 1:
					res = '!'
					break

				default:
					break
			}
			return (
				<Typography.Text className='task-row-priority' title={res} type={res === '!!!' ? 'danger' : 'secondary'}>
					{res}
				</Typography.Text>
			)
		},
	},
	{
		id: 'STATUS',
		name: 'STATUS',
		icon: () => <StatusFilter />,
		span: 2,
		getValue: (task: Task) => task.status,
	},
	{
		id: 'CREATEDBY',
		name: 'CREATED BY',
		span: 3,
		getValue: (task: Task) => {
			const { createdBy } = task
			return `${createdBy?.firstName ?? ''} ${createdBy?.lastName ?? ''}`
		},
	},
	{
		id: 'ITEM',
		name: 'ITEM',
		icon: () => <ItemFilter />,
		span: 2,
		getValue: (task: Task) => task.item?.name,
	},
	{
		id: 'DUEDATE',
		name: 'DUEDATE',
		icon: () => <OrderByFilter field={'dueDate'} />,
		span: 2,
		getValue: (task: Task) => task.dueDate ?? '',
	},
	{
		id: 'action1',
		name: '',
		getValue: (informAssignee: any, informLoading: boolean, editTask: any, editing: boolean, task: Task) => (
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<VisibleItem visible={!task.archived}>
					<Button
						loading={informLoading}
						onClick={informAssignee}
						style={{ height: 22, padding: 0 }}
						size='large'
						icon={<MegaPhoneIcon />}
						type='text'
					/>
				</VisibleItem>
				<Button
					onClick={editTask}
					style={{ height: 22, padding: 0 }}
					size='large'
					icon={editing ? <CloseIcon /> : <EditIcon />}
					type='text'
				/>
			</div>
		),
		span: 1,
	},
]
