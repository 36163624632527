import React from 'react'
import { Result, Button } from 'antd'
import { useHistory } from 'react-router-dom'

export const Checkbee404: React.FC<{}> = () => {
	const history = useHistory()
	return (
		<Result
			status='404'
			title='404'
			subTitle="Oh, no! We can't find the page you're trying to visit!"
			extra={
				<Button type='primary' size='large' onClick={() => history.push('/')}>
					Go back to Dashboard
				</Button>
			}
		/>
	)
}
